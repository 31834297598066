import * as RedirectionPath from '../../common/redirect';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService, SharedserviceService } from 'src/app/services';
import { DatePipe} from '@angular/common';
import { FormBuilder, FormGroup} from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Sort } from '@angular/material/sort';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;
  @Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.scss'],
    providers: [DatePipe, NgxUiLoaderService,Ng2SearchPipeModule]
  })
  export class UserSearchComponent implements OnInit {
    @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
    @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
    searchText:any;  sellerDtl: boolean = false;  headBner: boolean = false;  searchFlagReset: boolean = false;
    cancelticket: any;  return_d_date: any;  rreturn_d_date: any;  agentReport: any;  btnsubmitted: boolean = false;
    submitted: boolean = false;  Id: any;  Tedata:any; TTotalCount: string;
    bodydata: any = {}; data: any; keys: any; TotalCount: any; msg: any; ccData: any;
    flag: boolean = false;  clientView: any;  nodatafound: boolean = false;  page: any = {};
    totalpage: any;  venderdetail: any;  venderprofile: any;
    sortedBook: any[];  userdata: any;  sectordata: any;  imgUrl: any;  userid: any = "";  todate: any = "";
    formdate: any = "";  sector: any = "";  type: any = "";  filterstatus: any = "";  imgPath: any;  bookData: any;
    statusform: FormGroup;  userlist: any;  flightData: any;  returnFlight: any; flt_bokg_dtl: any;
    profileImg: any;  d1: any;  bgBannerPath: any;  p: number = 1;  element: any;  searchreportbool:boolean=false;
    booking_id: any;  Sectors: any=[];    fromDate: any = '';  toDate: any = '';  fromBookingDate:any='';  toBookingDate:any='';
    paymentMode:any="0";  depcity:any='';  arrcity:any='';  pnr:any='';  passengerfilter:boolean=false;  p_name:any=''
    request: {
      departure_date: any; departure_city_code: any; arrival_city_code: any; page_limit: number; page_no: number,user_id:number
    };
    constructor(
      private datePipe: DatePipe,    private fb: FormBuilder,    private dateAdapter: DateAdapter<Date>,    public sharedService: SharedserviceService,
      public router: Router, public commonService: CommonService, private toastr: ToastrManager, public activatedRoute: ActivatedRoute,
      private ngxLoader: NgxUiLoaderService,
    ) {
      this.activatedRoute.params.forEach((urlParams) => {
        this.Id = urlParams['id'];
        if (this.Id) {    this.userid = this.Id;    this.filterstatus = ""  }
        this.dateAdapter.setLocale('en-GB');  this.sortedBook = this.ccData;
        this.userdata = JSON.parse(localStorage.getItem("user"));
        this.imgUrl = this.sharedService.serviceBase
        this.profileImg = this.imgUrl + 'uploads/userprofile';
      });
    }
    sortData(sort: Sort) {
      const data = this.ccData;
      if (!sort.active || sort.direction === '') {
        this.sortedBook = data;
        return;
      }
      this.sortedBook = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'departure_date': return compare(a.departure_date, b.departure_date, isAsc);
          case 'booking_date': return compare(a.booking_date, b.booking_date, isAsc);
          case 'reference_id': return compare(a.reference_id, b.reference_id, isAsc);
          case 'seller_name': return compare(a.seller_name, b.seller_name, isAsc);
          case 'buyer_name': return compare(a.buyer_name, b.buyer_name, isAsc);
          case 'sector': return compare(a.sector, b.sector, isAsc);
          case 'total_book_seats': return compare(a.total_book_seats, b.total_book_seats, isAsc);
          case 'total_amount': return compare(a.total_amount, b.total_amount, isAsc);
          default: return 0;
        }
      });
      function compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }
    }

    applyFilter(event: Event) {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable1 tr").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    }

    ngOnInit() {
      this.bodydata = {
      user_id: Number(this.userid),
      page_limit: 10,
      page_no: 1,
      departure_city_code: "",
      arrival_city_code: "",
      departure_date: "",
      }
      this.bodydata['page_no'] = 1;
      this.bookinglist(this.bodydata);
    }


    bookinglist(bodydata) {
      this.sharedService.post('v1/user_activity/user_searches', bodydata).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.ccData = res.data;
          if ((res.data).length > 0) {
            this.data = res.data;
            this.keys = Object.keys(this.data[0]);
            this.TotalCount = res.count;
          } else {
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          }
        } else {
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.toastr.errorToastr(err.error.replyMsg, 'Error');
      });
    }

    viewDetail(element){
      // console.log("booking id check:::::::::::",element.flight_booking_id);
      sessionStorage.setItem("booking_id", JSON.stringify(element.flight_booking_id));
      window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
    }

    durationdiff(dep_time, arr_time, dep_date, type) {
      var dep_hr = parseInt(dep_time.split(':')[0]);
      var arr_hr = parseInt(arr_time.split(':')[0]);
      if (arr_hr < dep_hr) {
        var nwdate;
        if (!dep_date) {
          nwdate = new Date();
        } else {
          nwdate = new Date(dep_date);
        }

        var newdate = nwdate.setDate(nwdate.getDate() + 1);
        if (type == 'oneway') {
          this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
        } else {
          this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
        }
      } else {
        this.return_d_date = dep_date;
        this.rreturn_d_date = dep_date;
      }
      var start_date = dep_date + " " + dep_time;
      var end_date = this.return_d_date + " " + arr_time;
      return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));

    }
    //----------------Download PDF--------------

    printpreview() {
      var printW = window.open("");
      var html = document.getElementById('contentToConvert1').innerHTML;

      printW.document.write('<html><head><title>Booking Details</title>');
      printW.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" type="text/css" />`);
      printW.document.write(`<style>
       .col-3 {-webkit-box-flex: 0;flex: 0 0 25%;max-width: 25%;}
       .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
       table td{border-bottom:1px solid#ddd;padding:5px;border-collapse: collapse;} table th {border-collapse: collapse;border-bottom:1px solid#ddd;padding:5px;font-weight: 600;color: #000;} table{ width: 100%; border-spacing:1px;border-collapse: separate;}.row{display: flex;flex-wrap: wrap;width:100%;}.col-md-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}.img-box {width: 50px;height: 50px;border: 1px solid #ddd;object-fit:cover;}
       .border {border: 1px solid #dee2e6!important;}
       table{margin-bottom:5px !important;}
       .ph-text{height:220px !important;font-size:10px !important;line-height:13px !important;}
       .row {display: -webkit-box;display: flex;flex-wrap: wrap;}
       .text-left {text-align: left!important;}
       .img-profile {width: 100%;height: auto;-o-object-fit: contain;object-fit: contain;max-height: 70px;}
        .text-center {text-align: center!important;}
        .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
        .head-clr {color: #ff6a00;}.fw-500 {font-weight: 500;}
        .m-0 {margin: 0!important;}.w-100 {width: 100%!important;}.text-center {text-align: center!important;}
        .bg-white {background-color: #fff!important;}
        table {border-collapse: collapse;}
        .table {width: 100%;color: #212529;}
        .col-lg-4 {-webkit-box-flex: 0;flex: 0 0 33.333333%;max-width: 33.333333%;}
        .wp-100{width:100%;} .wp-30{width:30% !important;}
        .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
          margin-bottom:.5rem !important;font-weight: 500;line-height: 1.2;}
        .mytbl td {padding: 0.5rem;margin-bottom: 0rem !important;}
        .mytbl td {padding: 0.5rem !important;vertical-align: middle;padding-left: 0.75rem !important;}
        .table td, .table th {padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;}
        .border-bottom {border-bottom: 1px solid #dee2e6!important;}
        .img-box-fix {width: 50px;height: 30px;-o-object-fit: contain;object-fit: contain;}
        img {vertical-align: middle;border-style: none;}
        .textclr {color: #828282;}
        .ft-12 {font-size: 12px !important;}
        .align-self-center {align-self: center!important;}
        .table-responsive {display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
        .d-none {display: none!important;}.text-dark {color: #343a40!important;}.h6, h6 {font-size: 1rem;}
        .form-control:disabled, .form-control[readonly] {background-color: #e9ecef !important;opacity: 1;}
        .form-control {display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: .375rem .75rem;font-size: 1rem;
          font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;-webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
        .prnt-h{line-height:32px !important;}
        .form-control-1{border:1px solid#ddd;border-radius:5px;background-color:#e9ecef;color:#303030;padding:8px;}
        .w-10pr{width:10%;}.w-14pr{width:14%;}.w-25pr{width:25%;}
        .pmt-10{margin-top:10px;}
        .mytbl tr td{padding:2px;}
        .p-Size{font-size:12px;}.pmb-1{margin-bottom:10px !important;}.pmb-2{margin-bottom:15px !important; }
        .mytbl tr td{padding:2px;}.pmt-1{margin-bottom:10px !important;}.pmt-2{margin-bottom:15px !important; }
        </style>`);
      printW.document.write('</head><body >');
      printW.document.write(html);
      printW.document.write('</body></html>');
      printW.document.close();
      printW.focus();
      printW.print();
      // if(this.commonService.osname != "Mac OS"){
      //   printW.close();
      // }else{
      //   console.log("not Windows os")
      // }
    }
    getDataDiff(s_date, e_date) {
      var diff = e_date.getTime() - s_date.getTime();
      var days = Math.floor(diff / (60 * 60 * 24 * 1000));
      var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
      var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
      var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
      return hours + " hrs " + ": " + minutes + " min";
    }



    SearchDep(){
      if(this.depcity.length == 3){
        this.searchreport()
      }
    }

    SearchArr(){
      if(this.arrcity.length == 3){
        // console.log("arrcity")
        this.searchreport()
      }
    }

    searchreport(){
      // console.log("from date and to date ------->>",this.fromDate,this.toDate)
      if(this.fromDate != ''){
        this.searchreportbool=true;
        this.onSearch()
      }else if(this.depcity != '' && this.arrcity != ''){
        this.searchreportbool=true;
        this.onSearch()
      }else{
        this.searchreportbool=true;
      }
    }

    onSearch() {
      this.searchFlagReset = true;
      if (this.fromDate != '') {
        this.fromDate = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
      } else {
        this.fromDate = ''
      }

      this.request = {
        departure_date:this.fromDate,
        user_id: Number(this.userid),
        departure_city_code:this.depcity.toUpperCase(),
        arrival_city_code:this.arrcity.toUpperCase(),
        page_limit: 10,
        page_no: 1,
      }
      this.bookinglist(this.request);
    }


    onReset() {
      this.searchFlagReset = false;
      this.fromDate=''; this.depcity='';  this.arrcity='';  this.pnr='';  this.p_name=''
      this.bodydata['search'] = '';
      this.bookinglist(this.bodydata);
    }
    resetFunction(){
      // this.router.navigate(['dashboard/accounting/sale'])
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
    }
    addAccount() { }
    onPageChange(event) {
      this.bodydata['page_no'] = event;
      this.bookinglist(this.bodydata);
    }
  }

