import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { ApiRequestService } from 'src/app/services/api.service';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { SharedserviceService } from 'src/app/services';

declare var $: any;

@Component({ selector: 'app-dashboard', templateUrl: './dashboard.component.html', styleUrls: ['./dashboard.component.scss'], providers: [] })

export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public changeDetector: ChangeDetectorRef, private formBuilder: FormBuilder, public datePipe: DatePipe, public router: Router, private dateAdapter: DateAdapter<Date>, public manageDataService: ManagedataService, public apiService: ApiRequestService,public baseService: SharedserviceService,private ngxLoader: NgxUiLoaderService,) { this.dateAdapter.setLocale('en-GB'); }

  ngOnInit(): void {
  }
  ngAfterViewInit() {

  }
  ngOnDestroy() {

  }
}


