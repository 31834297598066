export * from './dashboard.component';
export * from './accounts/accounts.component';
// export * from './agentcompany/agentcompany.component';
// export * from './agentcompanypartner/agentcompanypartner.component';
// export * from './agents/agents.component';
export * from './apicompany/apicompany.component';
export * from './billing/billing.component';
// export * from './booking/booking.component';
// export * from './booking-history/booking-history.component';
// export * from './bookingview/bookingview.component';
// export * from './bookingviewpartner/bookingviewpartner.component';
// export * from './clients/clients.component';
// export * from './clientview/clientview.component';
export * from './company/company.component';
export * from './information/information.component';
export * from './loginrequest/loginrequest.component';
export * from './myapi/myapi.component';
// export * from './seller/seller.component';
// export * from './sellerview/sellerview.component';
// export * from './viewprofile/viewprofile.component';
export * from './partnerapi/partnerapi.component';
export * from './userlist/userlist.component';
export * from './account-balance/account-balance.component';
export * from './partnerAccount/partnerAccount.component';
// export * from './vendorAccount/vendorAccount.component';
export * from './partnerBalance/partnerBalance.component';
// export * from './vendorcompany/vendorcompany.component';
// export * from './vendor-booking-view/vendor-booking-view.component';
export * from './myapi-balance/myapi-balance.component';
export * from './myapi-account/myapi-account.component';
export * from './partner-company/partner-company.component';
export * from './partner-account-view/partner-account-view.component';
export * from './history-view/history-view.component';
export * from './emailsetting/emailsetting.component';
// export * from './bookingfail-list/bookingfail-list.component';
export * from './notification/notification.component';
export * from './search-result/search-result.component';
export * from './bank/bank.component';
export * from './bill/bill.component';
// export * from './accounting/accounting.component';
// export * from './payment/payment.component';
export * from './enquiry/enquiry.component';
export * from './status/status.component';
export * from './book-summery/book-summery.component';
export * from './sendmail/sendmail.component';
export * from './mail-list/mail-list.component';
export * from './comments/comments.component';
// export * from './user-activity/user-activity.component';
export * from './book-ticket/book-ticket.component';
export * from './payment-gateway/payment-gateway.component';
export * from './supplierlist/supplierlist.component';
// export * from './whatsapp-setting/whatsapp-setting.component';
export * from './user-search/user-search.component';
export * from './supplierAccount/supplierAccount.component';
export * from './supplierbookinglist/supplierbookinglist.component';
export * from './pg_transactions/pg_transactions.component';
// export * from './user-activity-details/user-activity-details.component';
// export * from './GGN/add-edit-ggn/add-edit-ggn.component';
// export * from './ggn-active-users/ggn-active-users.component';
// export * from './staff-management/staff-management.component';
// export * from './department-details/department-details.component';
// export * from './department-create/department-create.component';
// export * from './staff-create/staff-create.component';
export * from './tl-list/tl-list.component';
export * from './advertisement/advertisement.component';
export * from './statics/statics.component';
export * from './social-media/social-media.component';
export * from './smo_setting/smo_setting.component';
export * from './vehicle-transportation/vehicle-transportation.component';
// export * from './book-summery-italy/book-summery-italy.component';
export * from './accounting-list/accounting-list.component';
// export * from './staff-all-seller/staff-all-seller.component';
// export * from './staff-transaction/staff-transaction.component';
export * from './home/home.component';
// export * from './book-summery-italy_new/book-summery-italy_new.component';
