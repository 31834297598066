import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedserviceService } from 'src/app/services/sharedservice.service';

declare var $: any;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  id: any;
  apiList: any;
  domain_id: any;
  user_id: any;
  token: any;
  domainForm: FormGroup;
  DomainList: any;
  user: any = JSON.parse(localStorage.getItem("user"));
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, public httpClient: SharedserviceService, public router: Router) {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.user_id = this.activatedRoute.snapshot.paramMap.get("user_id");
    //this.user = JSON.parse(sessionStorage.getItem("user"));
    //if(this.user != null){ this.user_id = this.user[0].id;}

    this.getToken();
    this.api_list();
  }

  ngOnInit() {
  }


  api_list() {
    var api = {
      'id': this.id,
      'user_id': this.user_id,
    }
    const self = this;
    //this.httpClient.auth_token = this.user.jwt;
    this.httpClient.loadingShow = true;
    this.httpClient.post('api-list.json', api).subscribe((res) => {
      self.httpClient.loadingShow = false;
      if (res) {
        self.apiList = res.result;

      }
    });
  }



  getToken() {
    const self = this;
    this.httpClient.loadingShow = true;
    this.httpClient.get('token.json').subscribe((res) => {
      self.httpClient.loadingShow = false;
      if (res) {
        self.token = res.token;

      }
    });
  }

  change_domain_status(id, index, status) {
    var statusval = {
      'id': id,
      'status': status == 0 ? 1 : 0,
    }
    const self = this;
    self.httpClient.loadingShow = true;
    //self.domainForm.patchValue({customer_id:this.id});
    //alert(JSON.stringify(this.domainForm.value, null, 4));
    this.httpClient.post('updateDomain.json', statusval).subscribe((res) => {
      self.httpClient.loadingShow = false;
      if (res) {

        self.DomainList[index].status = status == 0 ? 1 : 0;
      }
    });
  }

  change_domain_plan(id, index, plan) {
    var statusval = {
      'id': id,
      'plan': plan,
    }
    const self = this;
    self.httpClient.loadingShow = true;
    //self.domainForm.patchValue({customer_id:this.id});
    //alert(JSON.stringify(this.domainForm.value, null, 4));
    this.httpClient.post('updatePlan.json', statusval).subscribe((res) => {
      self.httpClient.loadingShow = false;
      if (res) {
        self.DomainList[index].plan = plan;
      }
    });
  }


  status(id) {
    if (id == 2) {
      $("#deactivateapi").modal('show');
      $("#apistatus").modal('hide');
    }
    else if (id == 3) {
      $("#deleteapi").modal('show');
      $("#apistatus").modal('hide');
    }
    else { }
  }


}
