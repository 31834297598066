import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaterialModule } from '../route-file/material.module';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { UserAuthActivityService } from '../services';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [UserAuthActivityService] },
  // { path: 'admin-home', component: HomeComponent },
  { path: 'flight', component: DashboardComponent },
];

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxPaginationModule,
  MaterialModule
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
@NgModule({
  imports: [...BASE_MODULES,RouterModule.forChild(routes),OrderModule],
  exports: [RouterModule],
})
export class HomedashboardRoutingModule { }
