import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  enquiryList: any;
  AddCommentForm: FormGroup;
  submitted: boolean = false;
  CommentData: any;
  radioItems: Array<string>;
  message: any;
  comment: any;
  StatusData: any;
  checked:boolean = false
  radioButton:any;
  readioSelected:any;
  

  constructor( public sharedService: SharedserviceService,  private toastr: ToastrManager, private fb: FormBuilder) { 
    this.enquiryList=JSON.parse(sessionStorage.getItem("enquiry_list"));
  }

  ngOnInit() {  

    this.AddCommentForm = this.fb.group({
      id: ['',Validators.required],
      type: ['',Validators.required],
      comment: ['',Validators.required],
    }); 
    this.Commentlist()
  }   
  get com() { return this.AddCommentForm.controls; }

  Changetype(id){
    this.AddCommentForm.patchValue({
      type:id == 1?'note':'comment'
    }) 
  }

  commentAdd(){
  this.AddCommentForm.patchValue({
    id:this.enquiryList.id 
  })
  $('#add_comment').modal('show');
} 
AddComment(){
  this.submitted = true; 
  if(this.AddCommentForm.invalid){
    return;
  }
  var requestData ={
    "id": this.AddCommentForm.value.id,
    "type": this.AddCommentForm.value.type,
    "comment":this.AddCommentForm.value.comment
  }
  this.sharedService.post('v1/enquiry/add_comment',requestData).subscribe((res: any) => {
    if (res.replyCode == "success") {
      this.submitted = false;
      this.Commentlist();
      this.AddCommentForm.reset();
      $('#add_comment').modal('hide');
      this.toastr.successToastr(res.replyMsg, 'Success');
    } else {
      this.toastr.errorToastr(res.message, 'Error');
    }
  });
} 

Commentlist() {

  
  var request={
    id:this.enquiryList.id
  }
  this.sharedService.post('v1/enquiry/list_comment',request).subscribe((res) => {
    if (res.replyCode == 'success') {
      
      this.comment = res.data 
      this.CommentData = res.message;
      this.message = this.CommentData[0];
    }
  });
} 

get_status(){  
  this.sharedService.get('v1/enquiry/list_status').subscribe((res) => {
    if (res.replyCode == 'success') {
      this.StatusData = res.data;
    }
  });

$('#getStatus').modal('show')
}  
updateStatus(){
  var requestData = {
    id:this.message.id,
    status_id:Number(this.readioSelected)
  }
  this.sharedService.post('v1/enquiry/update_status',requestData).subscribe((res: any) => {
    if (res.replyCode == "success") {
      this.Commentlist();
      $('#getStatus').modal('hide');
      this.toastr.successToastr(res.replyMsg, 'Success');
    } else {
      this.toastr.errorToastr(res.message, 'Error');
    }
  });
  
}


}
