import * as RedirectionPath from '../../common/redirect';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService, MustMatch, SharedserviceService } from 'src/app/services';
import { DatePipe} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DateAdapter } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;

@Component({
  selector: 'app-supplierbookinglist',
  templateUrl: './supplierbookinglist.component.html',
  styleUrls: ['./supplierbookinglist.component.scss'],
  providers:[DatePipe,NgxUiLoaderService]
})
export class SupplierbookinglistComponent implements OnInit {
  public environment = environment;
  public dm = 'in';
  @ViewChild("fileInput1", {static: false}) fileInput1: ElementRef;files = []; TData: any;
  @ViewChild("inputSearch", {static: false}) inputSearch: ElementRef;  type1: any;
  airline_code: any ="";
  todate: any = "";  fromdate: any = "";  todepdate: any = "";  fromdepdate: any = "";  depcity: any = "";  arrcity: any = "";
  public imagePath;  image:any;  File: File=null;  public message: string=''; searchreportbool:boolean=false; totalpage: any;
  searchText:any;  headBner:boolean = false;  searchFlagReset:boolean=false;  summaryDtl:boolean =false;  cancelticket:any;
  return_d_date:any;  rreturn_d_date:any;  agentReport:any;  btnsubmitted:boolean=false;  submitted:boolean=false;
  Id:any;  bgBannerPath:any;  bodydata:any={};data:any;keys:any;TotalCount:any;msg:any;ccData:any;  flag:boolean=false;  clientView:any;
  nodatafound:boolean=false;  page:any={};    venderprofile:any;  sortedBook: any[];  userdata:any;
  sectordata:any;  imgUrl:any;  userid:any="";  sector:any="";  type:any="";  filterstatus:any="";  imgPath:any;
  bookData:any;  statusform:FormGroup;  userlist:any;  flightData:any;  returnFlight:any;flt_bokg_dtl:any;
  profileImg:any;  d1:any;  p: number = 1;  dataCount:any;  sort_departure_date: any = "desc";  sort_reference_id: any = "desc";
  sort_booking_date: any = "desc";
  sort_flight_sector: any = "desc";
  sort_total_book_seats: any = "desc";
  sort_total_amount: any = "desc";
  sort_return_flight: any = "desc";
  booking_id: any;
  today_date: Date;
  venderdetail:any=JSON.parse(sessionStorage.getItem('supplierBooking'))
  Bookingdata: any;
  bookingSendData:any={};
  paxname: any= [];

  constructor(
    private datePipe:DatePipe,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    public sharedService: SharedserviceService,
    public router: Router,
    public commonService: CommonService,
    private toastr: ToastrManager ,
    public activatedRoute:ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    ) {
    this.activatedRoute.params.forEach((urlParams) => {
      this.Id = urlParams['id'];
      if(this.Id){
        this.userid=this.Id;
        this.filterstatus="";
      }
      this.dateAdapter.setLocale('en-GB');
      this.sortedBook = this.ccData;
      this.userdata=JSON.parse(localStorage.getItem("user"));
      this.imgUrl = this.sharedService.serviceBase
      this.profileImg = this.imgUrl+'uploads/userprofile';
    });
    this.today_date = new Date()
   }


  sortfun(orderkey, type) {
    if (orderkey == 'departure_date' && type == 'asc') {this.sort_departure_date = 'asc';} else {this.sort_departure_date = 'desc';}
    if (orderkey == 'reference_id' && type == 'asc') {this.sort_reference_id = 'asc';} else {this.sort_reference_id = 'desc';}
    if (orderkey == 'booking_date' && type == 'asc') {this.sort_booking_date = 'asc';} else {this.sort_booking_date = 'desc';}
    if (orderkey == 'flight_sector' && type == 'asc') {this.sort_flight_sector = 'asc';} else {this.sort_flight_sector = 'desc';}
    if (orderkey == 'total_book_seats' && type == 'asc') {this.sort_total_book_seats = 'asc';} else {this.sort_total_book_seats = 'desc';}
    // if (orderkey == 'return_flight' && type == 'asc') {this.sort_return_flight = 'asc';} else {this.sort_return_flight = 'desc';}
    if (orderkey == 'total_amount' && type == 'asc') {this.sort_total_amount = 'asc';} else {this.sort_total_amount = 'desc';}
    this.bodydata={
      page_limit:10,
      page_no:1,
      search:"",
      display_type:"my_api",
      type:"api",
      user_id: parseInt(this.userid),
      status:this.filterstatus,
      flight_type:this.type,
      sector:this.sector,
      start_date:"",
      end_date:"",
      order_key:orderkey,
      order_value:type,
      flight_tbl_alias:"FT",
      flight_booking_tbl_alias:"FB",
    }
    // this.bookinglist()
  }

  clBalance(){
    $("#addBalance").modal("hide");
  }

  addAccount(){
  }

  ngOnInit() {
    this.bodydata['role_id'] = '3';
    this.clientView = this.fb.group({
      role_id:['3', Validators.required],
      name:['', Validators.required],
      email:['', Validators.required],
      mobile:['', [Validators.required, Validators.maxLength(10)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    },{
      validator: MustMatch('password', 'confirmPassword')
    });
    this.statusform = this.fb.group({
      status:['', Validators.required],
      share_inventory:['', Validators.required]
    });
    this.bodydata={
        page_limit:10,
        page_no:1,
        search:"",
        display_type:"my_api",
        type:"api",
        user_id: parseInt(this.userid),
        status:this.filterstatus,
        flight_type:this.type,
        sector:this.sector,
        start_date:"",
        end_date:"",
        order_key:"booking_date",
        order_value:"desc",
        flight_tbl_alias:"FT",
        flight_booking_tbl_alias:"FB",
    }
    this.bodydata['page_no']=1;

    this.bookingSendData = {
      supplier_id:Number(this.userid),
      dep_city_code : "" ,
      arr_city_code : "" ,
      booking_start_date : "" ,
      booking_end_date : "" ,
      dep_start_date : "" ,
      dep_end_date : "" ,
      search : "" ,
      airline_code : "" ,
      page_no : 1 ,
      page_limit : 10 ,
    }
    this.bookinglist(this.bookingSendData);
  }

  // statuschange(){
  //   var status:any;
  //   if(this.venderdetail.status == true){
  //     status = 0;
  //   }else{
  //     status = 1;
  //   }
  //   this.submitted= true;
  //    var data={
  //     share_inventory_id:this.venderdetail.inv_id,
  //     status:status,
  //   }
  //    this.sharedService.patch('v1/share_inventory/change_share_inventory_status', data).subscribe((res)=> {
  //     if(res.replyCode == 'success'){
  //       this.d1 = data;
  //       this.toastr.successToastr(res.replyMsg, 'Success');
  //       $("#apistatus").modal('hide');
  //     }else{
  //       this.toastr.errorToastr(res.replyMsg, 'Error');
  //     }
  //   },err=>{
  //     this.toastr.errorToastr(err['error']['message'], 'Error');
  //   });
  // }

  // vender_detail(){
  //   var data={
  //     id:this.Id
  //   }
  //   this.sharedService.post('v1/users/user_details', data).subscribe((res)=> {
  //     if(res.replyCode == 'success'){
  //       this.venderprofile=res.data;
  //       if(res.data.credit_limit == null || res.data.credit_limit == "") {
  //         this.venderprofile.credit_limit=0;
  //       }
  //       if(res.data.contact_person_name != null || res.data.name != null) {
  //         if(res.data.contact_person_name){
  //           this.venderprofile.ownername=res.data.contact_person_name;
  //         }else{
  //           this.venderprofile.ownername=res.data.name;
  //         }
  //       }
  //       if(res.data.gst_no == null || res.data.gst_no == "") {
  //         this.venderprofile.gst_no="NA";
  //       }
  //       $('#contact').modal('show');
  //     }else{
  //       this.toastr.errorToastr(res.replyMsg, 'Error');
  //     }
  //   },err=>{
  //     this.toastr.errorToastr(err['error']['message'], 'Error');
  //   });
  // }

  // loadList(){
  //   var sendBookingData={
  //     user_id:this.userid,
  //     display_type:"my_api",
  //   }
  //   this.ngxLoader.start();
  //   this.sharedService.post('v1/booking/my_booking_count', sendBookingData).subscribe((res)=> {
  //     if(res.replyCode == 'success'){
  //       this.dataCount = res.data;
  //       this.ngxLoader.stop();
  //     }else{
  //       this.ngxLoader.stop();
  //       this.toastr.errorToastr(res.replyMsg, 'Error');
  //     }
  //   },err=>{
  //     this.ngxLoader.stop();
  //     this.toastr.errorToastr(err['error']['message'], 'Error');
  //   });
  // }

  bookinglist(bookingSendData){
    this.sharedService.post('v1/super/flight/view_bookings',bookingSendData).subscribe((res)=> {
      if(res.replyCode == 'success'){
        this.ccData=res.flight;
        this.totalpage=res.totalPage;
        this.TotalCount = res.count;

        res.flight.forEach((element: any) => {
          var split_time = element.pax_names.split(', ');
          // this.paxname = split_time[2];
          this.paxname.push(split_time[2])
            console.log(split_time[2],"-----------247--*-*");

        });

        if(res.flight.length>0){
          this.data = res.flight;
          this.keys = Object.keys(this.data[0]);
          // this.TotalCount = res.totalRecords;
        }else{
          this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        }

      }else{
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    },err=>{
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  durationdiff(dep_time,arr_time,dep_date,type){
    var dep_hr=parseInt(dep_time.split(':')[0]);
    var arr_hr=parseInt(arr_time.split(':')[0]);
    if(arr_hr < dep_hr){
      var nwdate;
      if(!dep_date){
        nwdate =  new Date();
      }else{
        nwdate =  new Date(dep_date);
      }
      var newdate= nwdate.setDate(nwdate.getDate()+1);
      if(type == 'oneway'){
        this.return_d_date=this.datePipe.transform(newdate, 'yyyy-MM-dd');
      }else{
        this.rreturn_d_date=this.datePipe.transform(newdate, 'yyyy-MM-dd');
      }
    } else{
      this.return_d_date=dep_date;
      this.rreturn_d_date=dep_date;
    }
    var start_date=dep_date+" "+dep_time;
    var end_date=this.return_d_date+" "+arr_time;
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));

  }
  getDataDiff(s_date, e_date){
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours+" hrs "+": "+minutes+" min";
  }

  printpreview(){
  var printW = window.open("");
  var html = document.getElementById('contentToConvert1').innerHTML;

  printW.document.write('<html><head><title>Booking Details</title>');
  printW.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" type="text/css" />`);
  printW.document.write(`<style>
    .col-3 {-webkit-box-flex: 0;flex: 0 0 25%;max-width: 25%;}
    .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
    table td{border-bottom:1px solid#ddd;padding:5px;border-collapse: collapse;} table th {border-collapse: collapse;border-bottom:1px solid#ddd;padding:5px;font-weight: 600;color: #000;} table{ width: 100%; border-spacing:1px;border-collapse: separate;}.row{display: flex;flex-wrap: wrap;width:100%;}.col-md-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}.img-box {width: 50px;height: 50px;border: 1px solid #ddd;object-fit:cover;}
    .border {border: 1px solid #dee2e6!important;}
    table{margin-bottom:5px !important;}
    .ph-text{height:220px !important;font-size:10px !important;line-height:13px !important;}
    .row {display: -webkit-box;display: flex;flex-wrap: wrap;}
    .text-left {text-align: left!important;}
    .img-profile {width: 100%;height: auto;-o-object-fit: contain;object-fit: contain;max-height: 70px;}
    .text-center {text-align: center!important;}
    .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
    .head-clr {color: #ff6a00;}.fw-500 {font-weight: 500;}
    .m-0 {margin: 0!important;}.w-100 {width: 100%!important;}.text-center {text-align: center!important;}
    .bg-white {background-color: #fff!important;}
    table {border-collapse: collapse;}
    .table {width: 100%;color: #212529;}
    .col-lg-4 {-webkit-box-flex: 0;flex: 0 0 33.333333%;max-width: 33.333333%;}
    .wp-100{width:100%;} .wp-30{width:30% !important;}
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
      margin-bottom:.5rem !important;font-weight: 500;line-height: 1.2;}
    .mytbl td {padding: 0.5rem;margin-bottom: 0rem !important;}
    .mytbl td {padding: 0.5rem !important;vertical-align: middle;padding-left: 0.75rem !important;}
    .table td, .table th {padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;}
    .border-bottom {border-bottom: 1px solid #dee2e6!important;}
    .img-box-fix {width: 50px;height: 30px;-o-object-fit: contain;object-fit: contain;}
    img {vertical-align: middle;border-style: none;}
    .textclr {color: #828282;}
    .ft-12 {font-size: 12px !important;}
    .align-self-center {align-self: center!important;}
    .table-responsive {display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
    .d-none {display: none!important;}.text-dark {color: #343a40!important;}.h6, h6 {font-size: 1rem;}
    .form-control:disabled, .form-control[readonly] {background-color: #e9ecef !important;opacity: 1;}
    .form-control {display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: .375rem .75rem;font-size: 1rem;
      font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;-webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
    .prnt-h{line-height:32px !important;}
    .form-control-1{border:1px solid#ddd;border-radius:5px;background-color:#e9ecef;color:#303030;padding:8px;}
    .w-10pr{width:10%;}.w-14pr{width:14%;}.w-25pr{width:25%;}
    .pmt-10{margin-top:10px;}
    .mytbl tr td{padding:2px;}
    .p-Size{font-size:12px;}.pmb-1{margin-bottom:10px !important;}.pmb-2{margin-bottom:15px !important; }
    .mytbl tr td{padding:2px;}.pmt-1{margin-bottom:10px !important;}.pmt-2{margin-bottom:15px !important; }
    </style>`);
  printW.document.write('</head><body >');
  printW.document.write(html);
  printW.document.write('</body></html>');
    printW.document.close();
    printW.focus();
    printW.print();
    // if(this.commonService.osname != "Mac OS"){
    //   printW.close();
    // }else{
    //   console.log("not Windows os")
    // }
  }

  searchreport(){
    // console.log("from date and to date ------->>",this.fromDate,this.toDate)
    if(this.fromdate != '' && this.todate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.fromdepdate != '' && this.todepdate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.depcity != '' && this.arrcity != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else{
      this.searchreportbool=true;
    }
  }

  saerch(){
    if(this.searchText.length >= 2){
      this.onSearch()
      this.searchFlagReset = true;
    }
  }

  SearchAirline(){
    if(this.airline_code.length == 2){
      this.onSearch()
    }
  }

  onSearch() {
    var element;
    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }
    if(this.airline_code != ''){
      this.airline_code;
    }

    var DataSearch = {
      supplier_id:Number(this.userid),
      dep_city_code:this.depcity.toUpperCase(),  arr_city_code:this.arrcity.toUpperCase(),
      booking_start_date:this.fromdate,  booking_end_date:this.todate,
      dep_start_date:this.fromdepdate,  dep_end_date:this.todepdate,
      search: this.searchText,
      airline_code: this.airline_code,
      page_no: 1,
      page_limit: 10,
    }
    // this.sharedService.post('v1/booking/my_api_booking_list', DataSearch).subscribe((res) => {
      this.sharedService.post('v1/super/flight/view_bookings',DataSearch).subscribe((res)=> {
        if (res.replyCode == "success") {
          this.searchFlagReset = true;
          this.ccData = res.flight;  this.totalpage = (res.count)/(DataSearch.page_limit);  this.TotalCount = res.count;
        }
      }, err => {
      })
  }

  SearchDep(){
    if(this.depcity.length == 3){
      this.searchreport()
    }
  }

  SearchArr(){
    if(this.arrcity.length == 3){
      // console.log("arrcity")
      this.searchreport()
    }
  }

  download() {
    this.ngxLoader.start();
    // var data = {  todate: this.todate,  fromdate: this.fromdate  }
    var fromdate;  var todate; var fromdepdate;  var todepdate; var depcity; var arrcity;
    if (this.fromdate != '') {  fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');  }
    else {  fromdate = ''  }
    if (this.todate != '') {  todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');  }
    else {  todate = ''  }

    if (this.fromdepdate != '') {  fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');  }
    else {  fromdepdate = ''  }
    if (this.todepdate != '') {  todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');  }
    else {  todepdate = ''  }

    if (this.depcity != '') {  depcity = this.depcity;  }
    else {  depcity = ''  }
    if (this.arrcity != '') {  arrcity = this.todate;  }
    else {  arrcity = ''  }

    // var requestdata = {
    //     page_limit: this.TotalCount,  page_no: "",
    //     fromBookingDate:fromdate,  toBookingDate: todate,
    //     fromDepDate: fromdepdate,  toDepDate: todepdate,
    //     depCity: depcity,  arrCity:arrcity
    // }
    var DataSearch = {
      page_limit: this.TotalCount,  page_no: "",
      fromBookingDate:fromdate,  toBookingDate: todate,
      fromDepDate: fromdepdate,  toDepDate: todepdate,
      depCity: depcity,  arrCity:arrcity,
      search: '',
      display_type: this.type1, inventoryOwner:'',  partnerId:'',
      pnr:'', bookingStatus:'',  status: "",  flight_type: "",
      sector: "",  start_date: fromdate,  end_date: todate,
      order_key: "departure_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB",
      user_id:Number(this.userid),
    }

    this.searchFlagReset = true;
    // this.filtersearch(requestdata);
    this.sharedService.post('v1/booking/my_api_booking_list', DataSearch).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.TData = res.data;
        this.nodatafound = false;
        // $('#Exportmodal').modal('show');
        setTimeout(() => {
          this.exportdata();
        }, 1300);
      } else {
        this.ngxLoader.stop();  this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.ngxLoader.stop();  this.nodatafound = true;  this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  downloadbooking() {
    var request = {
      supplier_id:Number(this.userid),
      dep_city_code:this.depcity.toUpperCase(),  arr_city_code:this.arrcity.toUpperCase(),
      booking_start_date:this.fromdate,  booking_end_date:this.todate,
      dep_start_date:this.fromdepdate,  dep_end_date:this.todepdate,
      search: this.searchText,
      airline_code: this.airline_code,
      page_no: 1,
      page_limit: "",
    };

    this.sharedService.post('v1/super/flight/view_bookings', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
            this.Bookingdata = res.flight;
            this.TotalCount = res.count;
            // this.keys = Object.keys(this.Bookingdata[0]);

        }
        setTimeout(() => {
            this.exportdata();
        }, 1000);
    }, err => {
    })

  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert1');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element,{dateNF:'dd/MMM/yyyy;@',cellDates:true, raw: true});

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    this.ngxLoader.stop();
    XLSX.writeFile(wb, 'Excel-Report.xlsx');

  }

  onReset(){
    this.bookinglist(this.bookingSendData);
    this.inputSearch=null;
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.fromdate = '';
    this.todate = '';
    this.depcity = '';
    this.arrcity = '';
    this.airline_code = '';
    this.searchText = '';
  }

  onPageChange(event) {

    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }

    var DataSearch = {
      supplier_id:Number(this.userid),
      dep_city_code:this.depcity.toUpperCase(),  arr_city_code:this.arrcity.toUpperCase(),
      booking_start_date:this.fromdate,  booking_end_date:this.todate,
      dep_start_date:this.fromdepdate,  dep_end_date:this.todepdate,
      search: this.searchText,
      airline_code: this.airline_code,
      page_no: event,
      page_limit: 10,
    }
    this.bookinglist(DataSearch);
  }

  summaryShow(event) {
    if (event.checked == true) {
      this.summaryDtl = true;
    } else {
      this.summaryDtl = false;
    }
  }

  viewDetail(element){
    this.booking_id = element.id
    // console.log("booking id check:::::::::::",this.booking_id);
    sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
    window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
    // this.router.navigate([]).then(result => { window.open('/booking-summary/');})
    // this.router.navigate([]).then(result => { window.open('/admin/book-summery/');})
    // this.booking_id = JSON.parse
  }

}
