  import { Component, OnInit} from '@angular/core';
  import { FormGroup, FormBuilder} from '@angular/forms';
  import { Router, ActivatedRoute } from '@angular/router';
  import { SharedserviceService, CommonService } from 'src/app/services';
  import { DatePipe} from '@angular/common';
  import { ToastrManager } from 'ng6-toastr-notifications';
  import jspdf from 'jspdf';
  import domtoimage from 'dom-to-image';
  import { DateAdapter } from '@angular/material';
  import { NgxUiLoaderService } from 'ngx-ui-loader';
  import * as XLSX from 'xlsx';
  import { environment } from 'src/environments/environment';
  declare var $: any;
  import * as RedirectionPath from '../../common/redirect';
  @Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss'],
    providers: [DatePipe, NgxUiLoaderService]
  })
  export class SearchResultComponent implements OnInit {
    public environment = environment;
    public dm = 'in';
    NoOfAirline:any = [
      { id: '0', name: "Select AirLine" },
      { id: 'INDIGO', name: "INDIGO" },
      { id: 'SPICEJET', name: "SPICEJET" },
      { id: 'GOAIR', name: "GOAIR" },
      { id: 'AIR AISA', name: "AIR AISA" },
    ]
    airline_check="0"
    bill_id: any = '';
    acc_bill_no: any = '';
    bodydata: any = {};
    addNewBill: FormGroup;
    public editBill: FormGroup;
    sale_data: any;
    purchase_date: any;
    keys: any;
    forApi: boolean = false
    submitted: boolean = false;
    minDate: any;
    maxDate: any;
    date: any;
    dataId: any
    editBillData: any
    Type: any;
    searchreportbool:boolean=false;
    TotalCount: string;
    TTotalCount: string;
    data: any;
    Tdata: any;
    public page: number = 1;
    start = 0;
    msg: any;
    FormId: any;
    flag: boolean = false;
    today_date: Date;
    userdata:any;
    booking_id: any;
    Sectors: any=[];

    fromDate: any = '';
    toDate: any = '';
    fromBookingDate:any='';
    toBookingDate:any='';
    paymentMode:any="0";
    depcity:any='';
    arrcity:any='';
    pnr:any=JSON.parse(sessionStorage.getItem("sidebar_searchtext"));
    passengerfilter:boolean=false;
    p_name:any=''
    NoOfStopData=[
      { id: '0', name: "Select Mode" },
      { id: 'paytm', name: "PayTM" },
      { id: 'wallet', name: "Wallet" },
    ]
    request: { fromBookingDate: any; toBookingDate: any; fromDepDate: any; toDepDate: any; soldFrom: string; depCity: any; arrCity: any; paymentMode: any; inventoryOwner: number; partnerId: number; websiteUserId: number; page_limit: string; page_no: number; sortorder: string; pnr:any; p_name:any;withPassenger:any;bookingStatus:any;};

    constructor(public sharedService: SharedserviceService,
      private fb: FormBuilder,
      public router: Router,
      private toastr: ToastrManager,
      public datepipe: DatePipe,
      private ngxLoader: NgxUiLoaderService,
      private datePipe: DatePipe,
      private dateAdapter: DateAdapter<Date>,
      public commonService: CommonService,
      public activatedRoute: ActivatedRoute) {
      this.activatedRoute.params.forEach((urlParams) => {
        this.bodydata = {
          page_limit: 10,
          page_no: 1,
          // is_billed: true,
          sortorder: "desc",
          fromDate: "",
          toDate: '',
          pnr: JSON.parse(sessionStorage.getItem("sidebar_searchtext")),
          withPassenger: false,
          p_name: "",
          bookingStatus:1
        }

        this.showList();
        // this.showList('sale', this.bodydata);
        this.userdata=JSON.parse(localStorage.getItem("user"));
      });
      // this.activatedRoute.params.forEach((urlParams) => {
      //   this.Id = urlParams['id'];
      //   this.userid = this.Id;
      // })

      this.dateAdapter.setLocale('en-GB');
      this.today_date = new Date()
    }


    ngOnInit() {
      this.ngxLoader.start();
      // this.bodydata = {
      //   page_limit: 10,
      //   page_no: 1,
      //   // is_billed: true,
      //   sortorder: "desc",
      //   fromDate: "",
      //   toDate: '',
      //   pnr: JSON.parse(sessionStorage.getItem("sidebar_searchtext")),
      //   withPassenger: false,
      //   p_name: "",
      //   bookingStatus:1
      // }
      // this.showList('sale', this.bodydata);
      this.showList();
      this.ngxLoader.stop();
    }

    resetFunction(){
      // this.router.navigate(['dashboard/accounting/sale'])
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['dashboard/accounting/sale']);
    }

    showList() {
        var request ={
          pnr:JSON.parse(sessionStorage.getItem("sidebar_searchtext")),
        }
        this.sharedService.post('v1/accounting/pnr_search', request).subscribe((res: any) => {
        // this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
          if (res.replyCode == "success") {
            this.sale_data = res.data;
            this.TotalCount = res.count;
            this.Sectors = res.sectors;
            this.keys = Object.keys(this.sale_data[0]);
            // if(this.sale_data[0].airline_code == '6E'){
            //   this.airline_check='INDIGO'
            // }else if(this.sale_data[0].airline_code == 'SG'){
            //   this.airline_check='SPICEJET'
            // }else if(this.sale_data[0].airline_code == 'G8'){
            //   this.airline_check='GOAIR'
            // }else if(this.sale_data[0].airline_code == 'AK'){
            //   this.airline_check='AIR ASIA'
            // }else{
            //   this.airline_check='0'
            // }


            // this.sale_data.forEach(function (value) {
            // })
          }
        }, err => {
          this.toastr.errorToastr(err.replyMsg)
        })
    }

    // showList(type, request) {
    //   if (type == 'sale') {
    //     var request ={
    //       pnr:
    //     }
    //     this.sharedService.post('v1/accounting/pnr_search', request).subscribe((res: any) => {
    //     // this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
    //       if (res.replyCode == "success") {
    //         this.sale_data = res.data;
    //         this.TotalCount = res.count;
    //         this.Sectors = res.sectors;
    //         this.keys = Object.keys(this.sale_data[0]);
    //         if(this.sale_data[0].airline_code == '6E'){
    //           this.airline_check='INDIGO'
    //         }else if(this.sale_data[0].airline_code == 'SG'){
    //           this.airline_check='SPICEJET'
    //         }else if(this.sale_data[0].airline_code == 'G8'){
    //           this.airline_check='GOAIR'
    //         }else if(this.sale_data[0].airline_code == 'AK'){
    //           this.airline_check='AIR ASIA'
    //         }else{
    //           this.airline_check='0'
    //         }


    //         // this.sale_data.forEach(function (value) {
    //         // })
    //       }
    //     }, err => {
    //       this.toastr.errorToastr(err.replyMsg)
    //     })
    //   }
    // }

    paymentSelect(event){
      this.paymentMode=event;
      this.onSearch()
    }

    SearchDep(){
      if(this.depcity.length == 3){
        this.searchreport()
      }
    }

    SearchArr(){
      if(this.arrcity.length == 3){
        this.searchreport()
      }
    }
    Searchpnr(){
      if(this.pnr.length >= 6){
        this.searchreport()
      }else if(this.pnr.length == 0){
        this.pnr='';
        this.searchreport()
      }
    }

    Searchpassenger(){
      if(this.p_name.length >= 3){
        this.searchreport()
      }else if(this.p_name.length == 0){
        this.p_name='';
        this.searchreport()
      }
    }




    onSearch() {
      if (this.fromDate != '') {
        this.fromDate = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
      } else {
        this.fromDate = ''
      }
      if (this.toDate != '') {
        this.toDate = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd');
      } else {
        this.toDate = ''
      }
      if (this.fromBookingDate != '') {
        this.fromBookingDate = this.datePipe.transform(new Date(this.fromBookingDate), 'yyyy-MM-dd');
      } else {
        this.fromBookingDate = ''
      }
      if (this.toBookingDate != '') {
        this.toBookingDate = this.datePipe.transform(new Date(this.toBookingDate), 'yyyy-MM-dd');
      } else {
        this.toBookingDate = ''
      }
      // if (this.pnr != '') {
      //   this.pnr = this.pnr;
      // } else {
      //   this.pnr = ''
      // }


      // var request = {
      //   page_limit: '10',
      //   page_no: 1,
      //   sortorder: "desc",
      //   fromDate: this.fromDate,
      //   toDate: this.toDate
      // }

      this.request = {
        fromBookingDate:this.fromBookingDate,
        toBookingDate:this.toBookingDate,
        fromDepDate:this.fromDate,
        toDepDate:this.toDate,
        soldFrom:'',
        depCity:this.depcity.toUpperCase(),
        arrCity:this.arrcity.toUpperCase(),
        paymentMode:this.paymentMode,
        inventoryOwner:0,
        partnerId:0,
        websiteUserId:0,
        page_limit: '10',
        page_no: 1,
        sortorder: "desc",
        pnr:this.pnr.toUpperCase(),
        p_name:this.p_name,
        withPassenger:true,
        bookingStatus:1

      }
        this.sharedService.post('v1/accounting/complete_sale_list', this.request).subscribe((res: any) => {
          if (res.replyCode == "success") {
            this.sale_data = res.data;
            this.TotalCount = res.count;
            this.keys = Object.keys(this.sale_data[0]);
          }
        }, err => {
        })
    }

    filterwithpass(){
      if(this.passengerfilter == false ){
        this.passengerfilter=true;
        this.onSearch()
      }else{
        this.passengerfilter=false;
        // this.p_name='';
        this.onSearch()
      }

    }


    onPageChange(event) {
      if (this.fromDate != '') {
        this.fromDate = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
      } else {
        this.fromDate = ''
      }
      if (this.toDate != '') {
        this.toDate = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd');
      } else {
        this.toDate = ''
      }
      if (this.fromBookingDate != '') {
        this.fromBookingDate = this.datePipe.transform(new Date(this.fromBookingDate), 'yyyy-MM-dd');
      } else {
        this.fromBookingDate = ''
      }
      if (this.toBookingDate != '') {
        this.toBookingDate = this.datePipe.transform(new Date(this.toBookingDate), 'yyyy-MM-dd');
      } else {
        this.toBookingDate = ''
      }

      var request = {
        fromBookingDate:this.fromBookingDate,
        toBookingDate:this.toBookingDate,
        fromDepDate:this.fromDate,
        toDepDate:this.toDate,
        soldFrom:'',
        depCity:this.depcity.toUpperCase(),
        arrCity:this.arrcity.toUpperCase(),
        paymentMode:this.paymentMode,
        inventoryOwner:0,
        partnerId:0,
        websiteUserId:0,
        page_limit: '10',
        page_no: event,
        sortorder: "desc",
        withPassenger:true,
        p_name: this.p_name,
        pnr:this.pnr.toUpperCase(),
        bookingStatus:1
      }
        this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
          if (res.replyCode == "success") {
            this.sale_data = res.data;
            this.TotalCount = res.count;
            this.keys = Object.keys(this.sale_data[0]);
          }
        }, err => {

        })
    }


    exportexcel() {
      var request = {
        fromBookingDate:this.fromBookingDate,
        toBookingDate:this.toBookingDate,
        fromDepDate:this.fromDate,
        toDepDate:this.toDate,
        soldFrom:'',
        depCity:this.depcity.toUpperCase(),
        arrCity:this.arrcity.toUpperCase(),
        paymentMode:this.paymentMode,
        inventoryOwner:0,
        partnerId:0,
        websiteUserId:0,
        page_limit: '',
        page_no: 1,
        sortorder: "desc",
        pnr:this.pnr.toUpperCase(),
        p_name:this.p_name,
        withPassenger:true,
        bookingStatus:1
      }
      this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
          if (res.replyCode == "success") {
            this.Tdata = res.data;
            this.TTotalCount = res.count;
            this.keys = Object.keys(this.Tdata[0]);
          }
        }, err => {

      })
      setTimeout(() => {
        // console.log("Tdaatatatata ::::::::::", this.Tdata)
        // $('#Exportmodal').modal('show');
        this.exportdata(this.Tdata);
      }, 1000);
    }


    exportdata(data): void {
      /* table id is passed over here */
      let element = document.getElementById('contentToConvert');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      var name;
      if(data[0].reportfromdate == undefined){
        name=data[0].flight_number+'.xlsx';
      }else{
        name=data[0].departure_date+'-'+data[0].pnr_number+'.xlsx';
      }
      /* save to file */
      XLSX.writeFile(wb, name.toString());

    }

    viewDetail(listData){
      this.booking_id = listData.id;
      // return;
      sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
      window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
    }

    exportpassengerexcel(): void {
      var request = {
        fromBookingDate:this.fromBookingDate,
        toBookingDate:this.toBookingDate,
        fromDepDate:this.fromDate,
        toDepDate:this.toDate,
        soldFrom:'',
        depCity:this.depcity.toUpperCase(),
        arrCity:this.arrcity.toUpperCase(),
        paymentMode:this.paymentMode,
        inventoryOwner:0,
        partnerId:0,
        websiteUserId:0,
        page_limit: '',
        page_no: 1,
        sortorder: "desc",
        pnr:this.pnr.toUpperCase(),
        p_name:this.p_name,
        withPassenger:true,
        bookingStatus:1
      }
      this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
          if (res.replyCode == "success") {
            this.Tdata = res.data;
            this.TTotalCount = res.count;
            this.keys = Object.keys(this.Tdata[0]);
          }
        }, err => {

      })
      setTimeout(() => {
        // $('#Exportmodal').modal('show');
        /* table id is passed over here */
        let element = document.getElementById('contentToConvert2');
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        var name;
        if(this.Tdata[0].reportfromdate == undefined){
          name=this.Tdata[0].flight_number+'.xlsx';
        }else{
          name=this.Tdata[0].departure_date+'-'+this.Tdata[0].pnr_number+'.xlsx';
        }
        /* save to file */
        XLSX.writeFile(wb, name.toString());
      }, 1000);
    }

    generatePDF() {
      var request = {
        fromBookingDate:this.fromBookingDate,
        toBookingDate:this.toBookingDate,
        fromDepDate:this.fromDate,
        toDepDate:this.toDate,
        soldFrom:'',
        depCity:this.depcity.toUpperCase(),
        arrCity:this.arrcity.toUpperCase(),
        paymentMode:this.paymentMode,
        inventoryOwner:0,
        partnerId:0,
        websiteUserId:0,
        page_limit: '',
        page_no: 1,
        sortorder: "desc",
        pnr:this.pnr.toUpperCase(),
        p_name:this.p_name,
        withPassenger:true,
        bookingStatus:1
      }
      this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
          if (res.replyCode == "success") {
            this.Tdata = res.data;
            this.TTotalCount = res.count;
            this.keys = Object.keys(this.Tdata[0]);
          }
        }, err => {

      })
      setTimeout(() => {
        var data = document.getElementById('contentToConvert');
        this.ngxLoader.start();
        const options = { background: 'white', height: 595 , width:842};
        var imgWidth = 208;
        var imgHeight = 50 * imgWidth / imgWidth;

        domtoimage.toPng(data, options).then((dataUrl) => {
          //Initialize JSPDF
          const doc = new jspdf('l', 'mm', 'a4');
          //Add image Url to PDF
          doc.addImage(dataUrl, 'PNG', 0, 0, imgWidth, imgHeight);
          this.ngxLoader.stop();
          var name;
          if(this.Tdata[0].reportfromdate == undefined){
            name=this.Tdata[0].flight_number+'.pdf';
          }else{
            name=this.Tdata[0].departure_date+'-'+this.Tdata[0].pnr_number+'.pdf';
          }
          doc.save(name.toString());
        });
      }, 1000);
    }

    searchreport(){
      // console.log("from date and to date ------->>",this.fromDate,this.toDate)
      if(this.fromDate != '' && this.toDate != ''){
        this.searchreportbool=true;
        this.onSearch()
      }else if(this.fromBookingDate != '' && this.toBookingDate != ''){
        this.searchreportbool=true;
        this.onSearch()
      }else if(this.depcity != '' && this.arrcity != ''){
        this.searchreportbool=true;
        this.onSearch()
      }else if(this.pnr != ''){
        this.searchreportbool=true;
        this.onSearch()
      }else if(this.p_name != ''){
        this.searchreportbool=true;
        this.onSearch()
      }else{
        this.searchreportbool=true;
      }
    }

    reverseAndTimeStamp(dateString) {
      const reverse = new Date(dateString.split("-").reverse().join("-"));
      return reverse.getTime();
    }

  }
