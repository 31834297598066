import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit} from '@angular/core';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { ApiRequestService } from 'src/app/services/api.service';


declare var $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit{
  isLoggedInUser:any;
  payload:any;
  latestUpdate: any;
  isUpComming:any;
  constructor(public router: Router,private activatedRoute:ActivatedRoute,public apiService:ApiRequestService ,public manageService:ManagedataService) {
    
  }
  ngOnInit() {
    this.manageService.GetLoggedInUserDetails().subscribe((response:any)=>{
      if(Object.keys(response).length>0){
        this.isLoggedInUser=response;
      }
    })
    this.apiService.onLatestNews(this.payload);
    this.apiService.onUpcommingList(this.payload)
    this.manageService.GetLatestUpdates().subscribe((data:any)=>{
      if(data.length>0){
        this.latestUpdate = data;
      }
    })
    this.manageService.GetUpcommingData().subscribe((data:any)=>{
      if(data.length>0){
        this.isUpComming = data;
      }
    })
  }

  onFlightUrl(url:any,value:any){
    if(value==1){
      window.open(url, '_blank');
    }
  }
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.router.navigateByUrl('/auth/login')
  }
}
