import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
import { Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/commonservice.service'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
declare var $: any;

@Component({
  selector: 'app-partnerAccount',
  templateUrl: './partnerAccount.component.html',
  styleUrls: ['./partnerAccount.component.scss'],
  providers: [NgxUiLoaderService, Ng2SearchPipeModule]

})
export class PartnerAccountComponent implements OnInit {
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  NoOfStopData=[
    { id: 'cash', name: "CASH" },
    { id: 'cheque', name: "CHEQUE" },
    { id: 'rtgs', name: "RTGS" },
    { id: 'neft', name: "NEFT" },
    { id: 'imps', name: "IMPS" }
  ]
  searchText:any;
  usertype:any='balance';
  sort_company_name: any = "desc";
  sort_email: any = "desc";
  sort_credit_limit: any = "desc";

  bodydata: any = {};
  TotalCount: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; status: any; FormId: any; flag: boolean = false;
  searchFlagReset: boolean = false;
  sortedList: any[];
  accountData: any;
  ccData: any;
  data2: any;
  old_credit_limit: any;
  public addaccountValue: FormGroup;
  public addaccountPartner: FormGroup;
  public sentrequestform: FormGroup;
  submitted: boolean = false;
  userdata: any;
  old_available_balance: any; detail_data: any;
  
  RData:any;
  public imagePath;
  image:any;
  File: File=null;
  public message: string='';
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public dataservice: CommonService,
    public router: Router,
    private toastr: ToastrManager,
    private activatedRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    this.sortedList = this.ccData;
  }


  ngOnInit() {
    if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.bodydata = {
        page_limit: 10,
        page_no: "1",
        search_value: "",
        account_type: "patner",
        order_key: "",
        order_value: "",
      }
      this.accountList(this.bodydata);
      this.addaccountPartner = this.fb.group({
        share_inventorie_id: ['', Validators.required],
        share_to_user_id: ['', Validators.required],
        amount: ['', Validators.required],
        narration: ['', Validators.required],
      })
      this.sentrequestform = this.fb.group({
        type: ['balance', Validators.required],
        amount: ['', Validators.required],
        mode: ['', Validators.required],
        reference: ['', Validators.required],
        image: [''],
        comment: ['']
      });
    }
  }

  applyFilter(event: Event) {

  }

  SentRequest(data){
    this.RData=data;
    this.submitted=false;
    this.sentrequestform.reset();
    this.sentrequestform = this.fb.group({
      type: ['balance', Validators.required],
      amount: ['', Validators.required],
      mode: ['', Validators.required],
      reference: ['', Validators.required],
      image: [''],
      comment: ['']
    });
    $('#sentmodal').modal('show');
  }


  sortfun(orderkey, type) {
    if (orderkey == 'company_name' && type == 'asc') {
      this.sort_company_name = 'asc';
    } else {
      this.sort_company_name = 'desc';
    }
    if (orderkey == 'email' && type == 'asc') {
      this.sort_email = 'asc';
    } else {
      this.sort_email = 'desc';
    }
    if (orderkey == 'credit_limit' && type == 'asc') {
      this.sort_credit_limit = 'asc';
    } else {
      this.sort_credit_limit = 'desc';
    }
    this.searchFlagReset = true;
    var DataSearch = {
      page_limit: 10,
      page_no: "",
      search_value: "",
      account_type: "patner",
      order_key: orderkey,
      order_value: type,
    }
    this.accountList(DataSearch)
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  get ac() { return this.addaccountValue.controls; }
  get f() { return this.sentrequestform.controls; }
  get pc() { return this.addaccountPartner.controls; }

  //*********shorting Start*********** */
  sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedList = data;
      return;
    }
    this.sortedList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'company_name': return compare(a.company_name, b.company_name, isAsc);
        case 'email': return compare(a.email, b.email, isAsc);
        case 'credit_limit': return compare(a.credit_limit, b.credit_limit, isAsc);
        case 'available_balance': return compare(a.available_balance, b.available_balance, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  accountdetail(list) {
    if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      var data2 = {
        share_inventorie_id: list.share_inventorie_id,
        share_to_user_id: list.share_to_user_id,
        share_by_user_id: list.share_by_user_id,
        amount: list.credit_limit
      }
      sessionStorage.setItem("account_detail_data", JSON.stringify(data2))
      this.router.navigateByUrl("partner/partnerBalance");
    }
  }

  accountList(bodydata) {
    this.ngxLoader.start();
    if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.sharedService.post('v1/account/listing', bodydata).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.ccData = res.data;
          if (res.data.length > 0) {
            this.flag = false;
            this.detail_data = res.company_details;
            this.data = res.data;
            this.keys = Object.keys(this.data[0]);
            this.TotalCount = res.totalRecords
            this.ngxLoader.stop();
          } else {
            this.flag = true;
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
          }
        } else {
          this.flag = true;
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          this.ngxLoader.stop();
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.flag = true;
        this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        this.ngxLoader.stop();
        this.toastr.errorToastr(err.error.replyMsg, 'Error');
      });
    }

  }

  accountShow(data) {
    this.FormId = data;
    this.old_credit_limit = data.credit_limit;
    this.old_available_balance = data.available_balance;
    if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.addaccountPartner.patchValue({
        share_inventorie_id: data.share_inventorie_id,
        share_to_user_id: data.share_to_user_id,
        share_by_user_id: data.share_by_user_id,

      })
    }

    $('#addBalance').modal('show');
  }
  addAccount() {
    var self = this;
    self.submitted = true;
    if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      if (self.addaccountPartner.invalid) {
        return;
      } else {
        var request_data = self.addaccountPartner.value;
        self.sharedService.postRequest('v1/account/add_balance', request_data).subscribe((res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              this.accountData = res.data;
              self.submitted = false;
              this.accountList(this.bodydata);
              $('#addBalance').modal('hide');
              this.sentrequestform = this.fb.group({
                type: ['balance', Validators.required],
                amount: ['', Validators.required],
                mode: ['', Validators.required],
                reference: ['', Validators.required],
                image: [''],
                comment: ['']
              });
              self.addaccountPartner.reset();
              self.submitted = false;
              self.toastr.successToastr(res.replyMsg, 'Success');
            } else {
              self.submitted = false;
              self.addaccountPartner.reset();
              self.toastr.errorToastr(res.replyMsg, 'Error');
            }
          } else {
            self.submitted = false;
            self.addaccountValue.reset();
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          self.submitted = false;
          $('#addBalance').modal('hide');
          this.sentrequestform = this.fb.group({
            type: ['balance', Validators.required],
            amount: ['', Validators.required],
            mode: ['', Validators.required],
            reference: ['', Validators.required],
            image: [''],
            comment: ['']
          });
          self.addaccountPartner.reset();
          self.toastr.errorToastr(error.error.replyMsg);
        });
      }
    }

  }

  clBalance() {
    $('#addBalance').modal('hide');
    this.submitted = false;
  }

  saerch(e:any){
		if(e.code == "Enter" || e.code == "NumpadEnter"){
			this.onSearch();
		}
	}

  onSearch() {
    this.searchFlagReset = true;
    var bodydata = {
      page_limit: "",
      page_no: "",
      account_type: "patner",
      search_value: String(this.inputSearch.nativeElement.value),
      order_key: "",
      order_value: "",
    }
    this.accountList(bodydata);
  }

  onReset() {
    this.inputSearch.nativeElement.value = '';
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.accountList(this.bodydata);
  }

  onPageChange(event) {
    this.bodydata['page'] = event;
    this.accountList(this.bodydata);
  }

  addrequest(type){
    this.submitted=false;
    this.sentrequestform.reset();
    if(type == 'balance'){
      this.usertype='balance';
      this.sentrequestform = this.fb.group({
        type: ['balance', Validators.required],
        amount: ['', Validators.required],
        mode: ['', Validators.required],
        reference: ['', Validators.required],
        image: [''],
        comment: ['']
      });
    }
    else{
      this.usertype='credit'
      this.sentrequestform = this.fb.group({
        type: ['credit', Validators.required],
        amount: ['', Validators.required],
        comment: ['']
      });
    }  
    $('#sentmodal').modal('show');
  }

  preview(files) {
    if (files.length === 0){ return; }
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) { this.message = "Only images are supported."; return; }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { this.image = reader.result;  this.message=''; }
  }

  onFileChanged(event) {
    this.File = event.target.files[0];
  }

  addRequest(){
    this.submitted = true;
    if (this.sentrequestform.invalid) { 
      this.File=null;      
      this.image=null;      
      return;
    }else{
      const self=this;
      self.sharedService.loadingShow = true; 
        self.sharedService.loadingShow = true;
        const bodyObject = new FormData();
        if(this.usertype == 'balance'){
          if(self.File == null){  bodyObject.append('image','');  }else{  bodyObject.append('image', self.File, self.File.name);  }
          bodyObject.append('to_user_id', self.RData.share_by_user_id);
          bodyObject.append('type', self.sentrequestform.value.type);
          bodyObject.append('amount', self.sentrequestform.value.amount);
          bodyObject.append('mode', self.sentrequestform.value.mode);
          bodyObject.append('reference', self.sentrequestform.value.reference);
          bodyObject.append('comment', self.sentrequestform.value.comment);
      }else{
          bodyObject.append('to_user_id', self.RData.share_by_user_id);
          bodyObject.append('type', self.sentrequestform.value.type);
          bodyObject.append('amount', self.sentrequestform.value.amount);
          bodyObject.append('comment', self.sentrequestform.value.comment);
      }

        
        this.sharedService.postRequest('v1/request/send',bodyObject).subscribe((res:any)=> {
          if(res.replyCode == 'success'){
            this.File=null;
            this.submitted = false;
            this.sentrequestform.reset();
            this.toastr.successToastr(res.replyMsg, 'Success');
            this.image=null;
          }else{
            this.image=null;
            this.File=null;
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
          $('#sentmodal').modal('hide');
        },err=>{
         if(err.error.replyMsg.message){
            this.submitted = false;
            this.image=null;
            this.File=null;
            this.toastr.errorToastr(err.error.replyMsg.message, 'Error');
            $('#sentmodal').modal('hide');
            this.sentrequestform.reset();
          }else{
            this.submitted = false
            this.image=null;
            this.File=null;
            $('#sentmodal').modal('hide');
            this.toastr.errorToastr(err.error.replyMsg, 'Error');
          }
        });
}

  }
}