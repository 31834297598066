import { CustomDepartureSelectBoxComponent, FlightSearchComponent, SearchFlightResultComponent } from '../_shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { MaterialModule } from '../route-file/material.module';
import { NgModule } from '@angular/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CustomArrivalSelectBoxComponent } from './custom-arrival-select-box/custom-arrival-select-box.component';
import { NgSelectModule } from '@ng-select/ng-select';
const CommonComponent=[ CustomDepartureSelectBoxComponent, CustomArrivalSelectBoxComponent, FlightSearchComponent, SearchFlightResultComponent];

@NgModule({
  declarations: [...CommonComponent],
  exports:[...CommonComponent],
  imports:[FormsModule, ReactiveFormsModule, MaterialModule, CommonModule, NgxSliderModule,NgSelectModule]
})
export class SharedModule {}

