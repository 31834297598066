import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth.html',
  styleUrls: ['./auth.scss']
})
export class AuthLayoutComponent implements OnInit {
  anio: number = new Date().getFullYear();
  constructor() { }
    ngOnInit() {
  
  }
  
}
