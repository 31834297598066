import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestService } from 'src/app/services/api.service';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-flight-search',
  templateUrl: './flight-search.component.html',
  styleUrls: ['./flight-search.component.scss']
})
export class FlightSearchComponent implements OnInit{
  // Form Variable
  public searchForm: FormGroup | any; submitted = false; btnsubmitted: boolean = false;
  travellers: number=1; isTravelsObject:any={ adult:1, child:0, infant:0 };
  public isTripValue: any = 'one_way'; public isMasterOptions: any = '1';
  isEconomy: boolean=true;
  isPft: boolean=true; 
  constructor( private formBuilder: FormBuilder, public datePipe: DatePipe, public router: Router,private dateAdapter: DateAdapter<Date>,public manageDataService:ManagedataService,public apiService: ApiRequestService,public routActivate:ActivatedRoute) { this.dateAdapter.setLocale('en-GB'); }
  SelectedDepartureData:any={}; SelectedArrivalData:any={};
  departureDateInputFilter: any; mindepartureDate: Date = new Date(); maxdepartureDate: Date = new Date();
  arrivalDateInputFilter: any; minarrivalDate: Date = new Date(); maxarrivalDate: Date = new Date();
  isDateNextPreviousOptionFlag:boolean=false;isSelectedDateArray:any=[];
  currentDateStep: any = 0;to_day: any;
  isSelectedDate:any="";
  travellersForm: FormGroup;
  isLoggedInStatus:boolean=false;
  isTravellerBoxFlag:boolean=false;
  getDepartureCity: any;
  getArrivalCity: any;
  getDepartDate: any;
  getReturnDate: any;
  getDepAirportId: any;
  getArrAirportId: any;
  getDepCityId: any;
  getArrCityId: any;
  getAdult: any;
  isSearchDataURL: any;
  getChild: any;
  getInfant: any;
  getTravels: any;
  getTripType: any;
  return_date: any="";
  ngOnInit(): void {
    this.manageDataService.GetLoggedInUserDetails().subscribe(data =>{
      // console.log("LOgged",data)
      if(Object.keys(data).length>0){
        this.apiService.onDepartureFlightList({ flight_type: this.isTripValue });
      }
    });
    this.searchForm = this.formBuilder.group({
      departure_city_id: ['', Validators.required], arrival_city_id: ['', Validators.required],
      departure_date: ['', Validators.required], return_date: [''],
      adult: ['1'], child: ['0'], travellers: [1], infant: ['0'],
      cabin_class:[""],pft:[""]
    });
    this.travellersForm = this.formBuilder.group({  adult: ['1'],  child: ['0'],  infant: ['0'],  });
    this.manageDataService.GetTripValue().subscribe((data: any) => {
      if (data) {
        this.isTripValue = data;
        if (this.isMasterOptions == 1) {
          this.apiService.onDepartureFlightList({ flight_type: this.isTripValue });
        }
      }
    })
    this.manageDataService.GetMasterTripOptions().subscribe((data: any) => {
      if (data) {
        this.isMasterOptions = data;
      }
    })
    this.manageDataService.GetSelectedTravelersData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        var count = 0;
        count = count + Number(data.adult) + Number(data.child) + Number(data.infant);
        this.searchForm.controls["travellers"].setValue(count);
      }
    })

    if(this.isLoggedInStatus){

    }

    this.manageDataService.GetSelectedDepartureData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.SelectedDepartureData = data;
        this.searchForm.controls["departure_city_id"].setValue(this.SelectedDepartureData.departure_city_id);
      }
    })
    this.manageDataService.GetSelectedArrivalData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.SelectedArrivalData = data;
        this.searchForm.controls["arrival_city_id"].setValue(this.SelectedArrivalData.arrival_city_id);
      }
    })
    this.manageDataService.GetDepartureDateArray().subscribe((data: any) => {
      if (data.length > 0) {
        let validdates: any = [];
        this.manageDataService.SetAvailableToDate(data)
        data.forEach((element: any) => {
          var dateObject: any = this.datePipe.transform(element.flight_date, 'MM/dd/yyyy');
          validdates.push(new Date(dateObject))
        });
        this.departureDateInputFilter = (d: Date): any => { if (d) { const time = d.getTime(); return validdates.find((x: any) => x.getTime() == time); } }
        this.mindepartureDate = data[0].flight_date;
        this.maxdepartureDate = data[data.length - 1].flight_date;
      }else{
        this.departureDateInputFilter = (d: Date): any => { if (d) { const time = d.getTime(); return data.find((x: any) => x.getTime() == time); } }
        this.searchForm.controls["departure_date"].setValue({});
        this.departureDateInputFilter=data
      }
    })
    this.manageDataService.GetAvailableToDate().subscribe((data)=>{
      if(data && data.length>0){
      this.isSelectedDateArray=data;
      }
    })
    this.manageDataService.GetAvailableCurrentDate().subscribe((data)=>{
      if(data){
        this.to_day=data
        this.currentDateStep = this.isSelectedDateArray.findIndex((e:any) => e.flight_date == data);
      }
    })
    this.manageDataService.GetReturnDateArray().subscribe((data: any) => {
      if (data.length > 0) {
        let validdates: any = [];
        data.forEach((element: any) => {
          var dateObject: any = this.datePipe.transform(element.flight_date, 'MM/dd/yyyy');
          validdates.push(new Date(dateObject))
        });
        this.arrivalDateInputFilter = (d: Date): boolean => { if (d) { const time = d.getTime(); return validdates.find((x: any) => x.getTime() == time); } else { return false } }
        this.minarrivalDate = data[0].flight_date;
        this.maxarrivalDate = data[data.length - 1].flight_date;
      }
    })
    this.manageDataService.GetRegularCityArray().subscribe((data:any)=>{
      if(data.length>0){
        this.manageDataService.SetDepartureArray(data)
      }
    })
    // this.onSearchApiRequest();

    this.routActivate.queryParams.subscribe(params => {
      this.getDepartureCity = params['dep'];
      this.getArrivalCity = params['arr'];
      this.getDepartDate = params['depDt'];
      this.getReturnDate = params['retDt'];
      this.getDepAirportId = params['depAid'];
      this.getArrAirportId = params['arrAid'];
      this.getDepCityId = params['depCid'];
      this.getArrCityId = params['arrCid'];
      this.getAdult = params['adult'];
      this.getChild = params['child'];
      this.getInfant = params['inf'];
      this.getTravels = params['tTrvs']
      this.getTripType = params['tType'];
    });
    if (this.getDepartureCity && this.getArrivalCity && this.getDepartDate) {
      this.isSearchDataURL={
        dep_city_code:this.getDepartureCity,
        arr_city_code:this.getArrivalCity,
        dep_airport_id:Number(this.getDepAirportId),
        arr_airport_id:Number(this.getArrAirportId),
        dep_city_id:Number(this.getDepCityId),
        arr_city_id:Number(this.getArrCityId),
        departure_date:this.getDepartDate,
        return_date:this.getReturnDate ? this.getReturnDate : this.return_date,
        children:Number(this.getChild),
        adult:Number(this.getAdult),
        infant:Number(this.getInfant),
        trip_type:Number(this.getTripType),
        travellers:Number(this.getTravels)
      }
    }
  }
  get f() { return this.searchForm.controls; }
  onSelectTrip(value: any) {
    this.manageDataService.SetSearchFlightResponse({});
    this.manageDataService.SetTripValue(value);
    this.manageDataService.SetSelectedDepartureData({});
    this.manageDataService.SetSelectedArrivalData({});
    this.searchForm.reset();
    sessionStorage.clear();
  }
  onSelectMenu(value: any) {
    this.manageDataService.SetSearchFlightResponse({});
    this.manageDataService.SetMasterTripOptions(value?2:1);
    this.manageDataService.SetSelectedDepartureData({});
    this.manageDataService.SetSelectedArrivalData({});
    this.searchForm.reset();
    sessionStorage.clear();
    if (value) {
      this.isTripValue = "one_way";
      this.onDepartureRegularList();
      this.manageDataService.SetTripValue(value);
      this.searchForm.reset();
      this.searchForm.controls["travellers"].setValue(this.getTravellersCount());
    } else {
      this.isTripValue = "one_way";
      this.apiService.onDepartureFlightList({ flight_type: this.isTripValue });
      this.searchForm.reset();
      this.searchForm.controls["travellers"].setValue(this.getTravellersCount());
    }
  }
  getTravellersCount(){
    var value=0;
    value=value+Number(this.isTravelsObject.adult)+Number(this.isTravelsObject.child)+Number(this.isTravelsObject.infant);
    return value;
  }
  isTravellerObject:any={ adult:1, child:0,infant:0 }
  isTotalTravellersValue:any={ adult:1, child:0,infant:0 };
  changeValue(val){
    this.isTravellerObject.adult=Number(val);
    // this.isTotalTravellersValue = ((this.isTravellerObject.adult)+(this.isTravellerObject.child)+(this.isTravellerObject.infant))
    this.manageDataService.SetSelectedTravelersData(this.isTravellerObject);
  }
  changeValueChild(val){
    this.isTravellerObject.child=Number(val);
    // this.isTotalTravellersValue = ((this.isTravellerObject.adult)+(this.isTravellerObject.child)+(this.isTravellerObject.infant))
    this.manageDataService.SetSelectedTravelersData(this.isTravellerObject);
  }
  changeValueInfant(val){
    this.isTravellerObject.infant=Number(val);
    // this.isTotalTravellersValue = ((this.isTravellerObject.adult)+(this.isTravellerObject.child)+(this.isTravellerObject.infant))
    this.manageDataService.SetSelectedTravelersData(this.isTravellerObject);
  }
  onAddRemoveValue(type:any,value:any){
    if(type == 'adult'){
      if(value == '-1'){
        if(this.isTravellerObject.infant == this.isTravellerObject.adult){
          this.isTravellerObject.infant= Number(this.isTravellerObject.infant)+Number(value)
        }
      }
      this.isTravellerObject.adult= Number(this.isTravellerObject.adult)+Number(value);
    }else if(type == 'child'){
      this.isTravellerObject.child= Number(this.isTravellerObject.child)+Number(value);
    }else if(type == 'infant'){
      if(this.isTravellerObject.infant < this.isTravellerObject.adult){
        this.isTravellerObject.infant= Number(this.isTravellerObject.infant)+Number(value)
      }else if(value == '-1'){
        this.isTravellerObject.infant= Number(this.isTravellerObject.infant)+Number(value)
      }
    }
    this.manageDataService.SetSelectedTravelersData(this.isTravellerObject);
  }
  onOpenCloseTraveller(){
    this.isTravellerBoxFlag=!this.isTravellerBoxFlag;
    // this.commonService.SetTravellerBox(this.isTravellerBoxFlag);
  }
  closeDropBox(){
    this.isTravellerBoxFlag=false;
  }
  onDepartureRegularList() {
    this.apiService.onDepartureArrivalFlightList({ city_name: '' });
  }
  onSelectDepartureDate(date: any) {
    this.isSelectedDate = this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
    this.manageDataService.SetAvailableCurrentDate(this.isSelectedDate);
    if (this.isTripValue == "return") {
      this.getReturnDateList(date);
    }
    if(this.isMasterOptions==2){
        var validdates: any = [];
        var result: any = [];
        for (var i = 0; i < 365; i++) {
          var d = new Date();
          d.setDate(d.getDate() + i);
          const newDate: any = this.datePipe.transform(new Date(d), 'MM/dd/yyyy');
          result.push({ flight_date: this.datePipe.transform(new Date(d), 'yyyy-MM-dd') })
          validdates.push(new Date(newDate));
        }
        setTimeout(() => {
          if (result.length > 0) {
            this.departureDateInputFilter = (d: Date): boolean => { if (d) { const time = d.getTime(); return validdates.find((x: any) => x.getTime() == time); } else { return false } }
            this.mindepartureDate = result[0].flight_date;
            this.maxdepartureDate = result[result.length - 1].flight_date;
          }
        }, 1000);
      this.getReturnDateList(date);
    }
  }
  getReturnDateList(departureDate:any){
    // console.log("departureDate====>",departureDate);    
    if(this.isMasterOptions==2){
      var validdates: any = [];
      var result: any = [];
      for (var i = 0; i < 365; i++) {
         var d = new Date(departureDate);
        //var d = departureDate;
        d.setDate(d.getDate() + i);
        const newDate: any = this.datePipe.transform(new Date(d), 'MM/dd/yyyy');
        result.push({ flight_date: this.datePipe.transform(new Date(d), 'yyyy-MM-dd') })
        validdates.push(new Date(newDate));
      }
      // console.log("result>>>>>>",result);
      // console.log("validdates>>>>>>",result,validdates);
      this.manageDataService.SetReturnDateArray(result);
    }else{
    this.apiService.onArrivalFlightDateList({
      "departure_city_code":this.SelectedDepartureData.departure_city_code,
      "arrival_city_code":this.SelectedArrivalData.arrival_city_code,
      "departure_city_id":this.SelectedDepartureData.departure_city_id,
      "arrival_city_id":this.SelectedArrivalData.arrival_city_id,
      "is_return":this.isTripValue=="one_way"?0:1,
      "departure_date": this.datePipe.transform(departureDate, 'yyyy-MM-dd'),
    })
    }
  }
  dateTransform(date:any){
    var dates = new Date(date);
    return this.datePipe.transform(dates, "dd-MM-yyyy")
  }
  onPrevious() {
    if (this.currentDateStep > 0){
      this.currentDateStep--;
    }
    this.isSelectedDate=this.isSelectedDateArray[this.currentDateStep].flight_date;
    this.onSearchApiRequest();
  }
  onNext() {
    if (this.currentDateStep < this.isSelectedDateArray.length - 1){
      this.currentDateStep++;
    }
    this.isSelectedDate=this.isSelectedDateArray[this.currentDateStep].flight_date;
    this.onSearchApiRequest();;
  }
  setsearchdate(d:any) {
    // this.searchForm.patchValue({
    //   departure_date: this.datePipe.transform(d, "yyyy-MM-dd")
    // });
    // console.log("seaer",this.datePipe.transform(d,"yyyy-MM-dd"))
    // this.searchsubmit();
  }
  onSubmit(){

    this.manageDataService.GetSelectedTravelersData().subscribe((data:any)=>{
      if(data.adult!=null){
        this.isTravelsObject.adult = data.adult  
        this.isTravelsObject.child = data.child  
        this.isTravelsObject.infant = data.infant  
      }
    })
    this.submitted=true;
  // console.log("this.searchForm=======>>>>",this.searchForm);
    this.searchForm.controls['departure_date'].setErrors(null);
    if(this.searchForm.invalid){
      return;
    }else{
      var flightObject: any = {};
      if (this.searchForm.value.departure_date) {
        flightObject.departure_date = this.datePipe.transform(this.searchForm.value.departure_date, 'yyyy-MM-dd');
        if(this.isSelectedDate){
          this.searchForm.value.departure_date = this.isSelectedDate;
        }else{
          this.searchForm.value.departure_date = this.datePipe.transform(this.searchForm.value.departure_date, 'yyyy-MM-dd')
        }
      }
      if (this.searchForm.value.return_date) {
        this.searchForm.value.return_date = this.datePipe.transform(this.searchForm.value.return_date, 'yyyy-MM-dd');
        flightObject.return_date = this.datePipe.transform(this.searchForm.value.return_date, 'yyyy-MM-dd')
      }else{
        flightObject.return_date=""
      }
      this.searchForm.value.adult= this.isTravelsObject.adult;
      this.searchForm.value.child= this.isTravelsObject.child;
      this.searchForm.value.infant= this.isTravelsObject.infant;
      this.searchForm.value.travellers= this.isTravelsObject.travellers;
      // flightObject.cabin_class = this.searchForm.value.cabin_class;
      // flightObject.pft = this.searchForm.value.pft;
      flightObject.formValue = this.searchForm.value;
      flightObject.selectedDeparture = this.SelectedDepartureData;
      flightObject.selectedArrival = this.SelectedArrivalData;
      flightObject.isTripType = this.isTripValue;
      flightObject.isMasterType = this.isMasterOptions;
      sessionStorage.setItem("search_form_Data", JSON.stringify(flightObject));
      // this.commonService.SetArrivalChange(false);
      this.onSearchApiRequest();
    }
  }

  

  onSearchApiRequest(){
    var JsonData:any=sessionStorage.getItem('search_form_Data');
    var payloadObject:any={};
    this.searchForm.controls["departure_date"].setValue(this.isSelectedDate);
    payloadObject.dep_city_code=JSON.parse(JsonData).selectedDeparture.departure_city_code;
    payloadObject.dep_city_id=Number(JSON.parse(JsonData).selectedDeparture.departure_city_id);
    payloadObject.dep_airport_id=Number(JSON.parse(JsonData).selectedDeparture.departure_airport_id);
    payloadObject.departure_date=this.isSelectedDate!='' ? this.isSelectedDate: JSON.parse(JsonData).departure_date;
    payloadObject.arr_city_code=JSON.parse(JsonData).selectedArrival.arrival_city_code;
    payloadObject.arr_city_id=Number(JSON.parse(JsonData).selectedArrival.arrival_city_id);
    payloadObject.arr_airport_id=Number(JSON.parse(JsonData).selectedArrival.arrival_airport_id);
    payloadObject.return_date=JSON.parse(JsonData).formValue.return_date;
    payloadObject.adult=Number(JSON.parse(JsonData).formValue.adult);
    payloadObject.children=Number(JSON.parse(JsonData).formValue.child);
    payloadObject.infant=Number(JSON.parse(JsonData).formValue.infant);
    payloadObject.cabin_class=JSON.parse(JsonData).formValue.cabin_class;
    payloadObject.pft=JSON.parse(JsonData).formValue.pft;
    if(this.isMasterOptions==2){
      // console.log("payloadObject.return_date===>>>",payloadObject);
      // return;
      if(payloadObject.return_date==null){
        payloadObject.return_date=""
        payloadObject.trip_type=this.isTripValue=0;
      }else{
        payloadObject.trip_type=this.isTripValue=1;
      }
    }else{
        payloadObject.trip_type=this.isTripValue== 'one_way'?0:1;
    }
    payloadObject.travellers=this.getTravellersCount();
    // console.log("payloadObject=========>>>>>",payloadObject);      
    if(this.isMasterOptions == 1){
      /// this.apiService.onCustomFlightSearch(payloadObject);
      // const params = {
      //   dep: payloadObject.dep_city_code,
      //   arr: payloadObject.arr_city_code,
      //   depDt: payloadObject.departure_date,
      //   retDt: payloadObject.return_date? payloadObject.return_date : this.return_date,
      //   depAid: payloadObject.dep_airport_id,
      //   arrAid: payloadObject.arr_airport_id,
      //   depCid: payloadObject.dep_city_id,          
      //   arrCid: payloadObject.arr_city_id,          
      //   adult: payloadObject.adult,          
      //   child: payloadObject.children,          
      //   inf: payloadObject.infant,          
      //   tType: payloadObject.trip_type,
      //   tTrvs:payloadObject.travellers
      // };
      // this.router.navigate(['/dashboard'], { queryParams: params })
      this.apiService.onCustomFlightSearch(payloadObject);
    }else{
      // payloadObject.prefered_airline="AI";
      // payloadObject.old='true';
      this.apiService.onRegularFlightSearch(payloadObject);
    }
    this.submitted=false;
    sessionStorage.setItem("search_form", JSON.stringify(payloadObject));
    this.isDateNextPreviousOptionFlag=true;
    // this.router.navigateByUrl("/search-result");
  }
  open_popup() {  $('#totalpassengers').modal('show');  }
  total: any;
  traveller() {
    
    var adult = this.travellersForm.value.adult;  var child = this.travellersForm.value.child;  var infant = this.travellersForm.value.infant;
    if (adult != '' && child != '' && infant != '') {
      if (parseInt(adult) >= parseInt(infant)) {
        this.total = parseInt(adult) + parseInt(child) + parseInt(infant);
        this.searchForm.patchValue({  adult: parseInt(adult),  child: parseInt(child),  infant: parseInt(infant)  });
        this.isTravelsObject.adult=adult;
        this.isTravelsObject.child=child;
        this.isTravelsObject.infant=infant;
        this.isTravelsObject.travellers=this.total;
        this.manageDataService.SetSelectedTravelersData(this.isTravelsObject);
        $('#totalpassengers').modal('hide');  this.searchForm.patchValue({ travellers: this.total });
      } else {  alert('Please select infant less than adult');  }
    } else {  alert('All Field is required');  }
  }
  // onSwitch() {
  //   this.isSwapData = 1;this.isSwapNext=true;
  //   var swapDeparture = this.departureArrayResponse.filter((x: any) => x.departure_city_id == this.SelectedArrivalData.arrival_city_id)
  //   if (swapDeparture.length > 0) {
  //     this.isSwitchArrivalData = this.SelectedDepartureData;
  //     this.isSwitchDepartureData = this.SelectedArrivalData;
  //     console.log("isSwitchDepartureData222=====----->>>>",this.isSwitchDepartureData);
  //     console.log("isSwitchArrivalData=====33333----->>>>",this.isSwitchArrivalData);
  //     this.departureArrayResponse.forEach((element: any) => Object.assign(element, { isSelected: false }));
  //     swapDeparture[0].isSelected = true;
  //     this.commonService.SetSelectedDepartureData(swapDeparture[0]);
  //     // this.onChangeArrivalCity(swapDeparture[0]);
  //     this.apiService.onArrivalFlightList({
  //       flight_type: this.isTripValue,
  //       airport_code: swapDeparture[0].airport_code,
  //       airport_id: swapDeparture[0].airport_id,
  //       airport_name: swapDeparture[0].airport_name,
  //       departure_airport_id: swapDeparture[0].departure_airport_id,
  //       departure_city_code: swapDeparture[0].departure_city_code,
  //       departure_city_id: swapDeparture[0].departure_city_id,
  //       departure_city_name: swapDeparture[0].departure_city_name,
  //       shared_inventory_plan_Id: swapDeparture[0].shared_inventory_plan_Id,
  //       vendor_share_plan: swapDeparture[0].vendor_share_plan,
  //     });
  //     this.commonService.SetArrivalSelectBox(false)
  //     setTimeout(() => {
  //       // this.commonService.GetArrivalArray().subscribe((data:any)=>{
  //       //   if(data){
  //       //     this.swapArrivalData = data.data;
  //       //   }else{
  //       //     this.swapArrivalData="";
  //       //   }
  //       // })
  //       console.log("arrivalArrayAllResponse======>>>>",this.swapArrivalData);
  //       var swapArrival = this.arrivalArrayAllResponse.filter((x: any) => x.arrival_city_id == this.isSwitchArrivalData.departure_city_id)
  //       console.log('swapArrival[0]--------<<<<<<',swapArrival[0]);
  //       swapArrival[0].isSelected = true;
        
  //       this.commonService.SetSelectedArrivalData(swapArrival[0]);
  //       this.searchForm.controls["departure_date"].setValue('');
  //       this.commonService.SetDepartureDateBox(false)
  //     }, 500);
  //   } else {
  //     this.isSwapData = 0;this.isSwapNext=false;
  //     this.messageService.showError(this.SelectedArrivalData.arrival_city_code + '-' + this.SelectedDepartureData.departure_city_code + ' is not available')
  //   }
  // }

}
