import { NgModule } from '@angular/core';
import { ReplaceUnderscorePipe } from '../services';
import { WatermarkComponent } from '../_shared/watermark/watermark';

const COMMON_COMPONENTS = [ReplaceUnderscorePipe, WatermarkComponent];
@NgModule({
  declarations: [...COMMON_COMPONENTS],
  exports: [...COMMON_COMPONENTS],
})
export class ProjectModule { }
