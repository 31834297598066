export const processUrl = (url:any) => {
  var redirectPath:any = "";
  var splitPath= location.pathname.split('/');
  var hostname=location.hostname;
  if(hostname == 'localhost'){
    redirectPath=location.origin+'/booking-summary';
  }else{
    redirectPath=location.origin+'/booking-summary';
    //redirectPath=location.origin+'/'+splitPath[1]+'/'+url;
  }
  return redirectPath;
};
