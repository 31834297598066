import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';


declare var $;
@Component({
  selector: 'app-book-ticket',
  templateUrl: './book-ticket.component.html',
  styleUrls: ['./book-ticket.component.scss'],
  providers: [NgxUiLoaderService, Ng2SearchPipeModule]
})
export class BookTicketComponent implements OnInit {
  userdata: any;
  ticketData: any;
  keys: string[];
  data: any;
  TotalCount: any;
  firstFormGroup:FormGroup;
  secondFormGroup:FormGroup;
  isLinear = false;

  // isLinear = true;
  formNameGroup : FormGroup;
  formPasswordGroup : FormGroup;
  formEmailGroup : FormGroup;
  formPhoneGroup : FormGroup;

  constructor(
    private fb: FormBuilder, public ngxloader: NgxUiLoaderService,
    public sharedService: SharedserviceService,
    public router: Router,
    private toastr: ToastrManager,
    private activatedRoute: ActivatedRoute
  ) {
    this.userdata = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit() {
    

    this.firstFormGroup = this.fb.group({
      children:[''],
      adult:['', Validators.required],
      infant:[''],
      total_book_seats:[''],
      total_amount:['', Validators.required],
      contact_name:['', Validators.required],
      contact_email:['', Validators.required],
      dep_city_code:['', Validators.required],
      arr_city_code:['', Validators.required],
      departure_date:['', Validators.required],
      contact_number:['', Validators.required],
      partner_user_id:['', Validators.required],
      ticket_book_from: ['', Validators.required],
      airline_name: ['', Validators.required],
      live_arrival_date: ['', Validators.required],
      live_departure_time: ['', Validators.required],
      live_arrival_time: ['', Validators.required],
      live_airline_code: ['', Validators.required],
      live_departure_terminal_no: ['', Validators.required],
      live_arrival_terminal_no: ['', Validators.required],
      live_flight_number: ['', Validators.required],
      
    })

   this.secondFormGroup = this.fb.group({
      first_name: ['', Validators.required],
      middle_name: ['', Validators.required],
      last_name: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      age: ['', Validators.required],
    });
  }



  showDtl(){
    $('#ticketFrom').modal('show');
  }

  bookTicket() {
    var bookticket_request = {
      children:this.firstFormGroup.value.children,
      adult: this.firstFormGroup.value.adult,
      infant: this.firstFormGroup.value.infant,
      total_book_seats: this.firstFormGroup.value.total_book_seats,
      total_amount: this.firstFormGroup.value.total_amount,
      contact_name: this.firstFormGroup.value.contact_name,
      contact_email: this.firstFormGroup.value.contact_email,
      dep_city_code: this.firstFormGroup.value.dep_city_code,
      arr_city_code: this.firstFormGroup.value.arr_city_code,
      departure_date: this.firstFormGroup.value.departure_date,
      contact_number: this.firstFormGroup.value.contact_number,
      partner_user_id: this.firstFormGroup.value.partner_user_id,
      ticket_book_from: this.firstFormGroup.value.ticket_book_from,
      airline_name: this.firstFormGroup.value.airline_name,
      live_arrival_date: this.firstFormGroup.value.live_arrival_date,
      live_departure_time: this.firstFormGroup.value.live_departure_time,
      live_arrival_time: this.firstFormGroup.value.live_arrival_time,
      live_airline_code: this.firstFormGroup.value.live_airline_code,
      live_departure_terminal_no: this.firstFormGroup.value.live_departure_terminal_no,
      live_arrival_terminal_no: this.firstFormGroup.value.live_arrival_terminal_no,
      live_flight_number: this.firstFormGroup.value.live_flight_number,
      flight_traveller_details: [
        {
          first_name:this.firstFormGroup.value.first_name,
          middle_name:this.firstFormGroup.value.middle_name,
          last_name:this.firstFormGroup.value.last_name,
          gender: this.firstFormGroup.value.gender,
          dob: this.firstFormGroup.value.dob,
          age:this.firstFormGroup.value.age
        }
      ]
    }

    return false;
    this.sharedService.post('v1/add_bookticket_details', bookticket_request).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.ticketData = res.data.rows;
        if (res.data['rows'].length > 0) {
          this.data = res.data['rows'];
          this.keys = Object.keys(this.data[0]);
          this.TotalCount = res.totalRecords;
        } else {
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  rolechange(){}

}
