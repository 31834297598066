import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { MustMatch } from 'src/app/services/must-match';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Observable } from 'rxjs';
import { Sort } from '@angular/material';
declare var $: any;

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {

  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  clientuserid: any;
  clientList: any;
  changerole: any;

  sortedUser: any[];
  ccData: any;
  userData: any;

  data$: Observable<string[]>;
  names$: Observable<string[]>;

  viewAgent: boolean = true;
  editAgent: boolean = true;
  regForm: FormGroup;
  selectptype: any = 'Select Type';
  errors: any;
  success: any;
  dataSource2: any;
  submitted = false; type: any; btndisabled: boolean = false;
  searchFlagReset: boolean = false;
  bodydata: any = {}; TotalCount: string; data: any; keys: any;
  public page1: number = 1;
  start = 0; msg: any; status: any; FormId: any; flag: boolean = false;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,
    private toastr: ToastrManager
  ) {
    this.sortedUser = this.ccData;
  }

  //*********shorting Start*********** */
  sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedUser = data;
      return;
    }
    this.sortedUser = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'email': return compare(a.email, b.email, isAsc);
        case 'mobile': return compare(a.mobile, b.mobile, isAsc);
        case 'website': return compare(a.website, b.website, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  applyFilter(event: Event) {
    $("#myInput").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#myTable1 tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });
  }


  ngOnInit() {
    this.bodydata['role_id'] = '2';
    this.bodydata['page'] = '1';
    this.bodydata['page_limit'] = 10;

    this.bodydata = {
      role_id:"2",
      page_limit: 10,
      page: this.bodydata['page'] = '1',
      search: ""

    }

    this.loaddata(this.bodydata);


    this.regForm = this.fb.group({
      role_id: ['2', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.maxLength(10)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.loadType();
  }




  loaddata(bodydata) {

    this.sharedService.post('v1/users/users_list', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.ccData = res.data.rows;
        if (res.data['rows'].length > 0) {
          this.data = res.data['rows'];
          this.keys = Object.keys(this.data[0]);
          this.TotalCount = res.data['count']
          // this.TotalCount = res.data.count;
          // console.log("checkvalue============>>",this.TotalCount);

        } else {
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        }
      } else {
        this.flag = true;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  updateStatus(id, value) {

    if (value === true) {
      this.status = 1;
    } else {
      this.status = 0;
    }
    var self = this;
    var Object = {
      "id": id,
      "status": self.status
    }
    self.sharedService.post('v1/users/user_status_update', Object).subscribe((res: any) => {
      if (!res.error) {
        self.loaddata(self.bodydata);
        self.toastr.successToastr(res.message, 'Success');
      } else {
        self.toastr.errorToastr(res.error.replyMsg, 'Error');
      }
    }, error => {
      self.toastr.errorToastr("Oops! Something went wrong!");
    });
  }

  onDelete(event) {
    this.FormId = event;
    $('#delete').modal('show');
  }

  onDeleteSubmit() {
    var self = this;
    const uploadData = new FormData();
    uploadData.append('id', this.FormId);
    var Object = {
      "id": this.FormId
    }
    var url = 'v1/users/user_delete';
    self.sharedService.post(url, Object).subscribe((res: any) => {
      if (!res.error) {
        self.loaddata(this.bodydata);
        self.toastr.successToastr(res.message, 'Success');
        $('#delete').modal('hide');
      } else {
        self.toastr.errorToastr(res.error.replyMsg, 'Error');
      }
    }, error => {
      self.toastr.errorToastr("Oops! Something went wrong!");
    });
  }

  onPageChange(event) {
    this.bodydata['page'] = event;
    // console.log("event check value===========>>>",event);
    this.loaddata(this.bodydata);
  }

  onSearch() {
    this.searchFlagReset = true;
    this.bodydata['search'] = String(this.inputSearch.nativeElement.value);
    this.loaddata(this.bodydata);
  }
  onReset() {
    this.inputSearch.nativeElement.value = '';
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.loaddata(this.bodydata);
  }

  loadType() {
    this.sharedService.get('v1/role_list').subscribe((res) => {
      if (res.replyCode == 'success') {
        this.type = res.data;
      }
    });
  }
  get f() { return this.regForm.controls; }

  regUser() {
    this.submitted = true;
    if (this.regForm.invalid) {
      return;
    } else {
      const self = this;
      self.btndisabled = true;
      var bodyObject = {
        "role_id": '2',
        "name": this.regForm.value.name,
        "email": this.regForm.value.email,
        "mobile": this.regForm.value.mobile,
        "password": this.regForm.value.password,
        "device_token": "44634w8q7d7edwq6eqw46",
        "device_type": "web",
      }
      this.sharedService.post('v1/users/registration', bodyObject).subscribe((res) => {
        self.sharedService.loadingShow = false;
        if (res.replyCode == 'success') {
          self.btndisabled = false;
          $('#addagent').modal('hide');
          this.regForm.reset();
          this.msg = '';
          this.flag = false;
          this.loaddata(this.bodydata);
          this.submitted = false;
          this.toastr.successToastr(res.replyMsg, 'Success');
        } else {
          self.btndisabled = false;
          this.toastr.errorToastr(res.message, 'Error');
        }
      }, err => {
        self.btndisabled = false;
        this.toastr.errorToastr(err['error'].message, 'Error');
      });
    }
  }


  userDtl(data) {
    this.userData = data;

    $('#viewUserList').modal('show');
  }


  Change(data) {
    this.changerole = data.role_id
    this.clientuserid = data.id;
    $('#changeclient').modal('show');
  }
  rolechange(userid) {
    var request = {
      user_id: userid,
      role_id: this.changerole
    }
    this.sharedService.patch('v1/users/update_user_role', request).subscribe((res) => {
      if (res.replyCode == 'success') {
        $('#changeclient').modal('hide');
        this.loaddata(this.bodydata);
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error'].message, 'Error');
    });

  }


}
