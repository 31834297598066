import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;
    @Component({
      selector: 'app-status',
      templateUrl: './status.component.html',
      styleUrls: ['./status.component.scss'],
      providers: [NgxUiLoaderService,DatePipe]
    })
    export class StatusComponent implements OnInit {
      @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
      @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;

    enquirystatus:any;
    viewData: any;
    sortedAgent: any[];
    viewPmt: any;
    ccData: any;
    type: any;
    public Addstatus: FormGroup;
    public EditStatus: FormGroup;
    userdata: any;
    acceptApicheck: any;
    status: any;
    Data: any;
    StatusData: any;
    CommentData: any;
    UnaccpetedmyapiData: any;
    mydata: any;
    delApi: any;
    public apiRequestForm: FormGroup;
    public formEmail: FormGroup;
    submitted: boolean = false;
    pgc: boolean = false;
    disableinput: boolean = false;
    searchFlagReset: boolean = false;
    bodydata: any = {}; TotalCount: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; FormId: any; flag: boolean = false;
    acceptedApi: any;
    notAcceptedApi: any;
    venderprofile: any;
    bank_list_data: any;
    msgforbank: any;
    enquirydata:any={};


    constructor(
      private fb: FormBuilder,
      private ngxLoader: NgxUiLoaderService,
      public sharedService: SharedserviceService,
      public router: Router,
      public commonService: CommonService,
      private toastr: ToastrManager
    ) {
      this.sortedAgent = this.ccData;
      this.userdata = JSON.parse(localStorage.getItem("user"));
    }

    ngOnInit() {
      this.bodydata = {
        page_limit: 10,
        page_no: 1,
        status: 1,
      }

      this.ngxLoader.start();
      this.statuslist();
      this.statuslist();
      this.ngxLoader.stop();

      this.Addstatus = this.fb.group({
        name: ['',Validators.required],
        status_colour:['',Validators.required]
      });
      this.EditStatus = this.fb.group({
        id: ['',Validators.required],
        name: ['',Validators.required],
        status: ['',Validators.required],
        status_colour:['',Validators.required]
      });
    }

    get enq() { return this.Addstatus.controls; }
    get enqedit() { return this.EditStatus.controls; }

    closeENQ() {
      $('#Enq_us').modal('hide');
    }
    ENQ() {
          $('#Enq_us').modal('show');
    }

    Add(){
      this.submitted = true;
      if(this.Addstatus.invalid){
        return
      }
      var requestData =this.Addstatus.value;
      this.sharedService.post('v1/enquiry/add_status',requestData).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.Addstatus.reset();
          this.statuslist();
          $('#Enq_us').modal('hide');
          this.toastr.successToastr(res.replyMsg, 'Success');
        } else {
          this.toastr.errorToastr(res.message, 'Error');
        }
      },err=>{  this.toastr.errorToastr(err['error'].replyMsg, 'Error');  });
    }

    closeEdit() {
      $('#edit_Enq_us').modal('hide');
    }
    EditStatus1(data) {
      this.EditStatus.patchValue({
        id:data.id,
        name:data.name,
      })
      $('#edit_Enq_us').modal('show');
    }

    Edit(){
      this.submitted = true;
      var requestData ={
        id:this.EditStatus.value.id,
        name:this.EditStatus.value.name,
        status:1,
        status_colour:this.EditStatus.value.status_colour
      }
      this.sharedService.post('v1/enquiry/edit_status',requestData).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.EditStatus.reset();
          this.statuslist();
          $('#edit_Enq_us').modal('hide');
          this.toastr.successToastr(res.replyMsg, 'Success');
        } else {
          this.toastr.errorToastr(res.message, 'Error');
        }
      });
    }

    statuslist() {
      this.sharedService.get('v1/enquiry/list_status').subscribe((res) => {
        if (res.replyCode == 'success') {
          this.StatusData = res.data;
          this.TotalCount = res.count;
        }
      });
    }

    updatestatus(list){
      this.enquirydata=list;
      this.enquirystatus=list.status;
      $('#edit_s').modal('show');
    }

    onSearch() {
      this.searchFlagReset = true;
      this.bodydata['search'] = String(this.inputSearch.nativeElement.value);
      this.statuslist();
    }
    onReset() {
      this.inputSearch.nativeElement.value = '';
      this.searchFlagReset = false;
      this.bodydata['search'] = '';
      this.statuslist();
    }

    Changetype(colour){
      // this.AddCommentForm.patchValue({
      //   type:id == 1?'note':'comment'
      // })
      var statusColour = colour
     if(colour == 1){
        statusColour = 'Red'
     } else if(colour == 2){
      statusColour = 'Green'
     }else if(colour == 3){
      statusColour = 'Olive'
     }else if(colour == 4){
      statusColour = 'Brown'
     }else if(colour == 5){
      statusColour = 'Grey'
     }else if(colour == 6){
      statusColour = 'Blue'
     }else if(colour == 7){
      statusColour = 'Azure'
     }else if(colour == 8){
      statusColour = 'Black'
     }else if(colour == 9){
      statusColour = 'Purple'
     }else if(colour == 10){
      statusColour = 'Orange'
     }
     this.Addstatus.patchValue({
      status_colour: statusColour
     });
     this.EditStatus.patchValue({
      status_colour: statusColour
     })
    //  console.log("Color:::",statusColour);



    }

    deleteStatus(data){
      this.EditStatus.patchValue({
        id:data.id,
        name:data.name,
      })
      $('#delete_status').modal('show');
    }
    deleteData(){
      var requestData ={
        id:this.EditStatus.value.id,
        name:this.EditStatus.value.name,
        status:0,
        status_colour:"Red"
      }
      // console.log("request::::",requestData);

      this.sharedService.post('v1/enquiry/edit_status',requestData).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.EditStatus.reset();
          this.statuslist();
          $('#delete_status').modal('hide');
          this.toastr.successToastr(res.replyMsg, 'Success');
        } else {
          this.toastr.errorToastr(res.message, 'Error');
        }
      });
    }

    onPageChange(event) {
      this.bodydata['page'] = event;
      this.statuslist();
    }
  }


