import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManagedataService {

  isAirlineArray = new BehaviorSubject<any>([]);
  GetAirlineArray() { return this.isAirlineArray.asObservable() }
  SetAirlineArray(value: any) { this.isAirlineArray.next(value) }

  isCityArray = new BehaviorSubject<any>([]);
  GetCityArray() { return this.isCityArray.asObservable() }
  SetCityArray(value: any) { this.isCityArray.next(value) }

  isLoggedInUserDetails = new BehaviorSubject<any>({});
  GetLoggedInUserDetails() { return this.isLoggedInUserDetails.asObservable() }
  SetLoggedInUserDetails(value: any) { this.isLoggedInUserDetails.next(value) }

  isLoggedInUserImage = new BehaviorSubject<string>('');
  GetLoggedInUserImage() { return this.isLoggedInUserImage.asObservable() }
  SetLoggedInUserImage(value: any) { this.isLoggedInUserImage.next(value) }

  isLoggedInUserImageUrl = new BehaviorSubject<string>('');
  GetLoggedInUserImageUrl() { return this.isLoggedInUserImageUrl.asObservable() }
  SetLoggedInUserImageUrl(value: any) { this.isLoggedInUserImageUrl.next(value) }

  isLoggedInUserStatus = new BehaviorSubject<boolean>(false);
  GetLoggedInUserStatus() { return this.isLoggedInUserStatus.asObservable() }
  SetLoggedInUserStatus(value: boolean) { this.isLoggedInUserStatus.next(value) }


  isInventoryViewType = new BehaviorSubject<string>('');
  GetInventoryViewType() { return this.isInventoryViewType.asObservable() }
  SetInventoryViewType(value: any) { this.isInventoryViewType.next(value) }

  isInventoryCalenderData = new BehaviorSubject<any>({});
  GetInventoryCalenderData() { return this.isInventoryCalenderData.asObservable() }
  SetInventoryCalenderData(value: any) { this.isInventoryCalenderData.next(value) }

  isMasterApiChanges = new BehaviorSubject<boolean>(false);
  GetMasterApiChanges() { return this.isMasterApiChanges.asObservable() }
  SetMasterApiChanges(value: any) { this.isMasterApiChanges.next(value) }

  isHotelTravellersInfo = new BehaviorSubject<any>({numberOfAdults:0,numberOfChild:0,start_date:'',end_date:''});
  GetHotelTravellersInfo() { return this.isHotelTravellersInfo.asObservable() }
  SetHotelTravellersInfo(value: any) { this.isHotelTravellersInfo.next(value) }


  isTripValue = new BehaviorSubject<string>("");  // Search Flights

  isDepartureArray = new BehaviorSubject<any>([]);
  isRegularCityArray = new BehaviorSubject<any>([]);
  isArrivalArray = new BehaviorSubject<any>([]);
  isDepartureDateArray = new BehaviorSubject<any>([]);
  isDepartureChangesDate = new BehaviorSubject<any>([]);
  isReturnDateArray = new BehaviorSubject<any>([]);
  isSelectedDepartureData = new BehaviorSubject<any>({});
  isSelectedArrivalData = new BehaviorSubject<any>({});
  isSelectedTravelersData = new BehaviorSubject<any>({});
  isFlightImagePath = new BehaviorSubject<string>("");
  GetTripValue() { return this.isTripValue.asObservable() }
  SetTripValue(value: any) { this.isTripValue.next(value) }

  GetDepartureArray() { return this.isDepartureArray.asObservable() }
  SetDepartureArray(value: any) { this.isDepartureArray.next(value) }

  GetRegularCityArray() { return this.isRegularCityArray.asObservable() }
  SetRegularCityArray(value: any) { this.isRegularCityArray.next(value) }

  GetArrivalArray() { return this.isArrivalArray.asObservable() }
  SetArrivalArray(value: any) { this.isArrivalArray.next(value) }

  GetDepartureChangeDateArray() { return this.isDepartureChangesDate.asObservable() }
  SetDepartureChangeDateArray(value: any) { this.isDepartureChangesDate.next(value) }

  GetDepartureDateArray() { return this.isDepartureDateArray.asObservable() }
  SetDepartureDateArray(value: any) { this.isDepartureDateArray.next(value) }

  GetReturnDateArray() { return this.isReturnDateArray.asObservable() }
  SetReturnDateArray(value: any) { this.isReturnDateArray.next(value) }

  GetSelectedDepartureData() { return this.isSelectedDepartureData.asObservable() }
  SetSelectedDepartureData(value: any) { this.isSelectedDepartureData.next(value) }

  GetSelectedArrivalData() { return this.isSelectedArrivalData.asObservable() }
  SetSelectedArrivalData(value: any) { this.isSelectedArrivalData.next(value) }

  GetSelectedTravelersData() { return this.isSelectedTravelersData.asObservable() }
  SetSelectedTravelersData(value: any) { this.isSelectedTravelersData.next(value) }

  isMasterTripOptions = new BehaviorSubject<number>(1);
  GetMasterTripOptions() { return this.isMasterTripOptions.asObservable() }
  SetMasterTripOptions(value: any) { this.isMasterTripOptions.next(value) }

  isBookingViewItaly = new BehaviorSubject<any>([]);
  GetBookingViewItaly() { return this.isBookingViewItaly.asObservable() }
  SetBookingViewItaly(value: any) { this.isBookingViewItaly.next(value) }

  isAccountCompleteSaleItaly = new BehaviorSubject<any>([]);
  GetAccountCompleteSaleItaly() { return this.isAccountCompleteSaleItaly.asObservable() }
  SetAccountCompleteSaleItaly(value: any) { this.isAccountCompleteSaleItaly.next(value) }

  isSearchFlightResponse = new BehaviorSubject<any>({});
  GetSearchFlightResponse() { return this.isSearchFlightResponse.asObservable() }
  SetSearchFlightResponse(value: any) { this.isSearchFlightResponse.next(value) }

  isSearchFlightOnewayResponse = new BehaviorSubject<any>({});
  GetSearchFlightOnewayResponse() { return this.isSearchFlightOnewayResponse.asObservable() }
  SetSearchFlightOnewayResponse(value: any) { this.isSearchFlightOnewayResponse.next(value) }

  isSearchFlightReturnResponse = new BehaviorSubject<any>({});
  GetSearchFlightReturnResponse() { return this.isSearchFlightReturnResponse.asObservable() }
  SetSearchFlightReturnResponse(value: any) { this.isSearchFlightReturnResponse.next(value) }

  isFareRuleList = new BehaviorSubject<any>([]);
  GetFareRuleList() { return this.isFareRuleList.asObservable() }
  SetFareRuleList(value: any) { this.isFareRuleList.next(value) }

  isFormReset = new BehaviorSubject<boolean>(false);
  GetFormReset() { return this.isFormReset.asObservable() }
  SetFormReset(value: any) { this.isFormReset.next(value) }

  GetFlightImagePath() { return this.isFlightImagePath.asObservable() }
  SetFlightImagePath(value: any) { this.isFlightImagePath.next(value) }


  isDepartureSelectBox = new BehaviorSubject<boolean>(false);
  GetDepartureSelectBox() { return this.isDepartureSelectBox.asObservable() }
  SetDepartureSelectBox(value: any) { this.isDepartureSelectBox.next(value) }

  isArrivalSelectBox = new BehaviorSubject<boolean>(false);
  GetArrivalSelectBox() { return this.isArrivalSelectBox.asObservable() }
  SetArrivalSelectBox(value: any) { this.isArrivalSelectBox.next(value) }

  isDateNextPreviousOption = new BehaviorSubject<boolean>(false);
  GetDateNextPreviousOption() { return this.isDateNextPreviousOption.asObservable() }
  SetDateNextPreviousOption(value: any) { this.isDateNextPreviousOption.next(value) }

  isAvailableToDate = new BehaviorSubject<any>([]);
  GetAvailableToDate() { return this.isAvailableToDate.asObservable() }
  SetAvailableToDate(value: any) { this.isAvailableToDate.next(value) }

  isAvailableCurrentDate = new BehaviorSubject<any>("");
  GetAvailableCurrentDate() { return this.isAvailableCurrentDate.asObservable() }
  SetAvailableCurrentDate(value: any) { this.isAvailableCurrentDate.next(value) }

  isUserToken = new BehaviorSubject<string>('');
  GetUserToken() { return this.isUserToken.asObservable() }
  SetUserToken(value: any) { this.isUserToken.next(value) }

  isUserActivityDetails = new BehaviorSubject<any>([]);
  GetUserActivityDetails() { return this.isUserActivityDetails.asObservable() }
  SetUserActivityDetails(value: any) { this.isUserActivityDetails.next(value) }

  isGGNSetDataList = new BehaviorSubject<any>([]);
  GetGGNlistDataResponse() { return this.isGGNSetDataList.asObservable() }
  SetGGNlistDataResponse(value: any) { this.isGGNSetDataList.next(value) }

  isGgnActiveUserList = new BehaviorSubject<any>([]);
  GetggnActiveUserData() { return this.isGgnActiveUserList.asObservable() }
  SetggnActiveUserData(value: any) { this.isGgnActiveUserList.next(value) }

  isggnFormData = new BehaviorSubject<any>([]);
  GetggnFormDataValue() { return this.isggnFormData.asObservable() }
  SetggnFormDataValue(value: any) { this.isggnFormData.next(value) }

  isAddUserListData = new BehaviorSubject<any>([]);
  GetaddUserListResponse() { return this.isAddUserListData.asObservable() }
  SetaddUserListResponse(value: any) { this.isAddUserListData.next(value) }

  isDirectBookingResponse = new BehaviorSubject<any>([]);
  GetonDriectBookingDataResponse() { return this.isDirectBookingResponse.asObservable() }
  SetonDriectBookingDataResponse(value: any) { this.isDirectBookingResponse.next(value) }

  isSearchFlightDirectBooking = new BehaviorSubject<any>([]);
  GetSearchFlightDirectBooking() { return this.isSearchFlightDirectBooking.asObservable() }
  SetSearchFlightDirectBooking(value: any) { this.isSearchFlightDirectBooking.next(value) }

  isUserAgencyData = new BehaviorSubject<any>([]);
  GetUserAgencyData() { return this.isUserAgencyData.asObservable() }
  SetUserAgencyData(value: any) { this.isUserAgencyData.next(value) }

  isStateDataResponse = new BehaviorSubject<any>([]);
  GetStateListResponse() { return this.isStateDataResponse.asObservable() }
  SetStateListResponse(value: any) { this.isStateDataResponse.next(value) }

  isMarkupListResponse = new BehaviorSubject<any>([]);
  GetMarkupListResponse() { return this.isMarkupListResponse.asObservable() }
  SetMarkupListResponse(value: any) { this.isMarkupListResponse.next(value) }

  isFitUserList = new BehaviorSubject<any>([]);
  GetFitMarginUserList() { return this.isFitUserList.asObservable() }
  SetFitMarginUserList(value: any) { this.isFitUserList.next(value) }

  isGroupListResponse = new BehaviorSubject<any>([]);
  GetGroupListData() { return this.isGroupListResponse.asObservable() }
  SetGroupListData(value: any) { this.isGroupListResponse.next(value) }

  isFitMarginList = new BehaviorSubject<any>([]);
  GetFitMarginList() { return this.isFitMarginList.asObservable() }
  SetFitMarginList(value: any) { this.isFitMarginList.next(value) }

  isNameListResponse = new BehaviorSubject<any>([]);
  GetNameListData() { return this.isNameListResponse.asObservable() }
  SetNameListData(value: any) { this.isNameListResponse.next(value) }

  isApiPartnerId = new BehaviorSubject<any>([]);
  GetApiPartnerValue() { return this.isApiPartnerId.asObservable() }
  SetApiPartnerValue(value: any) { this.isApiPartnerId.next(value) }

  isGetDepartmentValueId = new BehaviorSubject<any>([]);
  GetDepartmentValue() { return this.isGetDepartmentValueId.asObservable() }
  SetDepartmentValue(value: any) { this.isGetDepartmentValueId.next(value) }

  isGetFitFareRuleListData = new BehaviorSubject<any>([]);
  GetFitFareRuleList() { return this.isGetFitFareRuleListData.asObservable() }
  SetFitFareRuleList(value: any) { this.isGetFitFareRuleListData.next(value) }

  isAdertisement = new BehaviorSubject<any>([]);
  GetAdertisement() { return this.isAdertisement.asObservable() }
  SetAdertisement(value: any) { this.isAdertisement.next(value) }

  isAllStaffInventory = new BehaviorSubject<any>([]);
  GetAllStaffInventoryData() { return this.isAllStaffInventory.asObservable() }
  SetAllStaffInventoryData(value: any) { this.isAllStaffInventory.next(value) }

  isStaffAllSeller = new BehaviorSubject<any>([]);
  GetStaffAllsellerData() { return this.isStaffAllSeller.asObservable() }
  SetStaffAllsellerData(value: any) { this.isStaffAllSeller.next(value) }

  isSelectedSellerData = new BehaviorSubject<any>({});
  GetSelectedSellerData() { return this.isSelectedSellerData.asObservable() }
  SetSelectedSellerData(value: any) { this.isSelectedSellerData.next(value) }

  isSelectedBuyerData = new BehaviorSubject<any>({});
  GetSelectedBuyerData() { return this.isSelectedBuyerData.asObservable() }
  SetSelectedBuyerData(value: any) { this.isSelectedBuyerData.next(value) }

  isStaffAllBuyer = new BehaviorSubject<any>([]);
  GetStaffAllBuyerData() { return this.isStaffAllBuyer.asObservable() }
  SetStaffAllBuyerData(value: any) { this.isStaffAllBuyer.next(value) }

  isStaticData = new BehaviorSubject<any>([]);
  GetStaticData() { return this.isStaticData.asObservable() }
  SetStaticData(value: any) { this.isStaticData.next(value) }

  isSelfSectorData = new BehaviorSubject<any>([]);
  GetSelfSectorData() { return this.isSelfSectorData.asObservable() }
  SetSelfSectorData(value: any) { this.isSelfSectorData.next(value) }

  isPartnerSectorData = new BehaviorSubject<any>([]);
  GetPartnerSectorData() { return this.isPartnerSectorData.asObservable() }
  SetPartnerSectorData(value: any) { this.isPartnerSectorData.next(value) }

  isSupplierSectorData = new BehaviorSubject<any>([]);
  GetSupplierSectorData() { return this.isSupplierSectorData.asObservable() }
  SetSupplierSectorData(value: any) { this.isSupplierSectorData.next(value) }

  isAvilSectorData = new BehaviorSubject<any>([]);
  GetAvilSectorData() { return this.isAvilSectorData.asObservable() }
  SetAvilSectorData(value: any) { this.isAvilSectorData.next(value) }


  isUnsoldSectorData = new BehaviorSubject<any>([]);
  GetUnsoldSectorData() { return this.isUnsoldSectorData.asObservable() }
  SetUnsoldSectorData(value: any) { this.isUnsoldSectorData.next(value) }

  isLatestUpdate = new BehaviorSubject<any>({});
  GetLatestUpdates() { return this.isLatestUpdate.asObservable() }
  SetLatestUpdates(value: any) { this.isLatestUpdate.next(value) }

  isUpcommingList = new BehaviorSubject<any>({});
  GetUpcommingData() { return this.isUpcommingList.asObservable() }
  SetUpcommingData(value: any) { this.isUpcommingList.next(value) }

  isMarkupCompareList = new BehaviorSubject<any>({});
  GetMarkupCompareList() { return this.isMarkupCompareList.asObservable() }
  SetMarkupCompareList(value: any) { this.isMarkupCompareList.next(value) }

  isGetFitFareNameListData = new BehaviorSubject<any>([]);
  GetFitFareFareNameList() { return this.isGetFitFareNameListData.asObservable() }
  SetFitFareFareNameList(value: any) { this.isGetFitFareNameListData.next(value) }

}

