import * as RedirectionPath from '../../common/redirect';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmountPatternValidator } from 'src/app/services/amount-pattern-validator';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Sort } from '@angular/material/sort';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-myapi-balance',
  templateUrl: './myapi-balance.component.html',
  styleUrls: ['./myapi-balance.component.scss'],
  providers: [DatePipe, NgxUiLoaderService]
})

export class MyapiBalanceComponent implements OnInit {
  public environment = environment;
  ref_id: any='';
  fromdate: any = "";  todate: any = "";  fromdepdate: any = ""; todepdate:any = ""; searchreportbool:boolean=false; airlinecode: any = ""; paymentStatus:any=""; transactionStatus:any="";  transaction_type:any='';  sort_narration: any = "desc";  sort_amount: any = "desc"; totalpage: any; pid:any; paymant_status:any;sort_account_type: any = "desc";  crediBalance:any='';
  currentdate : any;  last_Select_Date :any;  diffTime:any;  date: any; public addaccountPartner : FormGroup;
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];   isAuto:boolean=false;
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  ccData: any;  newData: any;  compDtl2: any;  creditCount:any;  compData: any;  compDtl: any;  sortedList: any[];  retrn: any;
  old_credit_limit: any;  detail_data: any;  submitted: boolean = false;  searchFlagReset: boolean = false;   bodydata: any = {};
  bodyRequestData: any = {};  requestdata:any = {};  TotalCount: any; data: any; data1: any; keys: any;
  public page: number = 1; start = 0; msg: any; status: any; FormId: any; flag: boolean = false;  accountData: any;  TotalCount1:any;
  page1: number = 1;  account: any;  // todaydate: any = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
  todaydate:any = new Date();  onSelectDate:any;  public addaccountValue: FormGroup; editNarration:FormGroup; public addreversal: FormGroup;  element: any = {};
  userdata: any;  acountPartner: any;  old_available_balance: any;  ticket = "Ticket Sold : ";  p: number = 1;  creditVal: any=3;

  reference_id: any='';
  add_b_sel_op:any=[
    // { title: "Transaction Type", value: 3 },
    // { title: "Give Credit", value: 0 },
    // { title: "Add Balance", value: 1 },
    // { title: "Refund", value: 'refund' },
    // { title: "Other Charges", value: 'other_charges' },
    { title: "Paid", value: 'paid' },
    { title: "Balance", value: 'balance' },
    { title: "Credit", value: 'credit' },
    { title: "Credit Note", value: 'credit_note' },
    { title: "Refund", value: 'refund' },
    { title: "SSR ", value: 'ssr' },
    { title: "Add Pax", value: 'add_pax' },
    { title: "Other Charges", value: 'other_charges' },
  ]
  booking_id: any;
  reversData: any;  myReverseAmount: number;
  narrationId: any;
  narrationData: any;
  constructor(
    private fb: FormBuilder,  public sharedService: SharedserviceService,  public router: Router,  private datePipe: DatePipe,
    private toastr: ToastrManager,  private activatedRoute: ActivatedRoute,  public dataservice: CommonService,private ngxLoader: NgxUiLoaderService,
  ) {
    this.userdata = JSON.parse(localStorage.getItem("user"));  this.sortedList = this.ccData;  this.date=new Date();
    if (sessionStorage.getItem("account_detail_data")) {
      this.detail_data = JSON.parse(sessionStorage.getItem("account_detail_data"))
    }
  }

  ngOnInit() {
    this.detail_data;
    this.bodyRequestData['page1'] = 1;
    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.role_id == 1) {
      this.addaccountPartner = this.fb.group({
        share_inventorie_id:['', Validators.required],
        share_to_user_id:['', Validators.required],
        amount:['', Validators.required],
        narration:['', Validators.required],  // crediBalance:['', Validators.required]

      })
      this.editNarration = this.fb.group({
        id: ['', Validators.required],
        narration: ['', Validators.required],
      })
      this.addaccountValue = this.fb.group({
        share_inventorie_id: ['', Validators.required],  share_to_user_id: ['', Validators.required],
        amount: ['', Validators.required],  narration: ['', Validators.required],
      })
      this.addreversal = this.fb.group({
        share_inventorie_id: [this.detail_data.share_inventorie_id, Validators.required],  share_to_user_id: [this.detail_data.share_to_user_id, Validators.required],
        credit_reversal_for: ['', Validators.required],  narration: ['', Validators.required],
      })
      this.element = {
        share_inventorie_id: this.detail_data.share_inventorie_id,  share_to_user_id: this.detail_data.share_to_user_id,
        share_by_user_id: this.detail_data.share_by_user_id,  page_limit: "10",
        page_no: "1",  search_value: "",  order_key: "id",  order_value: "",  account_type: "",
        // new field
        transaction_type: this.transaction_type,airline_code:"",
        from_book_date:"",  to_book_date:"",  from_dep_date:"",  to_dep_date:"", payment_status:"",page:""
      }
      this.bodyRequestData = {
        share_inventorie_id : this.detail_data.share_inventorie_id,  share_to_user_id : this.detail_data.share_to_user_id,
        share_by_user_id : this.detail_data.share_by_user_id,
        page_limit : "10",  page_no : 1,  search_value : "",  order_key : "",  order_value : "",  account_type : "",
        // new field
        transaction_type: this.transaction_type
      }
      this.bodydata['page_no'] = 1;
      this.accountDtl(this.element);
      // console.log("data::::",this.accountDtl(this.element))



    // }

  }

  convertToTitleCase(str: string): string {
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize first letter of each word
  }

  numberOnly(event): boolean {
    //
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedList = data;
      return;
    }
    this.sortedList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'narration': return compare(a.narration, b.narration, isAsc);
        case 'amount': return compare(a.amount, b.amount, isAsc);
        case 'account_type': return compare(a.account_type, b.account_type, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  accountDtl(element) {
    const self = this;
    this.ngxLoader.start();
    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      // this.sharedService.post('v1/account/company_account_details', element).subscribe((res: any) => {
      this.sharedService.post('v1/account/company_account_details_new', element).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.ccData = res.data;
          this.TotalCount = res.totalRecords;
          this.ngxLoader.stop();
          if ((res.data).length > 0) {
            this.data = res.data;
            this.retrn = res.data.return_flight == 0 ? "Return" : "Oneway";
            this.compDtl2 = res.company_details;
            this.keys = Object.keys(this.data[0]);
            this.creditCount=res.credit_count;
          } else {
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          }
        } else {
          this.ngxLoader.stop();
          self.toastr.errorToastr("Result Not Found", "Error")
        }
      }, err => {
        this.ngxLoader.stop();
        this.toastr.errorToastr(err.error.replyMsg, "Error");
      });
    // }
  }

  get ab() { return this.addaccountValue.controls }
  get rb() { return this.addreversal.controls }
  get narration() { return this.editNarration.controls }

  onOptionsSelected(value:string){
  }

  myFunctionDate(val){
    var newdate=new Date();
    var diff = val.getTime() - newdate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    this.diffTime= days+" Days, "+ hours+" Hours,"+minutes+" Minute, "+seconds+" Seconds";
    this.onSelectDate = val ;
    this.onSelectDate.toDateString() ;
  }

  accountShow() {
    var data = JSON.parse(sessionStorage.getItem("account_detail_data"))
    this.old_credit_limit = data.amount
    this.old_available_balance = data.amount
    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.addaccountPartner.patchValue({
        share_inventorie_id: data.share_inventorie_id.toString(),
        share_to_user_id: data.share_to_user_id.toString(),
        share_by_user_id: this.detail_data.share_by_user_id.toString(),
      })
    // }
    $('#addBalance').modal('show');
  }

  clBalance(){  $('#addBalance').modal('hide');      $('#reverseList').modal('hide'); this.submitted = false;  }

  // accountShow(data){
  //   this.FormId = data;
  //   this.old_credit_limit=data.credit_limit;
  //   this.old_available_balance=data.available_balance;

  //   if(this.userdata.role_id == 1){
  //     this.addaccountPartner.patchValue({
  //       share_inventorie_id:data.share_inventorie_id,
  //       share_to_user_id:data.share_to_user_id,
  //       share_by_user_id:data.share_by_user_id,
  //    })
  //   } else if(this.userdata.role_id == 3 || this.userdata.role_id == 5){
  //     this.addaccountPartner.patchValue({
  //       share_inventorie_id:data.share_inventorie_id,
  //       share_to_user_id:data.share_to_user_id,
  //       share_by_user_id:data.share_by_user_id,
  //     })
  //   }

  //   $('#addBalance').modal('show');

  // }

  isAutoReverse(event){
    var myvalisAuto = event;
    // this.isAuto = ! this.isAuto;
    if (event.checked == true) {
      this.isAuto = true;
      // localStorage.setItem('ContactShow','true')
    } else {
      this.isAuto = false;
      // localStorage.setItem('ContactShow','false')
    }
  }
  onChangeAccountType(type:any){
    console.log("this.addaccountPartner",type,this.addaccountPartner)
    if(type == 0){
      this.addaccountPartner.get('amount').setValidators([Validators.required,AmountPatternValidator(/^[0-9]*(\.[0-9]{0,8})?$/)]);
      this.addaccountPartner.controls['amount'].updateValueAndValidity();
    }else{
      this.addaccountPartner.get('amount').clearValidators();
      this.addaccountPartner.controls['amount'].updateValueAndValidity();
      this.addaccountPartner.get('amount').setValidators([Validators.required]);
      this.addaccountPartner.controls['amount'].updateValueAndValidity();
    }
  }

  onSearchChange(searchValue: string): void {
    this.reference_id = searchValue;
  }

  addAccount(){
    var self = this;
    self.submitted = true;
      if (self.addaccountPartner.invalid) {
        return;
      // }else if(this.submitted = true && (this.creditVal == 'refund'|| this.creditVal == 'other_charges') && this.ref_id == ''){
      //   return;
      }else if((this.creditVal=='credit_note' && this.reference_id == '')||this.creditVal=='ssr' && this.reference_id == ''||this.creditVal=='add_pax' && this.reference_id == ''){
        return;
      } else {
          if(this.submitted = true  && this.creditVal == 3){
            return;
          }else{
            // if(this.creditVal == 0){
            //   var acc_type = 'credit';
            // }else if(this.creditVal == 1){
            //   var acc_type = 'balance';
            // }else if(this.creditVal == 'refund'){
            //   var acc_type = 'refund'
            // }else {
            //   var acc_type = 'other_charges'
            // }

            var data= self.addaccountPartner.value;
            var request_data:any={
              share_inventorie_id: Number(data.share_inventorie_id),
              share_to_user_id: Number(data.share_to_user_id),
              amount:data.amount ,
              narration: data.narration,
              reference_id: this.reference_id,
              account_type: this.creditVal,
              // account_type: acc_type,  // 'balance': to add balance, 'credit': to add credit
              // account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
              // credit_reversal_date:this.crediBalance ==''?'':this.datePipe.transform(this.crediBalance, 'yyyy-MM-dd'),   // if is_auto_reverse is false, credit reversal date is not mandatory
              // is_auto_reverse:this.isAuto,
              // reference_id:this.ref_id
            }
            this.ngxLoader.start();
           self.sharedService.postRequest('v1/account/add_balance_new',request_data).subscribe((res: any) => {
             if (!res.error) {
               if(res.replyCode == 'success') {
                this.diffTime=''
                this.creditVal=3;
                this.crediBalance ='';
                 this.accountData = res.data;
                 self.submitted = false;
                 this.accountDtl(this.element);
                 this.ngxLoader.stop();
                 $('#addBalance').modal('hide');
                 self.addaccountPartner.reset();
                 self.submitted = false;this.isAuto=false;
                 self.toastr.successToastr(res.replyMsg, 'Success');
               }else {
                this.ngxLoader.stop();
                 self.submitted = false; self.addaccountPartner.reset();
                 self.toastr.errorToastr(res.replyMsg, 'Error');
                 this.diffTime=''
                 this.creditVal=3; this.isAuto=false;
                 this.crediBalance ='';
                  $('#addBalance').modal('hide');
               }
             }else {
              this.ngxLoader.stop();
              self.submitted = false; self.addaccountPartner.reset();
              self.toastr.errorToastr(res.error.replyMsg, 'Error');
              this.diffTime=''
              this.creditVal=3;
              this.crediBalance =''; this.isAuto=false;
               $('#addBalance').modal('hide');
             }
             },error => {
               self.submitted =false; $('#addBalance').modal('hide'); this.ngxLoader.stop();
               this.diffTime='';this.isAuto=false;  this.creditVal=3; this.crediBalance ='';
               self.addaccountPartner.reset(); self.toastr.errorToastr(error.error.replyMsg);
             });
          }
      }
  }


  ReverseCredit(data){
    var self = this;
    this.old_credit_limit = data.amount
    this.old_available_balance = data.available_balance
    var request_data={
      credit_reversal_for:(data.id),
      narration:"Credit Reversed",
      share_inventorie_id: this.detail_data.share_inventorie_id,
      share_to_user_id: this.detail_data.share_to_user_id,
      // share_by_user_id: this.detail_data.share_by_user_id,
    }
    self.sharedService.post('v1/account/reverse_credit_new', request_data).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        $('#reverseList').modal('hide');
        self.toastr.successToastr(res.replyMsg, 'Success');
        //
        // this.creditRevrsList();
        // $('#reverseList').modal('hide');
        this.creditRevrsList();
        this.accountDtl(this.element);
      }
    }, err => {
      self.submitted = false;
      // this.creditRevrsList();
      self.toastr.errorToastr(err.error.replyMsg, 'Error');
      // $('#reverseList').modal('hide');
    });
  }

  accountShow1(data) {
    this.myReverseAmount = (data.available_balance-data.actual_amount)

    this.old_credit_limit = data.amount
    this.old_available_balance = data.available_balance
      this.addreversal.patchValue({
        share_inventorie_id:this.detail_data.share_inventorie_id,
        share_to_user_id:this.detail_data.share_to_user_id,
        credit_reversal_for:(data.id),
        narration:"Credit Reversed",
      })
      $('#addReversal').modal('show');
  }

  deleteLastAccTxn(data:any){
    console.log(data,"------319-*-*-*-");
  }

  clAccount() {
    $('#addBalance').modal('hide');
    $('#reverseList').modal('hide');
    this.submitted = false;
  }

  addReversal() {
    var self = this;
    self.submitted = true;
    if (self.addreversal.invalid) {
      return;
    } else {
        var request_data = self.addreversal.value;
        var addBalanceData = 'v1/account/reverse_credit_new'
        // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
        //   var addBalanceData = 'v1/account/reverse_credit_new'
        // }
        self.sharedService.post(addBalanceData, request_data).subscribe((res: any) => {
            if (res.replyCode == 'success') {
              this.accountData = res.data;
              self.submitted = false;
              $('#addReversal').modal('hide');
              self.addreversal.reset();
              self.toastr.successToastr(res.replyMsg, 'Success');
              this.accountDtl(this.element);
            }
        }, err => {
          self.submitted = false;
          $('#addReversal').modal('hide');
          self.addreversal.reset();
          self.toastr.errorToastr(err.error.replyMsg, 'Error');
        });
    }
  }

  // addAccount() {
  //   var self = this;
  //   self.submitted = true;
  //   if (self.addaccountValue.invalid) {
  //     return;
  //   } else {
  //     if(this.submitted = true && this.crediBalance == '' && this.creditVal == 3){
  //       return;
  //     }else{
  //       var data = self.addaccountValue.value;
  //       var request_data:any={
  //         share_inventorie_id: data.share_inventorie_id,  share_to_user_id: data.share_to_user_id,  amount:data.amount ,  narration: data.narration,
  //         account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
  //         credit_reversal_date:this.crediBalance ==''?'':this.datePipe.transform(this.crediBalance, 'yyyy-MM-dd')   /* cannot be null if account_type = 'credit' */,
  //         // is_auto_reverse:this.creditVal == 0?true: false
  //       }
  //       if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
  //         // var addBalanceData = 'v1/account/add_balance_new'
  //         var addBalanceData = 'v1/account/add_balance'
  //       }
  //       self.sharedService.postRequest(addBalanceData, request_data).subscribe((res: any) => {
  //         if (!res.error) {
  //           if (res.replyCode == 'success') {
  //             this.crediBalance="";  this.creditVal=3;  this.accountData = res.data;  self.submitted = false;  $('#addBalance').modal('hide');
  //             var cl = Number(this.detail_data.amount) + Number(request_data.amount)
  //             if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
  //               var data2 = {
  //                 share_inventorie_id: this.detail_data.share_inventorie_id.toString(),amount: cl.toString(),
  //                 share_to_user_id: this.detail_data.share_to_user_id.toString(),  share_by_user_id: this.detail_data.share_by_user_id.toString()
  //               }
  //               sessionStorage.setItem("account_detail_data", JSON.stringify(data2));  this.accountDtl(this.element)
  //             }
  //             self.addaccountValue.reset();  $('#addBalance').modal('hide');  self.submitted = false;  self.toastr.successToastr(res.replyMsg, 'Success');
  //           } else {
  //             self.submitted = false;
  //             this.crediBalance="";
  //             this.creditVal="";
  //             self.addaccountValue.reset();
  //             $('#addBalance').modal('hide');
  //             self.toastr.errorToastr(res.replyMsg, 'Error');
  //           }
  //         } else {
  //           self.submitted = false;
  //           this.crediBalance="";
  //             this.creditVal="";
  //           self.addaccountValue.reset();
  //           $('#addBalance').modal('hide');
  //           self.toastr.errorToastr(res.error.replyMsg, 'Error');
  //         }
  //       }, error => {
  //         self.submitted = false;
  //         this.crediBalance="";
  //             this.creditVal="";
  //         self.addaccountValue.reset();
  //         $('#addBalance').modal('hide');
  //         self.toastr.errorToastr(error.error.replyMsg);
  //       });
  //     }
  //   }
  // }
  SearchAirlineCode(){
    if(this.airlinecode.length == 2){
      this.searchreport()
    }
  }

  onChangeStatus(event: any) {
    this.transactionStatus = event;
    console.log("transactionStatus=======>>>>",this.transactionStatus);

    this.onSearch()
  }

  onChangePaymentStatus(event: any) {
      this.paymentStatus = Number(event);
      this.onSearch()
  }

  searchreport(){
    if(this.fromdate != '' && this.todate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.fromdepdate != '' && this.todepdate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.airlinecode != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else{
      this.searchreportbool=true;
    }
  }

  onSearch() {
    var element;
    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }
    if (this.airlinecode != '') {
      this.airlinecode = this.airlinecode;
    } else {
      this.airlinecode = ''
    }

    this.searchFlagReset = true;
    var requestdata = {
      share_inventorie_id: this.detail_data.share_inventorie_id,  share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,  page_limit: this.TotalCount,  page_no: "",
      search_value: '',
      // search_value: String(this.inputSearch.nativeElement.value),
       order_key: "",  order_value: "",  account_type: "",
      // new field
      // transaction_type: this.transaction_type,
       airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,
      transaction_type:this.transactionStatus,page:""
    }

    this.sharedService.post('v1/account/company_account_details_new', requestdata).subscribe((res) => {
      if (res.replyCode == "success") {
        this.searchFlagReset = true;
        this.data = res.data;
        this.totalpage = (res.count)/(requestdata.page_limit);
        this.TotalCount = this.data.length;
        // this.TotalCount = res.totalRecords;
        // if(this.transactionStatus=='dr'){
        //   this.TotalCount = res.totalRecords
        // }else{
        //   this.TotalCount = res.credit_count
        // }
      }
    }, err => {
    })
    // this.accountDtl(requestdata);
  }

  onReset() {
    this.inputSearch=null;
    this.searchFlagReset = false;
    this.element['search'] = '';
    this.fromdate = '';
    this.todate = '';
    this.fromdepdate = '';
    this.todepdate = '';
    this.airlinecode = '';
    this.paymentStatus = '';
    this.transaction_type = '';
    this.accountDtl(this.element);

    // this.inputSearch.nativeElement.value = '';
    // this.searchFlagReset = false;
    // this.element['search'] = '';
    // this.accountDtl(this.element);
  }

  onChangePaymentStatusModal(id,payStatus){
    this.pid = id;
    this.paymant_status = payStatus;

    var requesData = {
      "id":this.pid,
      "status":this.paymant_status
    }

    this.sharedService.post('v1/account/mark_received_partner', requesData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.accountDtl(this.element);
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr("Result Not Found", "Error")
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });

  }

  onRightClick(id,payStatus) {
    this.onChangePaymentStatusModal(id,payStatus);
    return false;
  }

  onPageChange(event) {
    var requestdata = {
      share_inventorie_id: this.detail_data.share_inventorie_id,  share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,  page_limit: "10",  page_no: event,  search_value: "",  order_key: "id",  order_value: "", account_type: "",
      // new field
      transaction_type: this.transaction_type,airline_code:"",
      from_book_date:"",  to_book_date:"",  from_dep_date:"",  to_dep_date:"", payment_status:"",page:""

    }
    this.accountDtl(requestdata);
  }

  sortfun(orderkey, type) {
    if (orderkey == 'narration' && type == 'asc') {
      this.sort_narration = 'asc';
    } else {
      this.sort_narration = 'desc';
    }
    if (orderkey == 'amount' && type == 'asc') {
      this.sort_amount = 'asc';
    } else {
      this.sort_amount = 'desc';
    }
    if (orderkey == 'account_type' && type == 'asc') {
      this.sort_account_type = 'asc';
    } else {
      this.sort_account_type = 'desc';
    }

    this.searchFlagReset = true;
    var DataSearch = {
      share_inventorie_id: this.detail_data.share_inventorie_id,  share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,  page_limit: 10,
      page_no: "",  search: "",
      //search: String(this.inputSearch.nativeElement.value) ? String(this.inputSearch.nativeElement.value) : "",
      order_key: orderkey,  order_value: type,
      // new field
      transaction_type: this.transaction_type
    }

    this.accountDtl(DataSearch);
  }

  resetAccount(){
    var data ={
      user_id: Number(this.detail_data.share_to_user_id)
    }
    this.sharedService.post('v1/account/reset', data).subscribe((res: any) => {
        if (res.status == 'success') {
          this.accountData = res;
          this.toastr.successToastr(res.message, 'Success');
          this.accountDtl(this.element);
        }
    }, err => {
      this.toastr.errorToastr(err.error.message, 'Error');
    });
  }

  onPageChangeReve(event) {
    // console.log("checkEvent Data :::::::::",event);
    this.bodyRequestData['page_no'] = event;
    this.creditRevrsList();
  }

  creditRevrsList(){
    this.ngxLoader.start();
    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
    this.sharedService.post('v1/account/credit_not_reversed_list',this.bodyRequestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.ccData = res.data;
        if ((res.data).length > 0) {
          this.reversData = res.data;
          this.keys = Object.keys(this.reversData);
          this.TotalCount1 = res.totalRecords
          $('#reverseList').modal('show');
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
          this.toastr.errorToastr("Result Not Found", "Error")
        }
      } else {
        this.ngxLoader.stop();
        this.toastr.errorToastr("Result Not Found", "Error")
      }
    }, err => {
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });
  //  }
  }

  download(data) {
    var requestdata = {
      share_inventorie_id: this.detail_data.share_inventorie_id,  share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,  page_limit: this.TotalCount,  page_no: "1",
      search_value: '',  order_key: "",  order_value: "",  account_type: "",
      // new field
      transaction_type: this.transaction_type, airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,
    }
    const self = this;
    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      // this.sharedService.post('v1/account/company_account_details', requestdata).subscribe((res: any) => {
      this.sharedService.post('v1/account/company_account_details_new', requestdata).subscribe((res: any) => {
        if (res.replyCode == "success") {
          if ((res.data).length > 0) {
            this.data1 = res.data;
            setTimeout(() => {
              this.exportdata();
            }, 1300);
          } else {
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          }
        } else {
          self.toastr.errorToastr("Result Not Found", "Error")
        }
      }, err => {
        this.toastr.errorToastr(err.error.replyMsg, "Error");
      });
    // }
    // setTimeout(() => {
    //   // $('#Exportmodal').modal('show');
    //   this.exportdata(data);
    // }, 1000);
  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Excel-Report.xlsx');

  }

  viewDetail(element){
    this.booking_id = element.flight_booking_id
    sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
    window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
  }

  get ac(){return this.addaccountValue.controls;}
  get pc(){return this.addaccountPartner.controls;}

  clAccountBal(){
    $('#narrationModal').modal('hide');
  }
  onNarationData(data){
    this.narrationId = data.id
    this.narrationData = data.narration
    $('#narrationModal').modal('show');
    this.editNarration.patchValue({
      narration:this.narrationData
    })
  }
  onUpdateNarration(){

    this.ngxLoader.start();
    var request={
      "id":this.narrationId,
      "narration":this.editNarration.value.narration
    }
    this.editNarration.controls['id'].setValue(this.narrationId)
    if(this.editNarration.invalid){
      return;
    }else{
      this.sharedService.post('v1/account/edit_narration',request).subscribe((res:any)=>{
        if (res.replyCode == 'success') {
          this.ngxLoader.stop();
          this.toastr.successToastr("Update successfully", "Success")
          this.accountDtl(this.element)
          $('#narrationModal').modal('hide');

        } else {
          this.ngxLoader.stop();
          this.toastr.errorToastr("Result Not Found", "Error")
        }
      })

    }
  }
}


