import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePipe} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Sort } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-partnerapi',
  templateUrl: './partnerapi.component.html',
  styleUrls: ['./partnerapi.component.scss'],
  providers: [DatePipe,NgxUiLoaderService,Ng2SearchPipeModule]
})
export class PartnerapiComponent implements OnInit {
  public environment = environment;
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef; RData:any;
  NoOfStopData=[
    { id: 'cash', name: "CASH" },
    { id: 'cheque', name: "CHEQUE" },
    { id: 'rtgs', name: "RTGS" },
    { id: 'neft', name: "NEFT" },
    { id: 'imps', name: "IMPS" }
  ]
  todate: any = "";  fromdate: any = "";  todepdate: any = "";  fromdepdate: any = "";  depcity: any = "";  arrcity: any = "";
  public imagePath;  image:any;  File: File=null;  public message: string=''; searchreportbool:boolean=false; totalpage: any;
  sort_company_name: any = "desc";
  sort_deposit: any = "desc";
  sort_credit:any = "desc";
  sort_wallet:any = "desc";
  sort_share_token: any = "desc";
  sort_credit_limit: any = "desc";
  public sentrequestform: FormGroup;
  inwarddatalist:any;
  profileImg: any;   usertype:any='balance';
  searchText:any;
  searchText1:any;
  viewData: any;
  sortedAgent: any[];
  viewPmt: any;
  ccData: any;
  type: any;
  public createPayment: FormGroup;
  public createMargin: FormGroup;
  public createTicket: FormGroup;
  userdata: any;
  acceptApicheck: any;
  status: any;
  myapiData: any;
  UnaccpetedmyapiData: any;
  mydata: any;
  delApi: any;
  public apiRequestForm: FormGroup;
  submitted: boolean = false;
  pgc: boolean = false;
  disableinput: boolean = false;
  searchFlagReset: boolean = false;
  myinvertData:any ={};
  public page: number = 1;
  public page1: number|any = 1;
  bodydata: any = {};
  TotalCount: number;
  TotalCount1: number;
  data: any; keys: any;  start = 0; msg: any; FormId: any; flag: boolean = false;
  acceptedApi: any;
  notAcceptedApi: any;
  venderprofile: any;
  bank_list_data: any;
  msgforbank: any;
  Pdata: any;
  isStaffLogin:any;

  constructor(
    private fb: FormBuilder,private datePipe: DatePipe,  private ngxLoader: NgxUiLoaderService,public manageDataService:ManagedataService,
    public sharedService: SharedserviceService,  public router: Router,  public commonService: CommonService,  private toastr: ToastrManager
  ){
    this.sortedAgent = this.ccData;
    this.userdata = JSON.parse(localStorage.getItem("user"));
  }
  //*********shorting Start*********** */
  sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedAgent = data;
      return;
    }
    this.sortedAgent = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'accepted_status': return compare(a.accepted_status, b.accepted_status, isAsc);
        case 'company_name': return compare(a.company_name, b.company_name, isAsc);
        case 'credit_limit': return compare(a.credit_limit, b.credit_limit, isAsc);
        case 'share_token': return compare(a.share_token, b.share_token, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }
  changestatus(val) {
    if (val == 0) {
      this.disableinput = false;
    } else {
      this.disableinput = true;
    }

  }
  changeStatus(e, element) {

    var statusChange;
    if (e.target.checked) {
      statusChange = 1;
    } else {
      statusChange = 0;
    }
    var sendChangeStatus = {
      share_inventory_id: element.id,
      status: statusChange,
      patner: "yes"
    }

    this.sharedService.patch('v1/share_inventory/change_share_inventory_status', sendChangeStatus).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Status change successfully');
        this.partnerapilist(this.bodydata);

      }
    });
  }

  get f() { return this.sentrequestform.controls; }
  get formControlErrors() { return this.MarginForm.controls }

  ngOnInit() {
    this.manageDataService.GetLoggedInUserDetails().subscribe((data:any)=>{ this.isStaffLogin=data;})

    this.MarginForm = this.fb.group({
      margin_adult: ['', Validators.required],
      margin_infant: ['', Validators.required]
    });
    sessionStorage.removeItem("partner_api_booking name");
    this.bodydata = {
      page_limit: "",
      page_no: "",
      search: "",
      order_key: "accepted_status",
      order_value: "desc",
      my_api: 0
    }
    this.myinvertData={
      name:"",
      page_limit: "",
      page_no: "",
      search: "",
      order_key: "",
      order_value: "desc"
    }
    this.ngxLoader.start();
    this.sentrequestform = this.fb.group({
      type: ['balance', Validators.required],  amount: ['', Validators.required],  mode: ['', Validators.required],  reference: ['', Validators.required],
      image: [''],  comment: ['']
    });
    this.partnerapilist(this.bodydata);
    this.createMargin = this.fb.group({
      margin: ['', Validators.required],
      add_margin: ['', Validators.required]
    })
    this.createPayment = this.fb.group({
      add_payment_charge_status: ['', Validators.required],
      amount: ['0', Validators.required],
      percentage: ['0', Validators.required],
    })
    this.createTicket = this.fb.group({
      ticket_display_status: ['', Validators.required],
    })


    this.viewMargin();
    this.addPayment();
    this.ngxLoader.stop();
  }

  partnerapilist(bodydata) {
    this.acceptedApi = 0;
    this.notAcceptedApi = 0;
    this.sharedService.post('v1/share_inventory/api_list', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.myapiData = res.data;
        this.ccData = res.data;
        let index = this.myapiData.findIndex(x => x.id === this.userdata.id);
        res.data.forEach(ele => {
          if (ele.accepted_status == 1) {
            this.acceptedApi += 1;
          } else {
            this.notAcceptedApi += 1;
          }
        })

        if (index != 0 && index != -1) {
          var zeroindexdata = this.myapiData[0];
          this.myapiData[0] = this.myapiData[index];
          this.myapiData[index] = zeroindexdata;
          this.keys = Object.keys(this.myapiData[0]);
          this.TotalCount = (res.data).count;
        }
      }
    });
  }

  download() {
    var request = {
      page_limit: "",
      page_no: "",
      search: "",
      order_key: "accepted_status",
      order_value: "desc",
      my_api: 0
    };

    this.sharedService.post('v1/share_inventory/api_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
            this.Pdata = res.data;
            this.TotalCount = res.count;
            this.keys = Object.keys(this.Pdata[0]);

        }
        setTimeout(() => {
            this.exportdata();
        }, 1000);
    }, err => {
    })

  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element,{dateNF:'dd/MMM/yyyy;@',cellDates:true, raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1',);
    /* save to file */
    XLSX.writeFile(wb, 'Excel-Report.xlsx');
  }

  sortfun(orderkey, type) {
    if (orderkey == 'company_name' && type == 'asc') { this.sort_company_name = 'asc'; } else { this.sort_company_name = 'desc'; }
    if (orderkey == 'deposite' && type == 'asc') { this.sort_deposit = 'asc'; } else { this.sort_deposit = 'desc'; }
    if (orderkey == 'credit' && type == 'asc') { this.sort_credit = 'asc'; } else { this.sort_credit = 'desc'; }
    if (orderkey == 'wallet' && type == 'asc') { this.sort_wallet = 'asc'; } else { this.sort_wallet = 'desc'; }
    if (orderkey == 'share_token' && type == 'asc') { this.sort_share_token = 'asc'; } else { this.sort_share_token = 'desc'; }
    if (orderkey == 'credit_limit' && type == 'asc') { this.sort_credit_limit = 'asc'; } else { this.sort_credit_limit = 'desc'; }
    this.searchFlagReset = true;
    var DataSearch = {
      page_limit: 10,
      page_no: "",
      search_value: "",
      account_type: "patner",
      order_key: orderkey,
      order_value: type,
    }
    this.partnerapilist(DataSearch)
  }


  viewPartner(data) {
    this.mydata = data
    $('#viewApiModal').modal('show');
  }

  onDelete(element) {
    this.delApi = element;
    $('#delete').modal('show');
  }

  saerch(event:any){
    if(this.searchText.length >= 3){
      var self = this;
      var request_data={
          "name": this.searchText,
      }
      self.sharedService.post('v1/share_inventory/not_shared_inward',request_data).subscribe((res:any)=>{
        if(res.replyCode == 'success'){
          // this.ngxLoader.start();
          this.inwarddatalist = res.data;
          this.keys = Object.keys(this.inwarddatalist[0]);
          this.TotalCount = (res.data).count;
          // this.ngxLoader.stop();
        }
      },err =>{
        // this.ngxLoader.stop();
        this.toastr.errorToastr(err.replyMsg,'Error')
      })
    }else{
      this.inward(request_data)
    }
  }

  inward(data:any) {
    var self = this;
    var request_data;
      request_data ={
        "name": '',
      }
      $('#inwardlist').modal('show');
    self.sharedService.post('v1/share_inventory/not_shared_inward',request_data).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        // this.ngxLoader.start();
        this.inwarddatalist = res.data;
        this.keys = Object.keys(this.inwarddatalist[0]);
        this.TotalCount1 = (res.data).count;
        // this.toastr.successToastr(res.replyMsg,'Success')
        // this.ngxLoader.stop();
      }
    },err =>{
      // this.ngxLoader.stop();
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  onReset() {
    this.inputSearch.nativeElement.value = '';
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.partnerapilist(this.bodydata);
  }



  onDeleteSubmit(data) {
    var sendChangeStatus = {
      patner_user_id: data.share_by_user_id,
      share_inventory_id: data.id,
      status: 0,
    }
    this.sharedService.patch('v1/share_inventory/accept_partner', sendChangeStatus).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Delete api successfully');
        this.partnerapilist(this.bodydata);
        $('#delete').modal('hide');
      }
    });
  }

  notsharedinward(data) {
    var sendChangeStatus = {
      name: data.user.company_name,
    }
    this.sharedService.patch(' v1/share_inventory/not_shared_inward', sendChangeStatus).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'API run successfully');
        this.partnerapilist(this.bodydata);
      }
    });
  }

  addapiForAccept() {
    console.log(this.myapiData,"this.myapiData-------326---*");

    var unaccepteddata = [];
    this.myapiData.forEach(element => {
      if (element.accepted_status == 0) {
        unaccepteddata.push(element);
      }
    });
    this.UnaccpetedmyapiData = unaccepteddata;
    this.submitted = true;
    $('#addapi').modal('show');
  }

  viewMargin() {
    var add_margin;
    this.sharedService.get('v1/my_api_settiing/details').subscribe((res) => {
      if (res.replyCode == 'success') {
        this.type = res.data;
        var add_payment_status;
        if (this.type.payment_gatway_charges == false) {
          add_payment_status = '0'
          this.disableinput = true
        } else {
          this.disableinput = false;
          add_payment_status = '1'
        }
        if (this.type.add_margin == true) {
          add_margin = '1'
        } else {
          add_margin = '0'
        }
        this.createPayment.patchValue({
          add_payment_charge_status: add_payment_status,
          amount: this.type.amount,
          percentage: this.type.percentage,
        })
        this.createMargin.patchValue({
          margin: this.type.margin,
          add_margin: add_margin
        })
        this.createTicket.patchValue({
          ticket_display_status: this.type.ticket_display_status.toString(),
        })
      }
    });
  }

  addMargin() {
    var self = this;
    self.submitted = true;
    if (self.createMargin.invalid) {
      return;
    } else {
      self.sharedService.postRequest('v1/my_api_settiing/add_margin', self.createMargin.value).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            this.viewData = res.data;

            self.submitted = false;
            this.viewMargin();
            $('#margin').modal('hide');
            self.createMargin.reset();
            self.submitted = false;
            self.toastr.successToastr(res.replyMsg, 'Success');
          } else {
            self.submitted = false;
            self.toastr.errorToastr(res.replyMsg, 'Error');
          }
        } else {
          self.submitted = false;
          self.toastr.errorToastr(res.error.replyMsg, 'Error');
        }
      }, error => {
        self.submitted = false;
        self.toastr.errorToastr("Oops! Something went wrong!");
      });
    }
  }

  addPayment() {
    var self = this;
    self.submitted = true;
    if (this.createPayment.invalid) {
      return;
    } else {

      let formdata = this.createPayment.value;
      if (formdata.amount == '' || formdata.amount == null) {
        this.createPayment.patchValue(
          {
            amount: '0'
          })
      }
      if (formdata.percentage == '' || formdata.percentage == null) {
        this.createPayment.patchValue(
          {
            percentage: '0'
          })
      }


      self.sharedService.postRequest('v1/my_api_settiing/add_payment_charges', self.createPayment.value).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            self.submitted = false;
            this.viewMargin();
            $('#pgcharges').modal('hide');
            self.createPayment.reset();
            self.submitted = false;
            self.toastr.successToastr(res.replyMsg, 'Success');
          } else {
            self.submitted = false;
            self.toastr.errorToastr(res.replyMsg, 'Error');
          }
        } else {
          self.submitted = false;
          self.toastr.errorToastr(res.error.replyMsg, 'Error');
        }
      }, error => {
        self.submitted = false;
        self.toastr.errorToastr("Oops! Something went wrong!");
      });
    }
  }

  acceptApi(data) {
    var sendChangeStatus = {
      patner_user_id: data.share_by_user_id,
      share_inventory_id: data.id,
      status: 1,
    }
    this.sharedService.patch('v1/share_inventory/accept_partner', sendChangeStatus).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Accept API successfully');
        this.partnerapilist(this.bodydata);
        $('#addapi').modal('hide');
      }
    });
  }

  onPageChange(event) {
    this.bodydata['page'] = event;
    this.partnerapilist(this.bodydata);
  }
  onPageChange2(event) {
    this.myinvertData['page_no'] = event;
    this.inward(this.myinvertData);
  }

  ticketApi() {
    var self = this;
    self.submitted = true;
    if (this.createTicket.invalid) {
      return;
    } else {

      self.sharedService.postRequest('v1/my_api_settiing/add_ticket_display_preferences', this.createTicket.value).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            self.submitted = false;
            $('#myapi').modal('hide');
            self.submitted = false;
            self.toastr.successToastr(res.replyMsg, 'Success');
          } else {
            self.submitted = false;
            self.toastr.errorToastr(res.replyMsg, 'Error');
          }
        } else {
          self.submitted = false;
          self.toastr.errorToastr(res.error.replyMsg, 'Error');
        }
      }, error => {
        self.submitted = false;
        self.toastr.errorToastr("Oops! Something went wrong!");
      });
    }
  }

  vender_detail(userId) {
    var data = {
      id: userId
    }
    this.sharedService.post('v1/users/user_details', data).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.venderprofile = res.data;
        if (res.data.credit_limit == null || res.data.credit_limit == "") {
          this.venderprofile.credit_limit = 0;
        }

        if (res.data.contact_person_name != null || res.data.name != null) {
          if (res.data.contact_person_name) {
            this.venderprofile.ownername = res.data.contact_person_name;
          } else {
            this.venderprofile.ownername = res.data.name;
          }
        }

        if (res.data.gst_no == null || res.data.gst_no == "") {
          this.venderprofile.gst_no = "NA";
        }
        $('#contact').modal('show');
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });

  }
  // bank_modal(){
  //   $('#bankDetail').modal('show');
  // }
  showBankList(userId){
    var self = this;
    var request = {
      user_id:userId
     }
     self.sharedService.post('v1/bank/list',request).subscribe((res:any) =>{
      if (res.replyCode == "success") {
        if(res.data.rows.length > 0){
          this.flag = false;
        this.bank_list_data = res.data.rows;
        $('#bankDetail').modal('show');
        // console.log("data length 12:::::::::",res.data.length)
        // console.log("data length :::::::::",this.list_data.length)
        }
        else{
          this.flag =true
          this.msgforbank = "No record found";
        }
      }
     },err=> {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    })
  }

  searchreport(){
    // console.log("from date and to date ------->>",this.fromDate,this.toDate)
    if(this.fromdate != '' && this.todate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.fromdepdate != '' && this.todepdate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.depcity != '' && this.arrcity != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else{
      this.searchreportbool=true;
    }
  }

  onSearch() {
    var element;
    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }

    element = {
      fromBookingDate:this.fromdate,  toBookingDate:this.todate,  fromDepDate:this.fromdepdate,  toDepDate:this.todepdate,
      depCity:this.depcity.toUpperCase(),  arrCity:this.arrcity.toUpperCase(),  page_limit: 10,  page_no: 1,
    }
    this.sharedService.post('v1/booking/booking_failed_list', element).subscribe((res) => {
        if (res.replyCode == "success") {
          this.ccData = res.data;  this.totalpage = (res.count)/(element.page_limit);  this.TotalCount = res.count;
        }
      }, err => {
      })
  }

  SearchDep(){
    if(this.depcity.length == 3){
      this.searchreport()
    }
  }

  SearchArr(){
    if(this.arrcity.length == 3){
      this.searchreport()
    }
  }

  applyFilter(event: Event) {
    $("#myInput").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#myTable1 tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });
  }

  addrequest(type){
    this.submitted=false;
    this.sentrequestform.reset();
    if(type == 'balance'){
      this.usertype='balance';
      this.sentrequestform = this.fb.group({
        type: ['balance', Validators.required],
        amount: ['', Validators.required],
        mode: ['', Validators.required],
        reference: ['', Validators.required],
        image: [''],
        comment: ['']
      });
    }
    else{
      this.usertype='credit'
      this.sentrequestform = this.fb.group({
        type: ['credit', Validators.required],
        amount: ['', Validators.required],
        comment: ['']
      });
    }
    $('#sentmodal').modal('show');
  }

  preview(files) {
    if (files.length === 0){ return; }
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) { this.message = "Only images are supported."; return; }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => { this.image = reader.result;  this.message=''; }
  }

  onFileChanged(event) {
    this.File = event.target.files[0];
  }

  clAccount() {
    $('#sentmodal').modal('hide');
    this.sentrequestform.reset();
    this.submitted = false;
    }

  addRequest(){
    this.ngxLoader.start()
    this.submitted = true;
    if (this.sentrequestform.invalid) {
      this.File=null;
      this.image=null;
      return;
    }else{
      const self=this;
      self.sharedService.loadingShow = true;
        self.sharedService.loadingShow = true;
        const bodyObject = new FormData();
        if(this.usertype == 'balance'){
          if(self.File == null){  bodyObject.append('image','');  }else{  bodyObject.append('image', self.File, self.File.name);  }
          bodyObject.append('to_user_id', (self.RData.share_by_user_id).toString());
          bodyObject.append('type', self.sentrequestform.value.type);
          bodyObject.append('amount', self.sentrequestform.value.amount);
          bodyObject.append('mode', self.sentrequestform.value.mode);
          bodyObject.append('reference', self.sentrequestform.value.reference);
          bodyObject.append('comment', self.sentrequestform.value.comment);
      }else{
          bodyObject.append('to_user_id', (self.RData.share_by_user_id).toString());
          bodyObject.append('type', self.sentrequestform.value.type);
          bodyObject.append('amount', self.sentrequestform.value.amount);
          bodyObject.append('comment', self.sentrequestform.value.comment);
      }
        this.sharedService.postRequest('v1/request/send_new',bodyObject).subscribe((res:any)=> {
          if(res.replyCode == 'success'){
            this.File=null;
            this.usertype='balance'
            this.submitted = false;
            this.sentrequestform.reset();
            this.ngxLoader.stop();
            this.sentrequestform = this.fb.group({
              type: ['balance', Validators.required],  amount: ['', Validators.required],
              mode: ['', Validators.required],  reference: ['', Validators.required],  image: [''],  comment: ['']
            });
            this.toastr.successToastr(res.replyMsg, 'Success');
            this.image=null;
          }else{
            this.usertype='balance'
            this.image=null;
            this.File=null;
            this.ngxLoader.stop();
            this.sentrequestform = this.fb.group({
              type: ['balance', Validators.required],  amount: ['', Validators.required],  mode: ['', Validators.required],
              reference: ['', Validators.required],  image: [''],  comment: ['']});
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
          $('#sentmodal').modal('hide');
        },err=>{
          this.ngxLoader.stop();
          this.usertype='balance'
          if(err.error.replyMsg.message){
            this.submitted = false;
            this.image=null;
            this.File=null;
            this.toastr.errorToastr(err.error.replyMsg.message, 'Error');
            $('#sentmodal').modal('hide');
            this.sentrequestform.reset();
          }else{
            this.submitted = false
            this.image=null;
            this.File=null;
            $('#sentmodal').modal('hide');
            this.toastr.errorToastr(err.error.replyMsg, 'Error');
          }
          this.sentrequestform = this.fb.group({
            type: ['balance', Validators.required],  amount: ['', Validators.required],  mode: ['', Validators.required],
            reference: ['', Validators.required],  image: [''],  comment: ['']});
        });
  }

  }

  addbalancerequest(data){
    this.RData=data;
    this.submitted=false;
    this.sentrequestform.reset();
    this.sentrequestform = this.fb.group({
      type: ['balance', Validators.required],
      amount: ['', Validators.required],
      mode: ['', Validators.required],
      reference: ['', Validators.required],
      image: [''],
      comment: ['']
    });
    $('#sentmodal').modal('show');
  }


  accountdetail(list) {
    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      var data2 = {
        share_inventorie_id: list.id,
        // share_inventorie_id: list.share_inventorie_id,
        share_to_user_id: list.share_to_user_id,
        share_by_user_id: list.share_by_user_id,
        amount: list.credit,
        shareUserId:list.id,
        company_name:list.company_name
      }
      sessionStorage.setItem("account_detail_data", JSON.stringify(data2))
      this.router.navigateByUrl("partner/partnerBalance");
    // }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  goToPartnerCompany(element) {
    this.userdata.partner_company_name = element.company_name;

    var user_details=element.user
    // user_details.status=element.status
    // sessionStorage.setItem("partner_api_booking_name",JSON.stringify(user_details))
    sessionStorage.setItem("partner_api_booking_name",JSON.stringify(this.userdata))
    this.router.navigateByUrl(`/partner/partner-company/${element.user_id}/patner_api`);
  }
  public MarginForm: FormGroup |any;public submittedMargin: boolean = false;public btnsubmittedMargin: boolean = false;
  isEditMarginId:any='';
  onCLoseEditMarginPopup(){
    $('#editmarginPopup').modal('hide');
  }
  onOpenEditMarginPopup(marginObject:any){
    this.isEditMarginId=marginObject.id;
    this.MarginForm.controls['margin_adult'].setValue(marginObject.partner_margin_adult);
    this.MarginForm.controls['margin_infant'].setValue(marginObject.partner_margin_infant);
    $('#editmarginPopup').modal('show');
  }
  onSubmitMargin(){
    this.submittedMargin=true;
    if(this.MarginForm.invalid){
      return;
    }else{
      this.ngxLoader.start();
      this.btnsubmittedMargin=true;
      var sendData:any={};
      sendData=this.MarginForm.value;
      sendData.id=this.isEditMarginId;
      this.sharedService.postRequest('v1/share_inventory/edit_partner_margin',sendData).subscribe((res:any)=> {
        if(res.replyCode == 'success'){
          this.submitted = false;
          this.sentrequestform.reset();
          this.ngxLoader.stop();
          this.toastr.successToastr(res.replyMsg, 'Success');
          $('#editmarginPopup').modal('hide');
          this.partnerapilist(this.bodydata);
          this.btnsubmittedMargin=false;
        }else{
          this.ngxLoader.stop();
          this.toastr.errorToastr(res.replyMsg, 'Error');
          this.btnsubmittedMargin=false;
        }
      },err=>{
        this.ngxLoader.stop();
        this.btnsubmittedMargin=false;
      });
    }
  }
}
