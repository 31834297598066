import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;
  @Component({  selector: 'app-payment-gateway',  templateUrl: './payment-gateway.component.html',  styleUrls: ['./payment-gateway.component.scss']  })
  export class PaymentGatewayComponent implements OnInit {
    @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
    @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
    enquirystatus:any;  viewData: any;  sortedAgent: any[];
    viewPmt: any;  ccData: any;  type: any;
    public createTicket: FormGroup;  userdata: any;  acceptApicheck: any;
    status: any;  Data: any;  paymentgateways: any;
    CommentData: any;  UnaccpetedmyapiData: any;  mydata: any;  delApi: any;
    public apiRequestForm: FormGroup; public addpaymentgatway: FormGroup;public editpaymentgateway: FormGroup;
    submitted: boolean = false;  pgc: boolean = false;
    disableinput: boolean = false;  searchFlagReset: boolean = false;
    bodydata: any = {}; TotalCount: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; FormId: any; flag: boolean = false;
    acceptedApi: any;  notAcceptedApi: any; paymentgatewaydata:any;
    venderprofile: any;  bank_list_data: any;  msgforbank: any;  enquirydata:any={};
    Enquiry_id:any;  Enquiry_status:boolean;  enquiryList: any;  radioButton:any;
    readioSelected:any;  status_id: any;  selectedEnquiry: any; selectedpg:any=0;

    constructor(
      private fb: FormBuilder,  private ngxLoader: NgxUiLoaderService,
      public sharedService: SharedserviceService,  public router: Router,
      public commonService: CommonService,  private toastr: ToastrManager
    ){
      this.sortedAgent = this.ccData;  this.userdata = JSON.parse(localStorage.getItem("user"));
    }
    ngOnInit() {
      this.gatewaylist(); this.bodydata = {  page_limit: 10,  page_no: 1,  status: null,  }
      this.ngxLoader.start();  this.paymentgatewaylist(); this.ngxLoader.stop();
      this.addpaymentgatway = this.fb.group({
        payment_gateway_id:[''], status:[''],
        payment_gateway_field1_value:[''],  payment_gateway_field2_value:[''],
        payment_gateway_field3_value:[''],  payment_gateway_field4_value:[''],
        payment_gateway_field5_value:[''],  payment_gateway_field6_value:[''],
        payment_gateway_field7_value:[''],  payment_gateway_field8_value:[''],
        payment_gateway_field9_value:[''],  payment_gateway_field10_value:[''],
      });
      this.editpaymentgateway = this.fb.group({
        id:'',  payment_gateway_id:'',  status: '',
        payment_gateway_field1_value:'',  payment_gateway_field2_value:'',
        payment_gateway_field3_value:'',  payment_gateway_field4_value:'',
        payment_gateway_field5_value:'',  payment_gateway_field6_value:'',
        payment_gateway_field7_value:'',  payment_gateway_field8_value:'',
        payment_gateway_field9_value:'',  payment_gateway_field10_value:''
      });
    }

    get enq() { return this.addpaymentgatway.controls; }
    get com() { return this.editpaymentgateway.controls; }

    gatewaylist() {
      var data={  "name":"",  "slug":"",  "page":'' }
      this.sharedService.post('v1/payment_gateway/list',data).subscribe((res) => {  if (res.replyCode == 'success') { this.paymentgateways=res.data.rows; }  });
    }

    selectpg(){
      var data={  id: this.selectedpg}
      this.sharedService.post('v1/payment_gateway/details',data).subscribe((res) => {  if (res.replyCode == 'success') { this.paymentgatewaydata=res.data; }  });
    }

    closeAdd() {  $('#Enq_us').modal('hide');  }
    closeedit() {  $('#edit').modal('hide');  }
    ENQ() {  $('#Enq_us').modal('show');  }

    paymentgatewaylist() {
      var data={  "name":"",  "slug":"",  "page":1  }
      this.sharedService.post('v1/user_payment_gateway_detail/list',data).subscribe((res) => {
        if (res.replyCode == 'success') {  this.Data = res.data.rows;  this.TotalCount = res.data.count;  }
      });
    }

    View(data) {  this.viewData=data; $('#view').modal('show');   }
    closeview(){ $('#view').modal('hide'); }


    Edit(data){
      this.editpaymentgateway.patchValue({
        id:data.id,
        payment_gateway_id:(data.payment_gateway.id).toString(),
        payment_gateway_field1_value:data.payment_gateway_field1_value,
        payment_gateway_field2_value:data.payment_gateway_field2_value,
        payment_gateway_field3_value:data.payment_gateway_field3_value,
        payment_gateway_field4_value:data.payment_gateway_field4_value,
        payment_gateway_field5_value:data.payment_gateway_field5_value,
        payment_gateway_field6_value:data.payment_gateway_field6_value,
        payment_gateway_field7_value:data.payment_gateway_field7_value,
        payment_gateway_field8_value:data.payment_gateway_field8_value,
        payment_gateway_field9_value:data.payment_gateway_field9_value,
        payment_gateway_field10_value:data.payment_gateway_field10_value,
        status:data.status
      });
      $('#edit').modal('show')
    }

    update(){
      let formdata=this.editpaymentgateway.value;
      var data= {
        id:formdata.id,
        payment_gateway_id:formdata.payment_gateway_id,
        payment_gateway_field1_value:formdata.payment_gateway_field1_value,
        payment_gateway_field2_value:formdata.payment_gateway_field2_value,
        payment_gateway_field3_value:formdata.payment_gateway_field3_value,
        payment_gateway_field4_value:formdata.payment_gateway_field4_value,
        payment_gateway_field5_value:formdata.payment_gateway_field5_value,
        payment_gateway_field6_value:formdata.payment_gateway_field6_value,
        payment_gateway_field7_value:formdata.payment_gateway_field7_value,
        payment_gateway_field8_value:formdata.payment_gateway_field8_value,
        payment_gateway_field9_value:formdata.payment_gateway_field9_value,
        payment_gateway_field10_value:formdata.payment_gateway_field10_value,
        status:formdata.status == 'true'?'1':'0'
      }
      this.sharedService.post('v1/user_payment_gateway_detail/update',data).subscribe((res) => {
        if (res.replyCode == 'success') {  $('#edit').modal('hide');  this.paymentgatewaylist();  }
        else{  $('#Enq_us').modal('hide');  }
      },err=>{  $('#Enq_us').modal('hide'); });
    }

    changeStatus(e, event) {
      var statusChange;
      if (e.target.checked) {  statusChange = 1;  } else {  statusChange = 0;  }
      var sendChangeStatus = {  id: (event.id).toString(),  status: (statusChange).toString()  }
      this.sharedService.post('v1/user_payment_gateway_detail/status_change', sendChangeStatus).subscribe((res) => {
        if (res.replyCode == 'success') {  this.paymentgatewaylist();  }
      });
    }

    Add(){
      let formdata=this.addpaymentgatway.value;
      var data= {
        payment_gateway_id:(this.paymentgatewaydata.id).toString(),
        payment_gateway_field1_value:formdata.payment_gateway_field1_value,
        payment_gateway_field2_value:formdata.payment_gateway_field2_value,
        payment_gateway_field3_value:formdata.payment_gateway_field3_value,
        payment_gateway_field4_value:formdata.payment_gateway_field4_value,
        payment_gateway_field5_value:formdata.payment_gateway_field5_value,
        payment_gateway_field6_value:formdata.payment_gateway_field6_value,
        payment_gateway_field7_value:formdata.payment_gateway_field7_value,
        payment_gateway_field8_value:formdata.payment_gateway_field8_value,
        payment_gateway_field9_value:formdata.payment_gateway_field9_value,
        payment_gateway_field10_value:formdata.payment_gateway_field10_value,
        status:formdata.status == false ? '1':'0'
      }
      this.sharedService.post('v1/user_payment_gateway_detail/add',data).subscribe((res) => {
        if (res.replyCode == 'success') {  $('#Enq_us').modal('hide');  this.paymentgatewaylist();  }
        else{  $('#Enq_us').modal('hide');  }
      },err=>{  $('#Enq_us').modal('hide');  });
    }

    onPageChange(event) {  this.bodydata['page_no'] = event;  this.paymentgatewaylist();  }
  }

