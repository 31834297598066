import { AuthGuardService} from './services';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminmasterComponent } from './adminlayout/adminmaster/adminmaster.component';
import { ThemeLayoutComponent } from './@layout/@theme/theme';
import { AuthLayoutComponent } from './@layout/@auth/auth';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },{
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
    ]
  },{
    path: '',
    component: ThemeLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./homedashboard/homedashboard.module').then(m => m.HomedashboardModule)
      },{
        path: 'master',
        loadChildren: () => import('./route-file/master.module').then(m => m.MasterModule)
      },{
        path: 'booking-flight',
        loadChildren: () => import('./booking-flight/booking-flight.component.module').then(m => m.BookingFLightModule)
      },{
        path: 'inventory',
        loadChildren: () => import('./module/inventory.module').then(m => m.InventoryModule)
      },{
        path: 'myapi',
        loadChildren: () => import('./module/myapi.module').then(m => m.MyApiModule)
      },{
        path: 'dashboard1',
        loadChildren: () => import('./module/dashboard.module').then(m => m.DashboardModule)
      },{
        path: 'partner',
        loadChildren: () => import('./module/partner-api.module').then(m => m.PartnerApiModule)
      },{
        path: 'supplier',
        loadChildren: () => import('./module/supplier.module').then(m => m.SupplierModule)
      },{
        path: 'booking-summary',
        loadChildren: () => import('./module/book-summary.module').then(m => m.BookSummaryModule)
      },{
        path: 'booking-cancel',
        loadChildren: () => import('./booking-cancel/booking-cancel.module').then(m => m.BookingFlightCancelModule)
      },

   {
        path: 'hotel-vendor',
        children:[
          { path: '', loadChildren: () => import('./vendors/vendor.module').then(m => m.VendorModule) },
        ]
      }
    ]
  },{
    path: '',
    component:ThemeLayoutComponent,
    children:[
      {
      path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    ]
  },
  {
      path: '',
      component:AuthLayoutComponent,
      children:[
        { path: 'homepage', loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule) },
      ]
  },
  {
      path: 'clientdashboard',
      component:AuthLayoutComponent,
      canActivate: [AuthGuardService],
      children:[
        { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
        { path: '', loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule) },
      ]
    },{
    path: '',
    component:ThemeLayoutComponent,
    canActivate: [AuthGuardService],
    children:[
      {
        path: '',
        loadChildren: () => import('./route-file/route-file.module').then(m => m.RouteConfiqFileModule)
      }, 
      { path: '', loadChildren: () => import('./container/whatsapp/whatsapp.module').then(m => m.WhatsappModule) },
      { path: '', loadChildren: () => import('./container/GGN/GGN.module').then(m => m.GGNModule) },
      { path: '', loadChildren: () => import('./container/ggn-active-users/ggn-active-users.module').then(m => m.GgnActiveUsersModule) },
      { path: '', loadChildren: () => import('./container/whatsapp-setting/whatsapp-setting.module').then(m => m.WhatsappSettingModule) },
      { path: '', loadChildren: () => import('./container/invoice-master/invoice-master.module').then(m => m.InvoiceModule) },
      { path: '', loadChildren: () => import('./container/fit-master/fit-master.module').then(m => m.FitMasterModule) },
      { path: '', loadChildren: () => import('./container/staff-master/staff-master.module').then(m => m.StaffMasterModule) },
      { path: '', loadChildren: () => import('./container/department-master/department-master.module').then(m => m.DepartmentMasterModule) },
      { path: '', loadChildren: () => import('./container/manage-api/manage-api.module').then(m => m.ManageApiModule) },
      { path: '', loadChildren: () => import('./container/account-master/account-master.module').then(m => m.AccountingMasterModule) },
      { path: '', loadChildren: () => import('./container/payment/payment.module').then(m => m.PaymentModule) },
      { path: '', loadChildren: () => import('./container/viewprofile/viewprofile.module').then(m => m.ViewProfileModule) },
      { path: '', loadChildren: () => import('./container/seller/seller.module').then(m => m.SellerModule) },
      { path: '', loadChildren: () => import('./container/vendor/vendor.module').then(m => m.VendorModule) },
      { path: '', loadChildren: () => import('./container/client/client.module').then(m => m.CLientModule) },
      { path: '', loadChildren: () => import('./container/agent/agent.module').then(m => m.AgentModule) },
      { path: '', loadChildren: () => import('./container/booking/booking.module').then(m => m.BookingModule) },
      { path: '', loadChildren: () => import('./container/user-activity/user-activity.module').then(m => m.UserActivityModule) },
      { path: '', loadChildren: () => import('./container/booking-summary/booking-summary.module').then(m => m.BookingTicketSummaryModule) },
    ]
  },
  // {
  //   path: '', component:AdminmasterComponent,
  //   canActivate: [AuthGuardService],
  //   children:[
  //     {
  //       path: '',
  //       loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  //     }
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
