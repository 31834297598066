import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiRequestService } from 'src/app/services/api.service';
import { ManagedataService } from 'src/app/services/manage-data.service';

@Component({
  selector: 'app-statics',
  templateUrl: './statics.component.html',
  styleUrls: ['./statics.component.scss'],
  providers: [NgxUiLoaderService,DatePipe]
})
export class StaticsComponent implements OnInit {
  chartWidth: number = 400; // Set your desired width
  chartHeight: number = 300; // Set your desired height
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  isStaticData: any;
  dd: any;
  currDate = new Date();
  size = 2;
  pageIndex = 0;
  total_value: number;
  // viewpie: any[];
  staticsData = new Date();
  selfSectorDatadt = new Date();;
  selfPartnerSectorDatadt = new Date();;
  selfSectorData : any;
  selfPsSectorDate: any;
  selfPartnerSectorData = new Date();
  supplierData = new Date();
  avilDate = new Date();
  single: any[];
  singleQty: any[];
  singleAvail: any[];
  // viewBar : any;
  viewBar : any[] = [2000, 400];
  singleQtyAvil: any[];
  singleQtyAvilUnsold: any[];
   width: number = 700;
  height: number = 300;
  fitContainer: boolean = false;
  gradient: boolean = true;
  supplierSectorData: any;
  total_valueFlightNo: number = 0;
  avilSelectorData: any;
  unSoldSelectorData: any;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  partnerSelectorData: any;
  avildDate: any;
  filterInv: any;
  public pageSlef: any;
  legendPosition: string = 'below';
  public searchagencypage: number  = 1;
  // bodydataSelf: any = {}; totalCountSeltSelector: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; FormId: any; flag: boolean = false;
  bodydata: any = {}; TotalCount: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; FormId: any; flag: boolean = false;
  bodydataSelf: any = {}; TotalCountSelf: string; dataa: any; keyss: any; public pages: number = 1; starts = 0; msgs: any; FormIds: any; flags: boolean = false;
  bodydataPartner: any = {}; TotalCountPartner: string; dataptnr: any; keyptnr: any; public pageptnr: number = 1; startptnr = 0; msgptnr: any; FormIdptnr: any; flagptnr: boolean = false;
  bodydataAvil: any = {}; TotalCountAvil: string; dataAvil: any; keyAvil: any; public pagea: number = 1; startAvil = 0; msgAvil: any; FormIdAvil: any; flagAvil: boolean = false;
  bodydataUnsold: any = {}; TotalCountUnsold: string; dataUnsold: any; keyUnsold: any; public pageUnsold: number = 1; startUnsold = 0; msgUnsold: any; FormIdUnsold: any; flagUnsold: boolean = false;
  // bodydata: any = {}; TotalCount: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; FormId: any; flag: boolean = false;

  // options for the ch
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  xAxisLabel = 'Sector Flight No';
  // selfSectorData: new Date();
  showYAxisLabel = true;
  yAxisLabel = 'Quantity';
  staticsDate: any;
  selfSectorDate: any;
  selfSupSectorDate: any;

  // staticsDate: any;
  // staticsDate: any;
  // staticsDate: any;
  timeline = true;
  doughnut = true;
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#0040ff', '#f6d000', '#90EE90', '#FA8072','#868f00','#868fb7','#860600']
  };
  colorSchemeQuty = {
    domain: ['#52BE80 ', '#87CEFA', '#868fb7','#0040ff', '#FF8500', '#9370DB', '#42e6f5','#868f00','#A569BD']
  };
  colorSchemeBar = {
    domain: ['#9370DB', '#87CEFA', ]
    // domain: ['#f5c542', '#87CEFA', '#0040ff', '#f6d000', '#90EE90', '##42e6f5','#868f00','#868fb7','#860600']
  };
  colorSchemeBarUnsold = {
    // domain: ['#9370DB', '#87CEFA', ]
    domain: ['#9370DB', '#87CEFA',, '#0040ff', '#f6d000', '#90EE90', '##42e6f5','#868f00','#868fb7','#860600']
  };





  constructor(    private ngxLoader: NgxUiLoaderService,
    private apiService:ApiRequestService,private manageData:ManagedataService,  private datePipe: DatePipe,) { }

  ngOnInit() {
    this.ngxLoader.start();
    // this.dataSource = this.data.slice(0,3);
    // this.partnerapilist(this.bodydata);
    this.bodydata = {
      page_limit: 10,
      page: 1,
      status: null,
    }
    this.bodydataSelf = {
      page_limit: 10,
      pages: 1,
      status: null,
    }
    this.bodydataPartner = {
      page_limit: 10,
      pageptnr: 1,
      status: null,
    }
    this.bodydataAvil = {
      page_limit: 10,
      pagea: 1,
      status: null,

    }
    this.bodydataUnsold = {
      page_limit: 10,
      pagesUnsold: 1,
      status: null,
    }

    this.getData();
    this.getDatasSec('');
    this.getDataSupplier('');
    this.getDataPsec('');
    this.getDataAvil('')
    this.dailyUnsoldSelector('')
    this.ngxLoader.stop();

  }



  getData() {
    this.staticsDate = this.datePipe.transform(this.staticsData, 'yyyy-MM-dd');
    this.apiService.onStaticData({
      "date": this.staticsDate
  });
    this.manageData.GetStaticData().subscribe((data:any)=>{
      if(data.data){

        this.isStaticData = data.data;


        this.total_value = Number(this.isStaticData.total_val);

        this.single = [
          {
            "name": `Partner inv sold by website (API) ${this.isStaticData.partner_inv_sold_by_website_user_api_val? this.isStaticData.partner_inv_sold_by_website_user_api_val : 0}`,
            "value": this.isStaticData.partner_inv_sold_by_website_user_api_val? this.isStaticData.partner_inv_sold_by_website_user_api_val : 0
          },

          {
            "name": `Partner inv sold by website (User) ${this.isStaticData.partner_inv_sold_by_website_user_val? this.isStaticData.partner_inv_sold_by_website_user_val : 0}`,
            "value": this.isStaticData.partner_inv_sold_by_website_user_val? this.isStaticData.partner_inv_sold_by_website_user_val : 0
          },

          {
            "name": `Partner inv sold by Admin ${this.isStaticData.partner_inv_sold_from_admin_val? this.isStaticData.partner_inv_sold_from_admin_val : 0}`,
            "value": this.isStaticData.partner_inv_sold_from_admin_val? this.isStaticData.partner_inv_sold_from_admin_val : 0
          },
          ///
          {
            "name": `Sold by Partner ${this.isStaticData.sold_by_partner_val? this.isStaticData.sold_by_partner_val : 0}`,
            "value": this.isStaticData.sold_by_partner_val? this.isStaticData.sold_by_partner_val : 0
          },

          {
            "name": `Sold by Staff ${this.isStaticData.sold_by_staff_val? this.isStaticData.sold_by_staff_val : 0}`,
            "value": this.isStaticData.sold_by_staff_val? this.isStaticData.sold_by_staff_val : 0
          },
          {
            "name": `Sold by Website (API) ${this.isStaticData.sold_by_website_user_api_val? this.isStaticData.sold_by_website_user_api_val : 0}`,
            "value": this.isStaticData.sold_by_website_user_api_val? this.isStaticData.sold_by_website_user_api_val : 0
          },
          {
            "name": `Sold by Admin ${this.isStaticData.sold_from_admin_panel_val? this.isStaticData.sold_from_admin_panel_val : 0}`,
            "value": this.isStaticData.sold_from_admin_panel_val? this.isStaticData.sold_from_admin_panel_val : 0
          },
          {
            "name": `Sold on Website ${this.isStaticData.sold_on_website_val? this.isStaticData.sold_on_website_val : 0}`,
            "value": this.isStaticData.sold_on_website_val? this.isStaticData.sold_on_website_val : 0
          },

        ];
        this.singleQty = [
          // {
          //   "name": "Total",
          //   "value": Number(this.total_value)
          // },
          {
            "name": `Partner inv sold by website (API) ${this.isStaticData.partner_inv_sold_by_website_user_api_qty? this.isStaticData.partner_inv_sold_by_website_user_api_qty : 0}`,
            "value": this.isStaticData.partner_inv_sold_by_website_user_api_qty? this.isStaticData.partner_inv_sold_by_website_user_api_qty : 0
          },

          {
            "name": `Partner inv sold by website (User) ${this.isStaticData.partner_inv_sold_by_website_user_qty? this.isStaticData.partner_inv_sold_by_website_user_qty : 0}`,
            "value": this.isStaticData.partner_inv_sold_by_website_user_qty? this.isStaticData.partner_inv_sold_by_website_user_qty : 0
          },

          {
            "name": `Partner inv sold by Admin ${this.isStaticData.partner_inv_sold_from_admin_qty? this.isStaticData.partner_inv_sold_from_admin_qty : 0}`,
            "value": this.isStaticData.partner_inv_sold_from_admin_qty? this.isStaticData.partner_inv_sold_from_admin_qty : 0
          },
          ///
          {
            "name": `Sold by Partner ${this.isStaticData.sold_by_partner_qty? this.isStaticData.sold_by_partner_qty : 0}`,
            "value": this.isStaticData.sold_by_partner_qty? this.isStaticData.sold_by_partner_qty : 0
          },

          {
            "name": `Sold by Staff ${this.isStaticData.sold_by_staff_qty? this.isStaticData.sold_by_staff_qty : 0}`,
            "value": this.isStaticData.sold_by_staff_qty? this.isStaticData.sold_by_staff_qty : 0
          },
          {
            "name": `Sold by Website (API) ${this.isStaticData.sold_by_website_user_api_qty? this.isStaticData.sold_by_website_user_api_qty : 0}`,
            "value": this.isStaticData.sold_by_website_user_api_qty? this.isStaticData.sold_by_website_user_api_qty : 0
          },
          {
            "name": `Sold by Admin ${this.isStaticData.sold_from_admin_panel_qty? this.isStaticData.sold_from_admin_panel_qty : 0}`,
            "value": this.isStaticData.sold_from_admin_panel_qty? this.isStaticData.sold_from_admin_panel_qty : 0
          },
          {
            "name": `Sold on Website ${this.isStaticData.sold_on_website_qty? this.isStaticData.sold_on_website_qty : 0}`,
            "value": this.isStaticData.sold_on_website_qty? this.isStaticData.sold_on_website_qty : 0
          },

        ];
        Object.assign(this, {single:this.single});
        Object.assign(this, {singleQty:this.singleQty});
      }
    })
  }

  getDatasSec(bodydataSelf) {
    this.selfSectorDate = this.datePipe.transform(this.selfSectorDatadt, 'yyyy-MM-dd');
    this.apiService.dailySelfSector({"date": this.selfSectorDate})
    this.manageData.GetSelfSectorData().subscribe((res: any)=> {
     if(res.replyCode == 'success') {
      this.selfSectorData = res['data']['data'];
      this.TotalCount= this.selfSectorData.length;
     }
    })
  }

  getDataSupplier(bodydataSelf) {
    this.selfSupSectorDate = this.datePipe.transform(this.supplierData, 'yyyy-MM-dd');
    this.apiService.dailySupplierSector({"date": this.selfSupSectorDate})
    this.manageData.GetSupplierSectorData().subscribe((res: any)=> {
     if(res.replyCode == 'success') {
      this.supplierSectorData = res['data']['data'];
      this.TotalCountSelf = this.supplierSectorData.length;
     }
    })
  }
  getDataPsec(bodydataParther) {
    this.selfPsSectorDate = this.datePipe.transform(this.selfPartnerSectorDatadt, 'yyyy-MM-dd');
    this.apiService.dailyPartnerSector({"date": this.selfPsSectorDate})
    this.manageData.GetPartnerSectorData().subscribe((res: any)=> {
     if(res.replyCode == 'success') {
      this.partnerSelectorData = res['data']['data'];
      this.TotalCountPartner = this.partnerSelectorData.length;
     }
    })
  }
  getDataAvil(bodydataAvil) {
    this.avildDate = this.datePipe.transform(this.avilDate, 'yyyy-MM-dd');
    this.apiService.dailyAvilSector({"date": this.avildDate})
    this.manageData.GetAvilSectorData().subscribe((res: any)=> {
     if(res.replyCode == 'success') {
      this.avilSelectorData = res['data']['data'];
      this.singleQtyAvil = this.avilSelectorData.map((item) => ({
        "name":`${item.dep_city_code}- ${item.arr_city_code} | ${item.flight_number}`,
        "series": [
          {
            "name": "Sold",
            "value": Number(item.seats_sold)
          },
          {
            "name": "Unsold",
            "value": Number(item.seats_unsold)
          }]
    }));
      this.singleQtyAvilUnsold = this.avilSelectorData.map((item) => ({
        "name": `${item.dep_city_code} ${item.arr_city_code} ${item.flight_number}`,
        "value":  Number(item.seats_unsold),
    }));

      Object.assign(this, {singleQtyAvil:this.singleQtyAvil});
      Object.assign(this, {singleQtyAvilUnsold:this.singleQtyAvilUnsold});
      this.TotalCountAvil = this.avilSelectorData.length;


     }
    })
  }






  filtersData() {
        switch (this.filterInv) {
      case 'A-Z':
     this.avilSelectorData.sort((a, b) => {
      const nameA = a.dep_city_code.toUpperCase();
      const nameB = b.dep_city_code.toUpperCase();

      if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          this.singleQtyAvil = this.avilSelectorData.map((item) => ({
            "name":`${item.dep_city_code}- ${item.arr_city_code} | ${item.flight_number}`,
            "series": [
              {
                "name": "Sold",
                "value": Number(item.seats_sold)
              },
              {
                "name": "Unsold",
                "value": Number(item.seats_unsold)
              }]
        }));
          this.singleQtyAvilUnsold = this.avilSelectorData.map((item) => ({
            "name": `${item.dep_city_code} ${item.arr_city_code} ${item.flight_number}`,
            "value":  Number(item.seats_unsold),
        }));
          Object.assign(this, {singleQtyAvil:this.singleQtyAvil});
          break;

  case 'Z-A':
    this.avilSelectorData.sort((a, b) => {
     const nameA = b.dep_city_code.toUpperCase();
     const nameB = a.dep_city_code.toUpperCase();

     if (nameA < nameB) {
             return -1;
           }
           if (nameA > nameB) {
             return 1;
           }
           return 0;
         });
         this.singleQtyAvil = this.avilSelectorData.map((item) => ({
           "name":`${item.dep_city_code}- ${item.arr_city_code} | ${item.flight_number}`,
           "series": [
             {
               "name": "Sold",
               "value": Number(item.seats_sold)
             },
             {
               "name": "Unsold",
               "value": Number(item.seats_unsold)
             }]
       }));
         this.singleQtyAvilUnsold = this.avilSelectorData.map((item) => ({
           "name": `${item.dep_city_code} ${item.arr_city_code} ${item.flight_number}`,
           "value":  Number(item.seats_unsold),
       }));
         Object.assign(this, {singleQtyAvil:this.singleQtyAvil});
         break;
         case 'Flight No':
    this.avilSelectorData.sort((a, b) => {
     const nameA = a.flight_number.toUpperCase();
     const nameB = b.flight_number.toUpperCase();

     if (nameA < nameB) {
             return -1;
           }
           if (nameA > nameB) {
             return 1;
           }
           return 0;
         });
         this.singleQtyAvil = this.avilSelectorData.map((item) => ({
           "name":`${item.dep_city_code}- ${item.arr_city_code} | ${item.flight_number}`,
           "series": [
             {
               "name": "Sold",
               "value": Number(item.seats_sold)
             },
             {
               "name": "Unsold",
               "value": Number(item.seats_unsold)
             }]
       }));
         this.singleQtyAvilUnsold = this.avilSelectorData.map((item) => ({
           "name": `${item.dep_city_code} ${item.arr_city_code} ${item.flight_number}`,
           "value":  Number(item.seats_unsold),
       }));
         Object.assign(this, {singleQtyAvil:this.singleQtyAvil});
         break;

         case 'Show All Sold':
               this.singleQtyAvil = this.avilSelectorData.map((item) => ({
                 "name":`${item.dep_city_code}- ${item.arr_city_code} | ${item.flight_number}`,
                 "series": [
                   {
                     "name": "Sold",
                     "value": Number(item.seats_sold)
                   },
                   {
                     "name": "Unsold",
                     "value": Number(item.seats_unsold)
                   }
                  ]
             }));
               this.singleQtyAvilUnsold = this.avilSelectorData.map((item) => ({
                 "name": `${item.dep_city_code} ${item.arr_city_code} ${item.flight_number}`,
                 "value":  Number(item.seats_unsold),
             }));

               Object.assign(this, {singleQtyAvil:this.singleQtyAvil});
               break;
               case 'Show All UnSold':
                this.singleQtyAvil = this.avilSelectorData.map((item) => ({
                  "name":`${item.dep_city_code}- ${item.arr_city_code} | ${item.flight_number}`,
                  "series": [
                    {
                      "name": "Unsold",
                      "value": Number(item.seats_unsold)
                    }
                   ]
              }));
                this.singleQtyAvilUnsold = this.avilSelectorData.map((item) => ({
                  "name": `${item.dep_city_code} ${item.arr_city_code} ${item.flight_number}`,
                  "value":  Number(item.seats_unsold),
              }));

                Object.assign(this, {singleQtyAvil:this.singleQtyAvil});
                break;

 }
}



  dailyUnsoldSelector(bodydataUnsold) {
    this.apiService.dailyUnsoldSector({"date": "2024-01-29"})
    this.manageData.GetUnsoldSectorData().subscribe((res: any)=> {
     if(res.replyCode == 'success') {
      this.unSoldSelectorData = res['data']['data'];
      this.TotalCountUnsold = this.unSoldSelectorData.length;
     }
    })
  }

  onPageChange(event) {
    this.bodydata['page'] = event;
    // this.dailySelfSectorSales(this.bodydata);
  }

  onPageChangeSupplier(event) {
    this.bodydataSelf['pages'] = event;
    // this.dailySupplierSelector(this.bodydataSelf);
  }

  onPageChangePartner(event) {
    this.bodydataPartner['pageptnr'] = event;
    // this.dailyPartnerSelector(this.bodydataPartner);
  }
  onPageChangeAvil(event) {
    this.bodydataAvil['pagea'] = event;
    // this.dailyAvilSelector(this.bodydataAvil);
  }
  onPageChangeUnsold(event) {
    this.bodydataUnsold['pageUnsold'] = event;
    this.dailyUnsoldSelector(this.bodydataUnsold);
  }


}
