import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatHorizontalStepper } from '@angular/material';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { AmountPatternValidator } from 'src/app/services/amount-pattern-validator';


declare var $: any;
@Component({
  selector: 'app-myapi',
  templateUrl: './myapi.component.html',
  styleUrls: ['./myapi.component.scss'],
  providers: [NgxUiLoaderService,    Ng2SearchPipeModule, DatePipe]
})

export class MyapiComponent implements OnInit {
  public environment = environment;
  public dm = 'in';
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  venderList: any;
  sort_name: any = "desc";  sort_email: any = "desc";  sort_mobile: any = "desc";  searchText:any;
  sort_credit:any = "desc";
  sort_deposit:any = "desc";
  sort_wallet:any = "desc";
  ref_id: any='';
  orderkey:any;
  sort_company_name: any = "desc";
  sort_website: any = "desc";
  sort_share_token: any = "desc";
  sort_share_plan: any = "desc";
  sort_share_by_status: any = "desc";
  searchText1:any;
  inwarddatalist:any;
  public margineditform: FormGroup;
  public editUserDataform: FormGroup;

  editData: any;
  public toggleButton: boolean = true;
  editTrue: boolean = false;
  myapiEdit: any;
  flight_report: any;
  clientList: any;
  refData: any; sortedMyapi: any[];
  apiData: any;
  status: any;
  delApi: any;
  ccData: any;
  loadData: any;
  data: any;
  keys: any;
  public page: number = 1;
  public page1: number = 1;
  start = 0; msg: any;
  TotalCount: number;
  TotalCount1: number;
  BaseURL: any;
  searchFlagReset: boolean = false; bodydata: any = {};
  flag: any;
  venderprofile: any;crediBalance:any='';
  displayType: boolean = true;

  displayedColumns: string[] = ['position', 'status', 'passngrname', 'website', 'key', 'plan', 'credotlimit', 'due', 'edit'];
  displayedColumns1: string[] = ['position', 'status', 'companyname', 'website', 'key', 'balance1', 'edit'];
  displayedColumns2: string[] = ['position', 'companyname', 'website', 'key', 'plan', 'status', 'credtlimit', 'action'];
  dataSource2: any;
  public flightNumberUpdateForm: FormGroup;
  public createForm: FormGroup;
  public shareFlightForm: FormGroup;
  public shareAirlineForm: FormGroup;
  submitted = false; btnsubmitted: boolean = false;
  public clEditForm: FormGroup;
  public refForm: FormGroup;
  public pnrmarkupUpdateForm: FormGroup;
  public editApiForm: FormGroup;
  public firstFormGroup: FormGroup;
  public EditMarginform: FormGroup;
  errorAlready: boolean = false;
  admin: boolean = true;
  domain: any;
  public addaccountValue: FormGroup;
  public addaccountValueAgency: FormGroup;
  ELEMENT_DATA2: PeriodicElement1[] = [];
  userdata: any;old_credit_limit: any;
  old_available_balance:any;
  creditVal: any=3;
  dataMyapi = new MatTableDataSource<PeriodicElement1>(this.ELEMENT_DATA2);

  diffTime:any='';
  select_date: any;
  onSelectDate: any;
  todaydate:any = new Date();
  isAuto:boolean=false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatHorizontalStepper, { static: false }) stepper: MatHorizontalStepper;
  detail_data: any;
  accountData: any;
  reference_id: any='';
  add_b_sel_op:any=[
    // { title: "Transaction Type", value: 3 },
    // { title: "Give Credit", value: 0 },
    // { title: "Add Balance", value: 1 },
    // { title: "Refund", value: 'refund' },
    // { title: "Other Charges", value: 'other_charges' },
    { title: "Paid", value: 'paid' },
    { title: "Balance", value: 'balance' },
    { title: "Credit", value: 'credit' },
    { title: "Credit Note", value: 'credit_note' },
    { title: "Refund", value: 'refund' },
    { title: "SSR ", value: 'ssr' },
    { title: "Add Pax", value: 'add_pax' },
    { title: "Other Charges", value: 'other_charges' },
  ]
  add_b_sel_opAgency:any=[  { title: "Transaction Type *", value: 3 },   { title: "Give Credit", value: 0 },  { title: "Add Balance", value: 1 },  ]

  isAutoChange: any;
  refIdData: any;
  createApiData: any;
  myRefId: any;
  userIdData: any;
  shareDataAirline: any;
  shareData: any;
  isCompanyName: any;
  sharetoDataId: any;
  isPnrMarkupData: any;
  isUpdateValue: boolean=false;
  editId: any;
  isFlightMarkupdata: any;
  Mdata: any;isStaffLogin:any;
  TTotalCount: any;rowsClient: any;
  agencyUser:any ={};sortedAgent: any[];  sortedList: any[];
  userDatarole: any;
  TotalCountAgency: number;
  allstate: any;FormId: any;
  imagepathstatic = environment.imageurl;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,public manageDataService:ManagedataService,
    public commonService: CommonService,
    private toastr: ToastrManager,private datePipe: DatePipe
  ) {
    this.userdata = JSON.parse(localStorage.getItem("user"))
    this.sortedMyapi = this.ccData;
    this.sortedAgent = this.ccData;  this.sortedAgent = this.userDatarole;  this.sortedList = this.rowsClient;

  }



  get f() { return this.createForm.controls; }
  get ce() { return this.clEditForm.controls; }
  get m() { return this.EditMarginform.controls; }
  get shareflight() { return this.shareFlightForm.controls; }

  ngOnInit() {
    if(localStorage.getItem('domainame') != undefined && localStorage.getItem('domainame') != null && localStorage.getItem('domainame') != "" ){
      this.domain =localStorage.getItem('domainame');
    }
    this.manageDataService.GetLoggedInUserDetails().subscribe((data:any)=>{ this.isStaffLogin=data;})
    sessionStorage.removeItem("my_api_booking name");
    this.detail_data;
    this.ngxLoader.start();
    this.agencyUser = {
      role_id: 1,  page_limit: 10,  page_no: 1, search: "",  order_key: "createdAt",  order_value: "DESC", api_user:'1'
    }
    this.bodydata ={  page_limit: "",   page_no: "",   search: "",    order_key: "accepted_status",   order_value: "desc" , my_api: 1 };
    // this.bodydata ={  page_limit: "",   page_no: "",   search: "",    order_key: "createdAt",   order_value: "asc" , my_api: 0 };
    this.myApiList(this.bodydata);
      this.addaccountValue = this.formBuilder.group({
        share_inventorie_id: ['', Validators.required],  share_to_user_id: ['', Validators.required],
        amount: ['', Validators.required],  narration: ['', Validators.required],
        // reference_id:[''],
      })
      this.editUserDataform = this.formBuilder.group({
        id: [''],
        name: [''],
        email: [''],
        mobile: [''],
        company_name: [''],
        city:[''],
        state_id:[''],
        address:[''],
        address_2:[''],
        zipcode:[''],
        country_id:[93],
        pan_no:[''],
        gst_no:[''],
        aadhaar_no:['']
      })
    this.addaccountValueAgency = this.formBuilder.group({
      patner_vendor_id: ['', Validators.required],
      amount: ['', Validators.required],
      narration: ['', Validators.required],
    })
    this.margineditform = this.formBuilder.group({
      id: ['', Validators.required],
      my_adult: ['', Validators.required],
      my_infant: ['', Validators.required],
      partner_adult: ['', Validators.required],
      partner_infant: ['', Validators.required],
      supplier_adult:['', Validators.required],
      supplier_infant:['', Validators.required]
    })
    this.flightNumberUpdateForm = this.formBuilder.group({
      share_to_user_id: ['', Validators.required],
      flight_number: [''],
      markup_adult: [''],
      markup_infant: [''],
    });
    this.pnrmarkupUpdateForm = this.formBuilder.group({
      share_inventory_id: ['', Validators.required],
      pnr: [''],
      markup_adult: [''],
      markup_infant: [''],
    });
    this.shareFlightForm = this.formBuilder.group({
      share_inventory_id: ['', Validators.required],
      share_flight: [''],
      dont_share_flight: ['']
    });
    this.createForm = this.formBuilder.group({
      user_reference_id: ['', Validators.required],
    });
    this.EditMarginform = this.formBuilder.group({
      id: ['', Validators.required],
      margin_adult: ['', Validators.required],
      margin_infant: ['', Validators.required],
      supplier_margin_adult: ['', Validators.required],
      supplier_margin_infant: ['', Validators.required],
    });
    this.refForm = this.formBuilder.group({
      website: ['', Validators.required],  company_name: ['', Validators.required],
      share_to_user_id: ['', Validators.required],  credit_limit: ['0', Validators.required],
      share_price_breakup: ['1', Validators.required],  share_plan: ['1', Validators.required],
      status: ['', Validators.required],
    })

    this.editApiForm = this.formBuilder.group({
      share_inventory_id: ['', Validators.required],  share_to_user_id: ['', Validators.required],
      credit_limit: [''],  share_price_breakup: ['1', Validators.required],
      share_plan: ['1', Validators.required],  status: ['', Validators.required],
    })
    this.ngxLoader.stop();

    this.shareAirlineForm = this.formBuilder.group({
      share_airline: [''],
      dont_share_airline: ['']
    });
  }

apiReference(data){
  // $('#searchUserAndcreateApi').modal('show');

  $('#inwardlist').modal('hide');

}


  editMargin(){
    this.submitted=true;
    if(this.EditMarginform.valid){
      var data=this.EditMarginform.value;
      var request_data={
        id: data.id,
        margin_adult:Number(data.margin_adult),
        margin_infant:Number(data.margin_infant),
        supplier_margin_adult:Number(data.supplier_margin_adult),
        supplier_margin_infant:Number(data.supplier_margin_infant),
      }
      this.sharedService.post('v1/share_inventory/edit_margin', request_data).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.submitted=false;
          this.toastr.successToastr(res.replyMsg, 'Success');
          $('#editmarginmodal').modal('hide');
          this.myApiList(this.bodydata);
        } else {
          this.submitted=false;
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.submitted=false;
        this.toastr.errorToastr(err['error']['message'], 'Error');
      });
    }else{
      this.toastr.errorToastr("fill the form correctly....")
    }
  }
  get mrAgency() { return this.margineditform.controls; }
  marginEdit(data){
    this.EditMarginform.patchValue({
      id: data.id,
      margin_adult:data.margin_adult,
      margin_infant:data.margin_infant,
      supplier_margin_adult:data.supplier_margin_adult,
      supplier_margin_infant:data.supplier_margin_infant,
    })
    setTimeout(() => {
        $('#editmarginmodal').modal('show');
    }, 500);
  }

  resetstepper() {
    this.stepper.reset();
  }
  get ca() { return this.refForm.controls; }

  myApiRef(myId){
    this.myRefId = myId;
    setTimeout(() => {
      this.onAddForm()
    }, 500);
  }

  onAddForm() {
    $('#inwardlist').modal('hide');
    var self = this;
    self.submitted = true;
    // console.log("this.refIdData.ref=========== 210 =========",data.reference_id);
    // return false;
    if(!this.myRefId){
      this.createApiData = this.createForm.value.user_reference_id
    }else{
      this.createApiData = this.myRefId
    }

      var refID = {
        user_reference_id : this.createApiData
      }
    // if (self.createForm.invalid) {
    //   return;
    // } else {
      self.btnsubmitted = true;
      self.sharedService.postRequest('v1/users/find_by_reference_id', refID).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          if (res.data.role_id != 2) {
            this.refData = res.data;
            this.patchform(this.refData)
            $('#createApiMyid').modal('show');
            $('#searchUserAndcreateApi').modal('hide');
            self.submitted = false;
            self.btnsubmitted = false;
            self.createForm.reset();
            this.myRefId = '';
          } else {
            $('#searchUserAndcreateApi').modal('hide');
            self.toastr.errorToastr("API cannot be created for given Reference ID.", "Error")
            self.submitted = false;
            self.btnsubmitted = false;
            self.createForm.reset();
          }
        } else {
          self.btnsubmitted = false;
          self.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, error => {
        self.btnsubmitted = false;
        self.toastr.errorToastr(error.error.replyMsg);

      });
    // }

  }


  sortfun(orderkey, type) {
  if(this.displayType){
    if (orderkey == 'company_name' && type == 'asc') { this.sort_company_name = 'asc'; } else { this.sort_company_name = 'desc'; }
    if (orderkey == 'website' && type == 'asc') { this.sort_website = 'asc'; } else { this.sort_website = 'desc'; }
    if (orderkey == 'share_token' && type == 'asc') { this.sort_share_token = 'asc'; } else { this.sort_share_token = 'desc'; }
    if (orderkey == 'share_plan' && type == 'asc') { this.sort_share_plan = 'asc'; } else { this.sort_share_plan = 'desc'; }
    if (orderkey == 'share_by_status' && type == 'asc') { this.sort_share_by_status = 'asc'; } else { this.sort_share_by_status = 'desc'; }
    if (orderkey == 'deposite' && type == 'asc') { this.sort_deposit = 'asc'; } else { this.sort_deposit = 'desc'; }
    if (orderkey == 'credit' && type == 'asc') { this.sort_credit = 'asc'; } else { this.sort_credit = 'desc'; }
    if (orderkey == 'wallet' && type == 'asc') { this.sort_wallet = 'asc'; } else { this.sort_wallet = 'desc'; }
    this.searchFlagReset = true;
    this.orderkey = orderkey;
    var DataSearch = {
      my_api: 1,
      page_limit: '10',
      page_no: "1",
      search: String(this.inputSearch.nativeElement.value),
      order_key: orderkey,
      order_value: type
    }
    this.myApiList(DataSearch)
  }else{
    if (orderkey == 'name' && type == 'asc') { this.sort_name = 'asc'; } else { this.sort_name = 'desc'; }
    if (orderkey == 'email' && type == 'asc') { this.sort_email = 'asc'; } else { this.sort_email = 'desc'; }
    if (orderkey == 'mobile' && type == 'asc') { this.sort_mobile = 'asc'; } else { this.sort_mobile = 'desc'; }
    if (orderkey == 'deposite' && type == 'asc') { this.sort_deposit = 'asc'; } else { this.sort_deposit = 'desc'; }
    if (orderkey == 'credit' && type == 'asc') { this.sort_credit = 'asc'; } else { this.sort_credit = 'desc'; }
    if (orderkey == 'wallet' && type == 'asc') { this.sort_wallet = 'asc'; } else { this.sort_wallet = 'desc'; }
    this.searchFlagReset = true;
    this.orderkey = orderkey;
    this.agencyUser = {
      role_id: 1,
      page_limit: '10',
      page_no: '1',
      search: String(this.inputSearch.nativeElement.value),
      order_key: orderkey,
      order_value: type,
    }
    this.onAgencyList()
  }
  }


  get ab() { return this.addaccountValue.controls }
  accountShow(list) {
    console.log("list---418--->",list);

      this.isAuto = false;
      $('#addBalance').modal('show');
      if (this.userdata.role_id == 1 || this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.my_give_bal == 1) {
        this.addaccountValue.patchValue({
          share_inventorie_id:list.id,
          share_to_user_id:list.share_to_user_id,
          share_by_user_id:list.share_by_user_id,
        })
      }
  }

  onSearchChange(searchValue: string): void {
    this.reference_id = searchValue;
  }

  isAutoReveres(event){
    this.isAutoChange = event.checked
  }
  addAccount() {
    var self = this;

    self.submitted = true;
    if (self.addaccountValue.invalid) {
      return;
    } else {

      // if(this.submitted = true && this.crediBalance == '' && this.creditVal == 3){
      //   return;
      if((this.creditVal=='credit_note' && this.reference_id == '')||this.creditVal=='ssr' && this.reference_id == ''||this.creditVal=='add_pax' && this.reference_id == ''){
        return;
      // }else if(this.submitted = true && (this.creditVal == 'refund'|| this.creditVal == 'other_charges') && this.ref_id == ''){
      //   return;
      }
      else{

        // if(this.creditVal == 0){
        //   var acc_type = 'credit';
        // }else if(this.creditVal == 1){
        //   var acc_type = 'balance';
        // }else if(this.creditVal == 'refund'){
        //   var acc_type = 'refund'
        // }else {
        //   var acc_type = 'other_charges'
        // }

        var data = self.addaccountValue.value;
        var request_data:any={
          share_inventorie_id: data.share_inventorie_id,
          share_to_user_id: data.share_to_user_id,
          amount:data.amount ,
          narration: data.narration,
          reference_id: this.reference_id,
          account_type: this.creditVal,
          // account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
          // account_type: acc_type,  // 'balance': to add balance, 'credit': to add credit
          // credit_reversal_date:this.crediBalance ==''?'':this.datePipe.transform(this.crediBalance, 'yyyy-MM-dd')   /* cannot be null if account_type = 'credit' */,
          // is_auto_reverse:this.isAuto,
          // reference_id:this.ref_id
        }

        if (this.userdata.role_id == 1 || this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.my_give_bal == 1) {
          var addBalanceData = 'v1/account/add_balance_new'
          // var addBalanceData = 'v1/account/add_balance'
        }
        this.ngxLoader.start();
        self.sharedService.postRequest(addBalanceData, request_data).subscribe((res: any) => {
          if (!res.error) {
            this.ngxLoader.stop();
            if (res.replyCode == 'success') {
              this.myApiList(this.bodydata);
              this.crediBalance="";  this.creditVal=3;  this.accountData = res.data;  self.submitted = false;  $('#addBalance').modal('hide');
              self.addaccountValue.reset(); self.toastr.successToastr(res.replyMsg, 'Success');
              // var cl = Number(this.detail_data.amount) + Number(request_data.amount)
              // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
              //   var data2 = {
              //     share_inventorie_id: this.detail_data.share_inventorie_id.toString(),amount: cl.toString(),
              //     share_to_user_id: this.detail_data.share_to_user_id.toString(),  share_by_user_id: this.detail_data.share_by_user_id.toString()
              //   }
              //   sessionStorage.setItem("account_detail_data", JSON.stringify(data2));
              // }
            } else {
              self.submitted = false;
              this.crediBalance="";
              this.creditVal=3;
              self.addaccountValue.reset();
              $('#addBalance').modal('hide');
              self.toastr.errorToastr(res.replyMsg, 'Error');
            }
          } else {
            self.submitted = false;
            this.crediBalance="";
            this.creditVal=3;
            self.addaccountValue.reset();
            $('#addBalance').modal('hide');
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          this.ngxLoader.stop();
          self.submitted = false;
          this.crediBalance="";
          this.creditVal=3;
          self.addaccountValue.reset();
          $('#addBalance').modal('hide');
          self.toastr.errorToastr(error.error.replyMsg);
        });
      }
    }
  }


  clAccount() {
    $('#addBalance').modal('hide');
    $('#addBalanceAgency').modal('hide');
    $('#reverseList').modal('hide');
    this.addaccountValue.reset();
    this.submitted = false;
  }

  editModalMyApi(event) {
    var data = {
      id: event
    }
    this.sharedService.post('v1/users/user_details', data).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.venderprofile = res.data;
        if (res.data.credit_limit == null || res.data.credit_limit == "") {
          this.venderprofile.credit_limit = 0;
        }

        if (res.data.contact_person_name != null || res.data.name != null) {
          if (res.data.contact_person_name) {
            this.venderprofile.ownername = res.data.contact_person_name;
          } else {
            this.venderprofile.ownername = res.data.name;
          }
        }

        if (res.data.gst_no == null || res.data.gst_no == "") {
          this.venderprofile.gst_no = "NA";
        }
        var status;
        if (event.status == true) {
          status = 1;
        } else {
          status = 0;
        }
        var share_p_breakup;
        if (event.share_price_breakup == true) {
          share_p_breakup = 1;
        } else {
          share_p_breakup = 0;
        }
        var share_credtlimit;
        if (event.credit_limit == null) {
          share_credtlimit = 0;
        } else {
          share_credtlimit = event.credit_limit;
        }
        this.editApiForm.patchValue({
          share_inventory_id: event.id,
          share_to_user_id: event.share_to_user_id,
          credit_limit: share_credtlimit,
          share_price_breakup: share_p_breakup,
          share_plan: event.share_plan,
          status: status,
        })
        $('#editapiModal').modal('show');
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });



  }

  onPartner(){
    this.displayType = true;
    this.myApiList(this.bodydata)
  }
  onAgencyList(){
    this.displayType = false;
    this.sharedService.post('v1/users/partner_users_list_new', this.agencyUser).subscribe((res) => {
          if (res.replyCode == 'success') {
            this.userDatarole = res.data;
            if (res.data.length > 0) {
              this.userDatarole = res.data;
              this.flight_report = res.data;
              this.data = res.data;
              res.data.forEach(element => {
                element.my_adult=element.my_adult == null ? '-':element.my_adult
                element.my_infant=element.my_infant == null ? '-':element.my_infant
                element.partner_adult=element.partner_adult == null ? '-':element.partner_adult
                element.partner_infant=element.partner_infant == null ? '-':element.partner_infant
                element.supplier_adult=element.supplier_adult == null ? '-':element.supplier_adult
                element.supplier_infant=element.supplier_infant == null ? '-':element.supplier_infant
              });
              this.keys = Object.keys(this.userDatarole[0]);
              this.TotalCountAgency = res.totalRecords;
            } else {
              this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            }
          } else {
            this.flag = true;
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
        }, err => {
          this.toastr.errorToastr(err['error']['replyMsg'], 'Error');
        });



  }

  myApiList(bodydata) {
    let self = this;
    this.sharedService.post('v1/share_inventory/api_list', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {

        this.ccData = res.data;
        this.flight_report = res.data;
        this.data = res['data'];

        let index = this.data.findIndex(x => x.id === this.userdata.id);
        // let index = this.data.findIndex(x => x.user.id == this.userdata.id);

        if (index != 0 && index != -1) {
          var zeroindexdata = this.data[0];
          this.data[0] = this.data[index];
          this.data[index] = zeroindexdata;

          this.keys = Object.keys(this.data[0]);
          this.TotalCount = (res.data).length;
        }
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  download() {
    var request = {
        page_limit: "",
        page_no: "",
        search: "",
        order_key: "accepted_status",
        order_value: "desc" ,
        my_api: 1
    };

    this.sharedService.post('v1/share_inventory/api_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
            this.Mdata = res.data;
            this.TTotalCount = res.count;
            this.keys = Object.keys(this.Mdata[0]);
        }
        setTimeout(() => {
            this.exportdata();
        }, 1000);
    }, err => {
    })

  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element,{dateNF:'dd/MMM/yyyy;@',cellDates:true, raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1',);
    /* save to file */
    XLSX.writeFile(wb, 'Excel-Report.xlsx');
  }

  gotoagentcompany(element) {
    this.userdata.partner_company_name = element.company_name;

    sessionStorage.setItem("my_api_booking_name",JSON.stringify(this.userdata))
    this.router.navigateByUrl("dashboard/agentcompany" + '/' + element.share_to_user_id);
  }

  showsec() {

  }

  editMyApi() {


    this.submitted = true;
    if (this.editApiForm.valid) {
      var createEditApi = this.editApiForm.value
      var sendEditApi = {
        share_inventory_id: createEditApi.share_inventory_id,
        share_to_user_id: createEditApi.share_to_user_id,
        credit_limit: createEditApi.credit_limit,
        share_price_breakup: Number(createEditApi.share_price_breakup),
        share_plan: Number(createEditApi.share_plan),
        status: createEditApi.status,
      }

      this.sharedService.post('v1/share_inventory/update', sendEditApi).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.editData = res.data;

          this.myApiList(this.bodydata);
          $('#editapiModal').modal('hide');
          this.editTrue = false;
          this.toggleButton = true;
        }
      });

    }
  }

  saerch(e:any){
    if(e.code == "Enter" || e.code == "NumpadEnter"){
        this.onSearch();
    }
  }

  onSearchClient(e:any){
    var text_length= (e.target.value).length
    if(text_length >=3){
      this.searchFlagReset = true;
      this.bodydata['page_limit']= '',
      this.bodydata['page_no']= 1,
      this.bodydata['search'] = String(this.inputSearch.nativeElement.value);
      this.myApiList(this.bodydata);
    }else if(text_length < 3 && text_length == 0){
      this.searchFlagReset = true;
      this.bodydata['page_limit']= 10,
      this.bodydata['page_no']= 1,
      this.bodydata['search'] = '';
      this.myApiList(this.bodydata);
    }
  }
  saerch1(event:any){
    if(this.searchText1.length >= 3){
      var self = this;
      var request_data={
          "name": this.searchText1,
          "page":''
      }
      self.sharedService.post('v1/share_inventory/not_shared_outward',request_data).subscribe((res:any)=>{
        if(res.replyCode == 'success'){
          this.inwarddatalist = res.data;
          this.keys = Object.keys(this.inwarddatalist[0]);
          this.TotalCount = (res.data).count;
        }
      },err =>{
        this.toastr.errorToastr(err.replyMsg,'Error')
      })
    }else{
      this.inward(request_data)
    }
  }

  inward(data:any) {
    var self = this;
    var request_data;
      request_data ={
        "name": '',
        "page": ''
      }
      $('#inwardlist').modal('show');
    self.sharedService.post('v1/share_inventory/not_shared_outward',request_data).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.inwarddatalist = res.data;
        this.keys = Object.keys(this.inwarddatalist[0]);
        this.TotalCount1 = (res.data).count;
        // this.toastr.successToastr(res.replyMsg,'Success')
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  onSearch() {
    this.searchFlagReset = true;
    this.bodydata =
    {
      my_api: 1,
      page_limit: "",
      page_no: "",
      search: String(this.inputSearch.nativeElement.value),
      order_key: "createdAt",
      order_value: "asc"
    }
    this.myApiList(this.bodydata);
  }

  patchform(data) {
    this.refForm.patchValue({
      website: data.website,
      credit_limit:'0',
      company_name: data.company_name,
      status: data.status,
      share_price_breakup: 1,
      share_to_user_id: data.id,
      share_plan: 1
    });

  }

  createApi() {
    this.submitted = true;
    var createData = this.refForm.value
    var sendData1 = {
      website: createData.website,
      company_name: createData.company_name,
      share_to_user_id: createData.share_to_user_id,
      credit_limit: createData.credit_limit,
      share_price_breakup: Number(createData.share_price_breakup),
      share_plan: Number(createData.share_plan),
      status: createData.status
    }
    if (this.refForm.valid) {
      this.sharedService.put('v1/share_inventory/add_partner', sendData1).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          this.submitted = false;
          this.refForm.reset();
          // this.stepper.reset()
          this.ELEMENT_DATA2.length = 0;
          this.myApiList(this.bodydata);
          $('#createApiMyid').modal('hide');
        }
        else if (res.replyCode == 'error') {
          this.submitted = false

          this.toastr.errorToastr("Already Shared API", "Already Shared")
          $('#createApiMyid').modal('hide');
        }
      }, error => {
        this.submitted = false

        this.toastr.errorToastr("Already Shared API", "Already Shared")
        $('#createApiMyid').modal('hide');

      }
      );
    }
  }

  changeStatus(e, event) {
    var statusChange;
    if (e.target.checked) {
      statusChange = 1;
    } else {
      statusChange = 0;
    }
    var sendChangeStatus = {
      share_inventory_id: event.id,
      status: statusChange,
    }
    this.sharedService.patch('v1/share_inventory/change_share_inventory_status', sendChangeStatus).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Status change successfully');
        this.myApiList(this.bodydata);
      }
    });
  }

  accountdetail(list){
    console.log("list======>>>>",list);
    // return;
    if(this.userdata.role_id == 1){
      var data2={
        share_inventorie_id:list.id,
        share_to_user_id:list.share_to_user_id,
        share_by_user_id:list.share_by_user_id,
        amount:list.credit_limit,
        companyName:list.company_name
      }
      sessionStorage.setItem("account_detail_data",JSON.stringify(data2))
      this.router.navigateByUrl("myapi/balance");
    }
     else if (this.userdata.role_id == 3 || this.userdata.role_id == 5 ||  this.userdata.my_view == 1){
      var data2={
        share_inventorie_id:list.id,
        share_to_user_id:list.share_to_user_id,
        share_by_user_id:list.share_by_user_id,
        amount:list.credit_limit,
        companyName:list.company_name
      }
      sessionStorage.setItem("account_detail_data",JSON.stringify(data2))
      this.router.navigateByUrl("myapi/balance");
    }
  }

  onDelete(element) {
    this.delApi = element;
    $('#delete').modal('show');
  }

  onDeleteSubmit(element) {
    var sendChangeStatus = {
      share_inventory_id: element.id,
      status: 2,
    }
    this.sharedService.patch('v1/share_inventory/change_share_inventory_status', sendChangeStatus).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Delete api successfully');
        this.ELEMENT_DATA2.length = 0;
        this.myApiList(this.bodydata);
        $('#delete').modal('hide');
      }
    });
  }

  editDisabled() {
    this.submitted = true;
    this.editTrue = true;
    this.toggleButton = false;
  }

  cancleTrue() {
    $('#editapiModal').modal('hide');
    this.editTrue = false;
    this.toggleButton = true;
  }

  onReset() {
    if(this.displayType){
      this.inputSearch.nativeElement.value = '';
      this.searchFlagReset = false;
      this.bodydata['search'] = '';
      this.myApiList(this.bodydata);
    }else{
      this.inputSearch.nativeElement.value = '';
      this.searchFlagReset = false;
      this.agencyUser['search'] = '';
      this.onAgencyList();
    }
  }
  onPageChange(event) {
    this.bodydata['page'] = event;
    this.bodydata['order_value'] = this.sort_deposit;
    this.bodydata['order_key'] = this.orderkey;
    this.myApiList(this.bodydata);
  }
  onPageChange2(event) {
    this.bodydata['page'] = event;
    this.inward(this.bodydata);
  }
  onPageChangeAgency(event) {
    this.agencyUser['page_no'] = event;
    this.agencyUser['order_value'] = this.sort_deposit;
    this.agencyUser['order_key'] = this.orderkey;
    this.onAgencyList();
  }
  sendEmail(email){
    window.location.href="mailto:" + email;
  }
  myFunctionDate(val){
    this.select_date == val;
    var newdate=new Date();
    // console.log("check Date 1 is", this.last_Select_Date);
    // console.log("check Date 2 is", this.currentdate);
    var diff = val.getTime() - newdate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    // return hours+" hrs "+": "+minutes+" min";
    this.diffTime= days+" Days, "+ hours+" Hours,"+minutes+" Minute, "+seconds+" Seconds";
    // console.log("new date:::::", newdate)

    this.onSelectDate = val ;
     this.onSelectDate.toDateString() ;
    // console.log("on Date select :::::",this.onSelectDate);
    // console.log("select date::::",this.select_date )

  }
  isAutoReverse(event){
    var myvalisAuto = event;
    // this.isAuto = ! this.isAuto;
    if (event.checked == true) {
      this.isAuto = true;
      // localStorage.setItem('ContactShow','true')
    } else {
      this.isAuto = false;
      // localStorage.setItem('ContactShow','false')
    }
  }
  clAccountshare(){
    $('#pnrMarkup').modal('hide');
    $('#shareFlight').modal('hide');
    $('#flightNumberUpdate').modal('hide');
    this.flightNumberUpdateForm.reset();
    this.pnrmarkupUpdateForm.reset();
  }

  onFlightMarkup(userId:any,compName:any){
    this.sharetoDataId = userId;
    this.isCompanyName= compName;
      var flightMarkupRequest = {
        share_to_user_id: userId,
      }
      this.sharedService.post('v1/share_inventory/flight_number_markup', flightMarkupRequest).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.isFlightMarkupdata = res.share_flight
        }
      });
    $('#flightNumberUpdate').modal('show');
  }
  onFlightNumberAdd(){
    var onPnrMarkupAdd = {
      share_to_user_id: this.sharetoDataId,
      flight_number:this.flightNumberUpdateForm.value.flight_number,
      markup_adult:this.flightNumberUpdateForm.value.markup_adult,
      markup_infant:this.flightNumberUpdateForm.value.markup_infant
    }
    this.sharedService.post('v1/share_inventory/flight_number_markup_add', onPnrMarkupAdd).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Flight Number add successfully');
        this.onFlightMarkup(this.sharetoDataId,this.isCompanyName);
        this.flightNumberUpdateForm.reset();
      }
    });
  }
  onPatchFlightNumber(data:any){
    var editData = data;
    this.editId = data.id;
    this.flightNumberUpdateForm.patchValue({
      id: editData.id,
      flight_number:editData.flight_number,
      markup_adult:editData.markup_adult,
      markup_infant:editData.markup_infant,
    })
    this.isUpdateValue=true;
  }
  onFlightEditMarkup(){
    var requestData = {
      id: this.editId,
      flight_number:this.flightNumberUpdateForm.value.flight_number,
      markup_adult:Number(this.flightNumberUpdateForm.value.markup_adult),
      markup_infant:Number(this.flightNumberUpdateForm.value.markup_infant),
      status:1
    }
    this.sharedService.post('v1/share_inventory/flight_number_markup_edit', requestData).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'PNR update successfully');
        this.onFlightMarkup(this.sharetoDataId,this.isCompanyName);
        this.isUpdateValue=false;
        this.flightNumberUpdateForm.reset();
      }
    });
  }
  onFlightNumberDelete(pnrData:any){
    var flightDeleteData = pnrData
    var onEditPnrRequest = {
      id: flightDeleteData.id,
      flight_number:flightDeleteData.flight_number,
      markup_adult:Number(flightDeleteData.markup_adult),
      markup_infant:Number(flightDeleteData.markup_infant),
      status:2
    }
    this.sharedService.post('v1/share_inventory/flight_number_markup_edit', onEditPnrRequest).subscribe((res) => {
      if (res.replyCode == 'success') {
        // this.toastr.successToastr("success", 'Flight Delete successfully');
        this.onFlightMarkup(this.sharetoDataId,this.isCompanyName);
        this.isUpdateValue=false;
      }
    });
  }


  onPnrMargin(userId:any,compName:any){
    this.sharetoDataId = userId;
    this.isCompanyName= compName;
      var pnrmarkupRequiest = {
        share_to_user_id: userId,
      }
      this.sharedService.post('v1/share_inventory/pnr_markup', pnrmarkupRequiest).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.isPnrMarkupData = res.share_flight
        }
      });
    $('#pnrMarkup').modal('show');
  }
  onPnrAdd(){
    var onPnrMarkupAdd = {
      share_to_user_id: this.sharetoDataId,
      pnr:this.pnrmarkupUpdateForm.value.pnr,
      markup_adult:this.pnrmarkupUpdateForm.value.markup_adult,
      markup_infant:this.pnrmarkupUpdateForm.value.markup_infant
    }
    this.sharedService.post('v1/share_inventory/pnr_markup_add', onPnrMarkupAdd).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'PNR add successfully');
        this.onPnrMargin(this.sharetoDataId,this.isCompanyName);
        this.pnrmarkupUpdateForm.reset();
      }
    });
  }
  onPatchPnrMarkup(data:any){
    var editData = data;
    this.editId = data.id;
    this.pnrmarkupUpdateForm.patchValue({
      id: editData.id,
      pnr:editData.pnr,
      markup_adult:editData.markup_adult,
      markup_infant:editData.markup_infant,
    })
    this.isUpdateValue=true;
  }
  onEditPnrMarkup(){
    var onEditPnrRequest = {
      id: this.editId,
      pnr:this.pnrmarkupUpdateForm.value.pnr,
      markup_adult:Number(this.pnrmarkupUpdateForm.value.markup_adult),
      markup_infant:Number(this.pnrmarkupUpdateForm.value.markup_infant),
      status:1
    }
    this.sharedService.post('v1/share_inventory/pnr_markup_edit', onEditPnrRequest).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'PNR update successfully');
        this.onPnrMargin(this.sharetoDataId,this.isCompanyName);
        // $('#pnrMarkup').modal('hide');
        this.isUpdateValue=false;
        this.pnrmarkupUpdateForm.reset();
      }
    });
  }
  onPnrDelete(pnrData:any){
    var pnrDeleteData = pnrData
    var onEditPnrRequest = {
      id: pnrDeleteData.id,
      pnr:pnrDeleteData.pnr,
      markup_adult:Number(pnrDeleteData.markup_adult),
      markup_infant:Number(pnrDeleteData.markup_infant),
      status:2
    }
    this.sharedService.post('v1/share_inventory/pnr_markup_edit', onEditPnrRequest).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr("success", 'PNR Delete successfully');
        this.onPnrMargin(this.sharetoDataId,this.isCompanyName);
        this.isUpdateValue=false;
      }
    });
  }


  onShareFlight(usrId:any, compName:any){
    this.userIdData = usrId;
    this.isCompanyName= compName;
      var shareFlightRequest = {
        share_to_user_id: usrId,
      }
      this.sharedService.post('v1/share_inventory/share_flight', shareFlightRequest).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.shareData = res
          // console.log("shareData==============1018 ==>>>>>",res);

          this.shareFlightForm.patchValue({
            "share_flight":this.shareData.share_flight,
            "dont_share_flight":this.shareData.dont_share_flight
          })
        }
      });
    $('#shareFlight').modal('show');
  }
  shareFlightSubmit(){
    var shareFlightRequest = {
      share_to_user_id: this.userIdData,
      share_flight:this.shareFlightForm.value.share_flight,
      dont_share_flight:this.shareFlightForm.value.dont_share_flight
    }
    this.sharedService.post('v1/share_inventory/share_flight_edit', shareFlightRequest).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Flight share successfully');
        this.myApiList(this.bodydata);
        $('#shareFlight').modal('hide');
      }
    });
  }

  onShareAirline(usrId:any, compName:any){
    this.userIdData = usrId;
    this.isCompanyName= compName;
      var shareAirlineRequest = {
        share_to_user_id: usrId,
      }
      this.sharedService.post('v1/share_inventory/share_airline', shareAirlineRequest).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.shareDataAirline = res
          this.shareAirlineForm.patchValue({
            "share_airline":this.shareDataAirline.share_airline,
            "dont_share_airline":this.shareDataAirline.dont_share_airline
          })
        }
      });
    $('#shareAirline').modal('show');
  }

  shareAirlineSubmit(){
    var shareAirlineRequest = {
      share_to_user_id: this.userIdData,
      share_airline:this.shareAirlineForm.value.share_airline,
      dont_share_airline:this.shareAirlineForm.value.dont_share_airline
    }
    this.sharedService.post('v1/share_inventory/share_airline_edit', shareAirlineRequest).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Airline share successfully');
        this.myApiList(this.bodydata);
        $('#shareAirline').modal('hide');
      }
    });
  }


  clAirlineModal(){
    $('#shareAirline').modal('hide');
  }

// =========================== ********************* ======================== //
// =========================== Agency function Start ======================== //
// =========================== ********************* ======================== //

accountdetailAgency(list) {
  if (this.userdata.role_id == 1) {
    var data = {
      share_inventorie_id: list.share_inventorie_id,
      share_to_user_id: list.share_to_user_id,
      amount: list.credit_limit
    }
    sessionStorage.setItem("account_detail_data", JSON.stringify(data))
    this.router.navigateByUrl("dashboard/account-balance");
  }
  else if (this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.b2b_view == 1) {
    console.log('this.userdata.b2b_view ================== 906 ===', this.userdata.b2b_view);
    var data2 = {
      partner_user_id: list.id,
      patner_vendor_id: list.id,
      company_name: list.company_name?list.company_name:list.name,
      amount: list.available_balance
    }
    sessionStorage.setItem("account_detail_data", JSON.stringify(data2))
    this.router.navigateByUrl("dashboard/account-balance");
  }
  //this.dataservice.accountDtl(list);
}
editMarginAgency(){
  this.submitted=true;
  if(this.margineditform.valid){
    var data=this.margineditform.value;
    var request_data={
      id: data.id,
      my_adult:Number(data.my_adult),
      my_infant:Number(data.my_infant),
      partner_adult:Number(data.partner_adult),
      partner_infant:Number(data.partner_infant),
      supplier_adult:Number(data.supplier_adult),
      supplier_infant:Number(data.supplier_infant)
    }
    this.sharedService.post('v1/user_margin/edit', request_data).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.submitted=false;
        this.toastr.successToastr(res.replyMsg, 'Success');
        $('#editmarginmodalAgency').modal('hide');
        this.onAgencyList();
      } else {
        this.submitted=false;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.submitted=false;
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }else{
    this.toastr.errorToastr("fill the form correctly....")
  }
}
vendorDtl(data) {
  this.venderList = data;
  $('#viewDetails').modal('show');
}
updateStatus(id, value) {
  if (value === true) {this.status = 1; }
  else {this.status = 0;}
  var Object = {    "id": id,   "status": this.status  }
  this.sharedService.post('v1/user_activity/change_user_status', Object).subscribe((res: any) => {
    if (!res.error) {
      this.onAgencyList();
      this.toastr.successToastr(res.message, 'Success');
    } else {
      this.toastr.errorToastr(res.error.replyMsg, 'Error');
    }
  }, error => {
    this.toastr.errorToastr("Oops! Something went wrong!");
  });
}
saerchAgency(e:any){
  var text_length= (e.target.value).length
  if(text_length >=3){
    this.searchFlagReset = true;
    this.agencyUser['page_limit']= '10',
    this.agencyUser['page_no']= 1,
    this.agencyUser['search'] = String(this.inputSearch.nativeElement.value);
    this.onAgencyList();
  }else if(text_length < 3 && text_length == 0){
    this.searchFlagReset = true;
    this.agencyUser['page_limit']= '10',
    this.agencyUser['page_no']= 1,
    this.agencyUser['search'] = '';
    this.onAgencyList();
  }
}
onChangeAccountType(type:any){
  console.log("this.addaccountPartner",type,this.addaccountValueAgency)
  if(type == 0){
    this.addaccountValueAgency.get('amount').setValidators([Validators.required,AmountPatternValidator(/^[0-9]*(\.[0-9]{0,8})?$/)]);
    this.addaccountValueAgency.controls['amount'].updateValueAndValidity();
  }else{
    this.addaccountValueAgency.get('amount').clearValidators();
    this.addaccountValueAgency.controls['amount'].updateValueAndValidity();
    this.addaccountValueAgency.get('amount').setValidators([Validators.required]);
    this.addaccountValueAgency.controls['amount'].updateValueAndValidity();
  }
}
accountShowAgency(list) {
  console.log("list=====1292====+>>>>>>>",list);

  this.isAuto = false;
  $('#addBalanceAgency').modal('show');
  this.addaccountValueAgency.patchValue({
    patner_vendor_id:list.id,
  })
}
get abAgency() { return this.addaccountValueAgency.controls }
addAccountAgency() {
  console.log("add account called ",this.addaccountValueAgency)
  this.submitted = true;
  if (this.addaccountValueAgency.invalid) {
    return;
  } else {
    if(this.crediBalance == '' && this.creditVal == 3){
      return
    }else{
    this.ngxLoader.start();
      var data = this.addaccountValueAgency.value;
      var request_data:any={
        patner_vendor_id: data.patner_vendor_id,
        amount:data.amount,
        narration: data.narration,
        account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
        credit_reversal_date:this.crediBalance ==''?'':this.datePipe.transform(this.crediBalance, 'yyyy-MM-dd')   /* cannot be null if account_type = 'credit' */,
        is_auto_reverse:this.isAuto
      }
      if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
        var addBalanceData = 'v1/vendor_account/add_balance_new'
        // var addBalanceData = 'v1/account/add_balance'
      }
      this.sharedService.postRequest(addBalanceData, request_data).subscribe((res: any) => {
        if (!res.error) {
          this.ngxLoader.stop();
          if (res.replyCode == 'success') {
            this.crediBalance="";  this.creditVal=3;  this.accountData = res.data;  this.submitted = false;
            // var cl = Number(this.detail_data.amount) + Number(request_data.amount)
            this.addaccountValueAgency.reset();  $('#addBalanceAgency').modal('hide');  this.submitted = false;  this.toastr.successToastr(res.replyMsg, 'Success');
            this.onAgencyList();
            this.ngxLoader.stop();
          } else {
            this.submitted = false;
            this.crediBalance="";  this.creditVal=3;this.submitted = false;
            this.addaccountValueAgency.reset();  $('#addBalanceAgency').modal('hide');  this.submitted = false;
            this.onAgencyList();
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
        } else {
          this.ngxLoader.stop();
          this.submitted = false;
          this.crediBalance="";  this.creditVal=3; this.submitted = false;
          this.addaccountValueAgency.reset();  $('#addBalanceAgency').modal('hide');  this.submitted = false;
          this.onAgencyList();
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, error => {
        this.ngxLoader.stop();
        this.submitted = false;
        this.crediBalance="";  this.creditVal=3;  this.submitted = false;
        this.addaccountValueAgency.reset();  $('#addBalanceAgency').modal('hide');  this.submitted = false;
        this.onAgencyList();
        this.toastr.errorToastr(error.error.replyMsg, 'Error');
      });
    }
  }
}
marginEditAgency(data){

  this.margineditform.patchValue({
    id: data.id,
    my_adult:data.my_adult,
    my_infant:data.my_infant,
    partner_adult:data.partner_adult,
    partner_infant:data.partner_infant,
    supplier_adult:data.supplier_adult,
    supplier_infant:data.supplier_infant
  })
  setTimeout(() => {
      $('#editmarginmodalAgency').modal('show');
  }, 500);
}
myFunctionDateAgency(val){
  var newdate=new Date();
  var diff = val.getTime() - newdate.getTime();
  var days = Math.floor(diff / (60 * 60 * 24 * 1000));
  var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
  var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
  var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
  this.diffTime= days+" Days, "+ hours+" Hours,"+minutes+" Minute, "+seconds+" Seconds";
  this.onSelectDate = val ;
  this.onSelectDate.toDateString() ;
}
loadstate(id) {
  this.sharedService.get(`v1/state_list/${id}`).subscribe(res => {
    if (res['replyCode'] == "success") {
      this.allstate = res.data;

    } else {
    }
  }, err => {
    this.toastr.errorToastr(err['error']['message'], 'Error');
  });
}
editUser(data){
  this.loadstate(93);
  this.editUserDataform.patchValue({
    id: data.id,
    name:data.name,
    email:data.email,
    mobile:data.mobile,
    address:data.address,
    address_2:data.address_2,
    zipcode:data.zipcode,
    company_name:data.company_name,
    city:data.city,
    state_id:data.state_id,
    country_id:data.country_id,
    pan_no:data.pan_no,
    gst_no:data.gst_no,
    aadhaar_no:data.aadhaar_no
  })
  setTimeout(() => {
      $('#editUserData').modal('show');
  }, 500);
}
editUserData(){
  this.submitted=true;
   var data=this.editUserDataform.value;
    var request_data={
      // id: this.editUserId,
      id: data.id,
      name:data.name,
      email:data.email,
      mobile:data.mobile,
      address:data.address,
      address_2:data.address_2,
      zipcode:data.zipcode,
      company_name:data.company_name,
      city:data.city,
      state_id:data.state_id,
      country_id:data.country_id,
      pan_no:data.pan_no,
      gst_no:data.gst_no,
      aadhaar_no:data.aadhaar_no
    }
    this.sharedService.post('v1/users/edit_user', request_data).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.submitted=false;
        this.toastr.successToastr(res.replyMsg, 'Success');
        $('#editUserData').modal('hide');
        this.onAgencyList();
      } else {
        this.submitted=false;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.submitted=false;
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  // }else{
  //   this.toastr.errorToastr("fill the form correctly....")
  // }
}
roleObject:any={};
onChangeRoleOfAgency(event:any){
  this.roleObject=event;
  this.FormId = event;
  $('#roleChange').modal('show');
}
btnSubmitted:boolean=false;
onChangeRoleSubmit(){
  var request = {
    user_id: this.roleObject.id,
    role_id: 2
  }
  this.btnSubmitted=true;
  this.sharedService.patch('v1/users/update_patner_user_role', request).subscribe((res) => {
    if (res.replyCode == 'success') {
      this.btnSubmitted=false;
      $('#roleChange').modal('hide');
      this.onAgencyList();
      this.toastr.successToastr(res.replyMsg, 'Success');
    } else {
      this.btnSubmitted=false;
      this.toastr.errorToastr(res.message, 'Error');
    }
  }, err => {
    this.btnSubmitted=false;
    this.toastr.errorToastr(err['error'].message, 'Error');
  });
}
onChangeRoleOfClientDelete(event:any){
  this.roleObject=event;
  $('#roleChangeDelete').modal('show');
}
onChangeRoleDeleteSubmit(){
  var request = {
    user_id: this.roleObject.id,
    role_id: 3
  }
  this.btnSubmitted=true;
  this.sharedService.patch('v1/users/update_patner_user_role', request).subscribe((res) => {
    if (res.replyCode == 'success') {
      this.btnSubmitted=false;
      $('#roleChangeDelete').modal('hide');
      this.onAgencyList();
      this.toastr.successToastr(res.replyMsg, 'Success');
    } else {
      this.btnSubmitted=false;
      this.toastr.errorToastr(res.message, 'Error');
    }
  }, err => {
    this.btnSubmitted=false;
    this.toastr.errorToastr(err['error'].message, 'Error');
  });
}
// =========================== ********************* ======================== //
// =========================== Agency function End ======================== //
// =========================== ********************* ======================== //

}


export interface PeriodicElement1 {
  id: number;
  position: number;
  status: number;
  companyname: string;
  website: string;
  key: string;
  plan: number;
  credtlimit: number;
  share_to_user_id: number;
  share_inventory_id: number;
  share_price_breakup: number;
  user_id: number;
}


