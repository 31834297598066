import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedserviceService } from './sharedservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BehaviorSubject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManagedataService } from 'src/app/services/manage-data.service';


declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isInvoiceData = new BehaviorSubject<any>([]);
  GetInvoiceDataResponse() { return this.isInvoiceData.asObservable() }
  SetInvoiceDataResponse(value: any) { this.isInvoiceData.next(value) }

  public userimgsrc:any='';
  public userimage:any='';
  public companyname:any;
  public osname:any;
  public modalshow: boolean = false;
  public clicked_inventory: any;
  public imageTrue: any = {};
  public bannerTrue: any = {};
  public headBner: boolean = false;
  public bgBner: boolean = false;
  public user: any;
  public compDtl: any;
  public acDtl: any;
  public unicId: any;
  public searchedflight: any;
  public searchedflightticketusertype: any;
  public searchedflight_detail: any;
  public srcimagepath: any;
  public inventoryview_searchdata: any;
  myProfileimg: any;
  constructor(private router: Router, private httpClient: SharedserviceService, private toastr: ToastrManager, public ngxLoader:NgxUiLoaderService,public managService:ManagedataService){

    // if(JSON.parse(localStorage.getItem("user"))){this.userinfo();}
    this.bannerTrue['headvalue'] = '0';
    this.imageTrue['headvalue'] = '0';

    if (this.bannerTrue.headvalue == 1) {
      this.headBner = true;
    } else {
      this.headBner = false;
    }

    if (this.imageTrue.headvalue == 1) {
      this.bgBner = true;
    } else {
      this.bgBner = false;
    }
    this.osname=this.getOS();
  }
  getToken(): string {
    return window.localStorage['token']
  }
  saveToken(token: string) {
    window.localStorage['token'] = token
  }
  destroyToken() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user')
  }
  getUser(): string {
    return window.localStorage['user']
  }
  saveUser(user: string) {
    window.localStorage['user'] = user;
  }


  get_booking_token() {
    this.httpClient.get('v1/flight/booking_token').subscribe((res) => {
      if (res.replyCode == 'success') {
        // console.log("res.data:::::::::",res.data);
        sessionStorage.setItem("Booking_token",JSON.stringify(res.booking_token));
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      // this.ngxLoader.stop();
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });

  }

  userinfo() {

    var request = { }
    this.httpClient.post('v1/users/view_profile', request).subscribe(res => {
      if (res['replyCode'] == "success") {
        var UserDataShow = res['data'];
        this.userimgsrc = res['imageurl'];
        this.userimage = this.userimgsrc + "/" + UserDataShow[0].image;
        // console.log("check img path=============",UserDataShow[0].image);
        this.myProfileimg = UserDataShow[0].image;
        }
      }, err => {
        this.toastr.errorToastr(err['error']['message'], 'Error');
      });

      return this.userimage;

  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }

        return os;
  }


  checkHeader(event) {
    var headData = { headvalue: 1 }
    if (event.checked == true) {
      sessionStorage.setItem("headerTrue", JSON.stringify(headData));
      this.bannerTrue = JSON.parse(sessionStorage.getItem("headerTrue"));
      if (this.bannerTrue.headvalue == 1) {
        this.headBner = true;
        event.checked = true;
      }
      else {
        event.checked = false;
        this.headBner = false;
      }
    }
    else {
      sessionStorage.removeItem("headerTrue");
      this.headBner = false;
      event.checked = false;
    }
  }

  imgBanner(event) {
    var bgData = { headvalue: 1 }
    if (event.checked == true) {
      sessionStorage.setItem("imageBg", JSON.stringify(bgData));
      this.imageTrue = JSON.parse(sessionStorage.getItem("imageBg"));
      if (this.imageTrue.headvalue == 1) {
        this.bgBner = true;
        event.checked = true;
      }
      else {
        event.checked = false;
        this.bgBner = false;
      }
    }
    else {
      sessionStorage.removeItem("imageBg");
      this.bgBner = false;
      event.checked = false;
    }
  }
  setinventory_searchdata(data, date) {
    this.inventoryview_searchdata = data;
    localStorage.setItem("inventory_flight_id", data.flight_id);
    localStorage.setItem("inventory_data", JSON.stringify(data));
    localStorage.setItem("inventory_d_date", date);
    this.router.navigate(['/inventory/inventory-view']);
  }

  setinventory_searchdata1(data, id, date) {
    localStorage.setItem("inventory_flight_id", id);
    localStorage.setItem("inventory_date", date);
    localStorage.setItem("inventory_data", JSON.stringify(data));
    localStorage.setItem("inventory_d_date", date);
    this.router.navigate(["/inventory/inventory-view"]);
  }

  searchflightdetail(data) {
    const self = this;
    this.httpClient.post('v1/search_flight/search_ticket', data).subscribe((res: any) => {
      if (res.replyCode == "success" && res.data != '') {
        self.searchedflight = res.data;
        self.srcimagepath = res.imgBasePath;

        sessionStorage.setItem("flights", JSON.stringify(self.searchedflight));
        sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath));
        self.router.navigateByUrl("search-detail");
      } else {
        sessionStorage.removeItem("flights");
        sessionStorage.removeItem("imagepath");
        self.toastr.errorToastr("Result Not Found", "Error")
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });
  }
  searchflightticket(data) {
    const self = this;
    this.httpClient.post('v1/search_flight/search_ticket', data).subscribe((res: any) => {
      if (res.replyCode == "success" && res.data != '') {
        this.searchedflightticketusertype = res.data[0].inventory_user_Type
      }
      else {
        sessionStorage.removeItem("flights");
        sessionStorage.removeItem("imagepath");
        self.toastr.errorToastr("Result Not Found", "Error")
      }
    });
  }
  searchflightdetails(data) {
    const self = this;
    this.httpClient.post('v1/search_flight/search_ticket', data).subscribe((res: any) => {
      if (res.replyCode == "success" && res.data != '') {
        self.searchedflightticketusertype = res.data[0].inventory_user_Type
        self.searchedflight = res.data;
        self.srcimagepath = res.imgBasePath;

        sessionStorage.setItem("flights", JSON.stringify(self.searchedflight));
        sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath));
        self.router.navigateByUrl("search-detail");
      }
      else {
        sessionStorage.removeItem("flights");
        sessionStorage.removeItem("imagepath");
        self.toastr.errorToastr("Result Not Found", "Error")
      }
    });
  }
  
  searchedflightdetail(data) {
    this.ngxLoader.start();
    const self = this;
    this.httpClient.post('v1/search_flight/search_details', data).subscribe((res: any) => {
      if (res.replyCode == "success" && res.data != '') {
        self.searchedflight_detail = res.data;
        self.srcimagepath = res.imgBasePath;
        self.searchedflight_detail.booking_token = res.booking_token_id;
        self.searchedflight_detail.live_pnr = data.live_pnr;

        sessionStorage.setItem("searchedflights_detail", JSON.stringify(self.searchedflight_detail));
        sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath));
        self.router.navigateByUrl("detail");
        this.ngxLoader.stop();
      } else {
        sessionStorage.removeItem("searchedflights_detail");
        sessionStorage.removeItem("imagepath");
        self.toastr.errorToastr("Result Not Found", "Error")
        this.ngxLoader.stop();
      }
    }, err => {
      self.toastr.errorToastr(err.error.replyMsg, "Error")
      this.ngxLoader.stop();
    });
  }
  isTokenValid() {
    this.destroyToken();
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
  onLogout() {
    this.destroyToken();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }
}
