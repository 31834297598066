import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loginrequest',
  templateUrl: './loginrequest.component.html',
  styleUrls: ['./loginrequest.component.scss']
})
export class LoginrequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
